import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MessageIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M4.733 14.367A4.817 4.817 0 019.55 9.55h28.9a4.817 4.817 0 014.817 4.817v19.267a4.817 4.817 0 01-4.817 4.816H9.55a4.817 4.817 0 01-4.817-4.816zm4.817-2.409a2.408 2.408 0 00-2.409 2.409v.523L24 25.004l16.858-10.115v-.522a2.408 2.408 0 00-2.408-2.408zm31.309 5.739l-11.46 6.876L40.858 31.5V17.7zm-.082 16.565l-13.716-8.287L24 27.813l-3.067-1.838-13.71 8.285a2.408 2.408 0 002.326 1.782h28.9a2.408 2.408 0 002.328-1.78zM7.141 31.5L18.6 24.574 7.141 17.697v13.8z" />
      </g>
    </svg>
  )
}
