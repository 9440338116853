import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MeaningfulLifeIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        d="M46.434 26.086h-7.305c-.863 0-1.563.703-1.563 1.566v.02a12.997 12.997 0 00-5.812-1.586c-1.512-1.207-5.098-3.637-9.84-4.094v-2.18c0-2.19 1.305-3.25 2.215-4.16h5.61c4.316 0 7.827-3.511 7.827-7.824V1.566C37.566.703 36.863 0 36 0h-6.262c-4.312 0-7.824 3.512-7.824 7.828v5.61c-.887.89-1.148 1.124-1.566 1.675-.414-.55-.68-.789-1.563-1.675v-5.61C18.785 3.512 15.273 0 10.957 0H4.695c-.863 0-1.562.703-1.562 1.566v6.262c0 4.313 3.508 7.824 7.824 7.824h5.613c.899.899 2.211 1.961 2.211 4.16v2.168c-4.062.34-8.617 2.118-11.086 5.188a4.695 4.695 0 00-6.32 6.934l9.395 9.39a4.66 4.66 0 003.316 1.38h23.48v1.562c0 .863.7 1.566 1.563 1.566h7.305C47.3 48 48 47.297 48 46.434V27.652c0-.863-.7-1.566-1.566-1.566zM25.043 7.828c0-2.59 2.11-4.695 4.695-4.695h4.696v4.695a4.702 4.702 0 01-4.696 4.695h-2.48l3.59-3.59a1.57 1.57 0 000-2.214 1.57 1.57 0 00-2.215 0l-3.59 3.59zm-9.39 2.48l-3.59-3.59a1.565 1.565 0 00-2.211 2.216l3.585 3.59h-2.48a4.702 4.702 0 01-4.695-4.696V3.133h4.695a4.7 4.7 0 014.695 4.695zm4.695 14.735c2.453 0 4.789.738 6.718 1.727-1.187.406-2.32.988-3.355 1.753-.777.575-1.766.696-2.457.696h-4.035a4.704 4.704 0 00-4.399 3.047l-2.879-2.88c1.536-2.171 5.637-4.343 10.407-4.343zm17.218 16.695h-23.48c-.41 0-.813-.168-1.106-.457l-9.39-9.39a1.563 1.563 0 010-2.215 1.57 1.57 0 012.215 0l8.472 8.472c.293.297.692.461 1.106.461h14.355a1.566 1.566 0 100-3.132h-12.52c-.863 0-1.566-.7-1.566-1.563s.703-1.566 1.567-1.566h4.035c1.668 0 3.16-.453 4.32-1.309 3.508-2.594 8.75-2.363 11.992.43zm7.305 3.13h-4.176v-15.65h4.176zm0 0"
        fill="currentColor"
      />
    </svg>
  )
}
