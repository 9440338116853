import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ChartsIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#prefix__a)"
        fill="none"
        stroke="currentColor"
        strokeWidth={3}
      >
        <g data-name="Rectangle 6" transform="translate(0 17)">
          <rect width={13} height={30} rx={4} stroke="none" />
          <rect x={1.5} y={1.5} width={10} height={27} rx={2.5} />
        </g>
        <g data-name="Rectangle 8" transform="translate(35 10)">
          <rect width={13} height={37} rx={4} stroke="none" />
          <rect x={1.5} y={1.5} width={10} height={34} rx={2.5} />
        </g>
        <g data-name="Rectangle 7" transform="translate(18 1)">
          <rect width={13} height={46} rx={4} stroke="none" />
          <rect x={1.5} y={1.5} width={10} height={43} rx={2.5} />
        </g>
      </g>
    </svg>
  )
}
