import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const LonelinessIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_275)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M5.197 2.197a7.5 7.5 0 1110.606 10.606A7.5 7.5 0 015.197 2.197zM10.5 3a4.5 4.5 0 100 9 4.5 4.5 0 000-9z" />
        <path d="M3.075 18.075A10.5 10.5 0 0121 25.5v6a1.5 1.5 0 01-1.5 1.5h-1.642l-1.365 13.65A1.5 1.5 0 0115 48H6a1.5 1.5 0 01-1.493-1.35L3.143 33H1.5A1.5 1.5 0 010 31.5v-6a10.5 10.5 0 013.075-7.425zM10.5 18A7.5 7.5 0 003 25.5V30h1.5a1.5 1.5 0 011.493 1.35L7.357 45h6.285l1.365-13.65A1.5 1.5 0 0116.5 30H18v-4.5a7.5 7.5 0 00-7.5-7.5zM29.95 3.22a4.55 4.55 0 00-5.468 2.296v.002a4.546 4.546 0 00.873 5.274l9.145 9.54 9.145-9.54a4.55 4.55 0 10-6.414-6.46l-1.67 1.67a1.5 1.5 0 01-2.122 0l-1.67-1.67a4.55 4.55 0 00-1.82-1.112zM27.34.098a7.55 7.55 0 016.55 2.113l.609.61.61-.61a7.553 7.553 0 0112.793 6.55A7.55 7.55 0 0145.8 12.88l-10.217 10.66a1.5 1.5 0 01-2.166 0L23.2 12.878a7.545 7.545 0 01-1.402-8.704A7.55 7.55 0 0127.341.098z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_275">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
