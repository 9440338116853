import * as msalBrowser from "@azure/msal-browser"
import * as msalReact from "@azure/msal-react"
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import * as R from "ramda"
import { VirtaRole } from "../roles"
import { useAdminAuthContext } from "./AdminAuthContext"
import { isHomogenousPrimitiveArray } from "../typings/typeUtils"

const ASSUMED_ROLES_LOCALSTORAGE_KEY = "virta.admin.assumedRoles"

const azureAdRolesToVirtaRoles: Record<string, VirtaRole> = {
  "Api.Admin": "Admin",
  "Api.Coach": "Coach",
}

export interface AdminAuthProviderProps {
  msalConfig: msalBrowser.Configuration
}

export const AdminAuthProvider: FC<AdminAuthProviderProps> = ({
  children,
  msalConfig,
}) => {
  const [msalInitializing, setMsalInitializing] = useState(true)
  const msalAppRef = useRef<msalBrowser.PublicClientApplication>()

  useEffect(() => {
    setMsalInitializing(true)
    const msalApp = new msalBrowser.PublicClientApplication(msalConfig)
    msalApp
      .initialize()
      .then(() => {
        msalAppRef.current = msalApp
        setMsalInitializing(false)
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log("Couldn't initialize MSAL:", error)
        throw error
      })
  }, [msalConfig])

  if (msalInitializing || !msalAppRef.current) {
    return null
  }

  return (
    <msalReact.MsalProvider instance={msalAppRef.current}>
      {children}
    </msalReact.MsalProvider>
  )
}

/**
 * Exactly like msalReact.useAccount, but the parameter is optional.
 */
export const useAccountSane = (
  accountIdentifiers?: msalReact.AccountIdentifiers,
): msalBrowser.AccountInfo | null =>
  msalReact.useAccount(accountIdentifiers ?? { username: "" })

export const login = async (
  msalInstance: msalBrowser.IPublicClientApplication,
  scopes: string[],
  redirectUri: string,
): Promise<void> =>
  msalInstance.loginRedirect({
    redirectUri,
    scopes,
  })

export const logout = async (
  msalInstance: msalBrowser.IPublicClientApplication,
  account: msalBrowser.AccountInfo,
  authority: string,
): Promise<void> =>
  msalInstance.logoutRedirect({
    account,
    authority,
  })

export const useLogout = (): (() => void) => {
  const { instance, accounts } = msalReact.useMsal()
  const account = useAccountSane(accounts[0])
  const adminAuthContext = useAdminAuthContext()

  return useCallback(() => {
    if (!account) {
      return
    }

    logout(instance, account, adminAuthContext.getAzureAdAuthority()).catch(
      error => {
        throw error
      },
    )
  }, [account, instance, adminAuthContext])
}

/**
 * Return an authentication result, or "interactionRequired" if interactive login is required, or
 * "forcedInteractionRequired" if interactive login must be forced.
 */
export const authenticateSilent = async (
  msalInstance: msalBrowser.IPublicClientApplication,
  account: msalBrowser.AccountInfo,
  scopes: string[],
): Promise<msalBrowser.AuthenticationResult | "interactionRequired"> => {
  try {
    const result = await msalInstance.acquireTokenSilent({
      account,
      scopes,
    })
    return result
  } catch (error) {
    if (error instanceof msalBrowser.InteractionRequiredAuthError) {
      return "interactionRequired"
    }

    // eslint-disable-next-line no-console
    console.error(error)
    return "interactionRequired"
  }
}

/**
 * Return an authentication result or trigger the auth page if login is required.
 *
 * @deprecated Don't use. Apollo authenticates automatically now.
 */
export const useAuthentication = () => {
  const dummy = useMemo(() => ({}), [])
  return dummy
}

interface IdTokenClaimsWithRoles {
  roles: string[]
}

const isIdTokenClaimsWithRoles = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  obj?: object,
): obj is IdTokenClaimsWithRoles => {
  if (!obj) {
    return false
  }

  const maybeIdTokenClaimsWithRoles = obj as IdTokenClaimsWithRoles

  if (
    !isHomogenousPrimitiveArray(maybeIdTokenClaimsWithRoles.roles, "string")
  ) {
    return false
  }

  return maybeIdTokenClaimsWithRoles.roles.every(
    role => typeof role === "string",
  )
}

export const useRoles = (
  isProductionEnv: boolean,
  account: msalBrowser.AccountInfo | undefined | null,
): VirtaRole[] => {
  // For debugging reasons an admin can assume other roles in non-production environments.
  // Note that roles are ultimately validated on the server side - this just affects the visual appearance of the site.

  const msalRoles = useMemo((): VirtaRole[] => {
    if (!account) {
      return []
    }

    if (!isIdTokenClaimsWithRoles(account.idTokenClaims)) {
      throw new Error(
        "MSAL account idTokenClaims.roles is missing or malformed.",
      )
    }

    return R.flatten(
      account.idTokenClaims.roles.map(
        role => azureAdRolesToVirtaRoles[role] ?? [],
      ),
    )
  }, [account])

  const assumedRoles: VirtaRole[] | undefined = useMemo(() => {
    if (!account) {
      return []
    }

    if (isProductionEnv || !msalRoles.includes("Admin")) {
      return undefined
    }

    const rolesAsString = localStorage.getItem(ASSUMED_ROLES_LOCALSTORAGE_KEY)
    if (rolesAsString === null) {
      return undefined
    }

    return JSON.parse(rolesAsString) as VirtaRole[]
  }, [account, isProductionEnv, msalRoles])

  return assumedRoles ?? msalRoles
}
