import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 45a21 21 0 110-42 21 21 0 010 42zm0 3a24 24 0 100-48 24 24 0 000 48z"
        fill="currentColor"
      />
      <path
        d="M26.79 19.764l-6.87.861-.246 1.14 1.35.249c.882.21 1.056.528.864 1.407l-2.214 10.404c-.582 2.691.315 3.957 2.424 3.957 1.635 0 3.534-.756 4.395-1.794l.264-1.248c-.6.528-1.476.738-2.058.738-.825 0-1.125-.579-.912-1.599l3.003-14.115zM27 13.5a3 3 0 11-6 0 3 3 0 016 0z"
        fill="currentColor"
      />
    </svg>
  )
}
