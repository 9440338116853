import { math, opacify, rem } from "polished"
import { css, RuleSet } from "styled-components"
import { ITheme } from "react-styled-flexboxgrid"

import React from "react"
import { gridConfig } from "./flexbox-config"
import {
  GeneratedIcon,
  generatedIcons,
} from "../../generated/icons/generated-icons"

import { FontFamily, fontFamilies } from "./fonts"

export const DEFAULT_COMPONENT_SIZE = "medium"

// Please sort BrandColor values by color instead of name. See the Colors page in Storybook.

export type BrandColor =
  | "primary"
  // TODO AIS-525 at least all blue colors should be fixed/synced with design (blueExtraLight is not proper color name)
  | "blue"
  | "blueBright"
  | "blueLight"
  | "blueExtraLight"
  | "blueDark"
  | "yellow"
  | "yellowLight"
  | "yellowDark"
  | "yellowExtraDark"
  | "orange"
  | "orangeBright"
  | "roseGold"
  | "roseGoldLight"
  | "red"
  | "redLight"
  | "redDark"
  | "black"
  | "white"
  | "grey"
  | "greyDark"
  | "greyCold"
  | "greyLight"
  | "transparent"
  | "green"
  | "greenLight"
  | "greenDark"

export type BrandColorValues = Record<BrandColor, string>

export const colors: BrandColorValues = {
  primary: "#012856",
  blue: "#2446AB",
  blueBright: "#5277FF",
  blueLight: "#5277FF",
  blueDark: "#012856",
  blueExtraLight: "#dae6f2",
  yellow: "#ECCD8F",
  yellowLight: "#FEF0D8",
  yellowDark: "#D9A648",
  yellowExtraDark: "#534120",
  orange: "#F3BC44",
  orangeBright: "#F2A500",
  // orange: "#f2c86d",
  roseGold: "#FFC993",
  roseGoldLight: "#FFE6CC",
  red: "#F26A5E",
  redLight: "#FEDBD8",
  redDark: "#532420",
  black: "#000000",
  white: "#FFFFFF",
  grey: "#CCCCCC",
  greyDark: "#BABABA",
  greyCold: "#E6EAEC",
  greyLight: "#F5F5F5",
  transparent: "transparent",
  green: "#5AA67A",
  greenLight: "#D6E9DE",
  greenDark: "#1E3729",
}

export type BrandIcon = GeneratedIcon

export const brandIcons: Record<
  BrandIcon,
  React.FC<React.SVGProps<SVGSVGElement>>
> = generatedIcons

export const isBrandIcon = (icon: unknown): icon is BrandIcon =>
  typeof icon === "string" && Object.keys(brandIcons).includes(icon)

export const breakpointNames = ["xs", "sm", "md", "lg"] as const
export type Breakpoint = typeof breakpointNames[number]
export type BreakpointValues = Record<Breakpoint, string>
export type BreakpointPropertyValues = Pick<BreakpointValues, "xs"> &
  Partial<BreakpointValues>

const customMediaQuery = (breakpoint: Breakpoint): string =>
  `@media (min-width: ${gridConfig?.breakpoints?.[breakpoint]}em)`

const breakpoints: BreakpointValues = {
  xs: customMediaQuery("xs"),
  sm: customMediaQuery("sm"),
  md: customMediaQuery("md"),
  lg: customMediaQuery("lg"),
}

export type BrandSpacing =
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"

export type BrandSpacingValues = Record<BrandSpacing, string>

export type BrandShadow = "flat" | "skim" | "lifted" | "raised" | "floating"
export type BrandShadowValues = Record<BrandShadow, RuleSet>

export type TextStyleType = "default" | "paragraph" | "heading"

export type HeadingTextSize =
  | "xsmall"
  | "small"
  | "medium"
  | "mediumplus" // The jump between medium and large is pretty big
  | "large"
  | "largeplus" // The jump between large and xlarge is pretty big
  | "xlarge"
  | "xxlarge"

export type HeadingTextWeight = "medium" | "bold" | "extrabold"

export type DefaultTextSize = "xsmall" | "small" | "medium" | "large" | "xlarge"
export type DefaultTextWeight = "normal" | "medium" | "semibold" | "bold"

export type ParagraphTextSize =
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
export type ParagraphTextWeight = "light" | "normal" | "medium" | "bold"

export type HeadingTextWeightValues = Record<HeadingTextWeight, string>

export type DefaultTextWeightValues = Record<DefaultTextWeight, string>

export type ParagraphTextWeightValues = Record<ParagraphTextWeight, string>

export interface Typography {
  default: FontFamily & {
    lineHeights: Record<DefaultTextSize, BreakpointPropertyValues>
    sizes: Record<DefaultTextSize, BreakpointPropertyValues>
    weights: DefaultTextWeightValues
  }
  heading: FontFamily & {
    lineHeights: Record<HeadingTextSize, BreakpointPropertyValues>
    sizes: Record<HeadingTextSize, BreakpointPropertyValues>
    weights: HeadingTextWeightValues
  }
  paragraph: FontFamily & {
    lineHeights: Record<ParagraphTextSize, BreakpointPropertyValues>
    sizes: Record<ParagraphTextSize, BreakpointPropertyValues>
    weights: ParagraphTextWeightValues
  }
}

export type MarginProp =
  | "marginTop"
  | "marginRight"
  | "marginLeft"
  | "marginBottom"

export type Margins = Partial<Record<MarginProp, BrandSpacing>>

export type MarginStyleFunc = (
  theme: Pick<BrandTheme, "spacings">,
  margins: Margins,
) => RuleSet | undefined

const marginStyle: MarginStyleFunc = (theme, margins) => {
  const { marginTop, marginRight, marginBottom, marginLeft } = margins

  return css`
    margin-top: ${marginTop && theme.spacings[marginTop]};
    margin-right: ${marginRight && theme.spacings[marginRight]};
    margin-bottom: ${marginBottom && theme.spacings[marginBottom]};
    margin-left: ${marginLeft && theme.spacings[marginLeft]};
  `
}

export const textAligns = ["left", "center", "right", "justify"] as const

export type TextAlign = typeof textAligns[number]

/*
 * NOTE: If modifying sizes, or weights, remember to update the
 * fontsCssUrl in @aistihealth/web-common/src/util/styles/fonts and
 * .storybook/preview-body.html and .storybook/preview-head.html.
 */
const typography: Typography = {
  default: {
    family: fontFamilies.default.family,
    lineHeights: {
      xsmall: {
        xs: "0.75rem",
      },
      small: {
        xs: "0.875rem",
      },
      medium: {
        xs: "1rem",
      },
      large: {
        xs: "1.25rem",
      },
      xlarge: {
        xs: "1.5rem",
      },
    },
    sizes: {
      xsmall: {
        xs: "0.75rem",
      },
      small: {
        xs: "0.875rem",
      },
      medium: {
        xs: "1rem",
      },
      large: {
        xs: "1.25rem",
      },
      xlarge: {
        xs: "1.5rem",
      },
    },
    weights: {
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
    },
  },
  heading: {
    family: fontFamilies.heading.family,
    lineHeights: {
      xsmall: {
        xs: "1rem",
        sm: "1rem",
      },
      small: {
        xs: "1.25rem",
        sm: "1.25rem",
      },
      medium: {
        xs: "1.5rem",
        sm: "1.5rem",
      },
      mediumplus: {
        xs: "1.625rem",
        sm: "1.75rem",
      },
      large: {
        xs: "1.75rem",
        sm: "2rem",
      },
      largeplus: {
        xs: "1.875rem",
        sm: "2.5rem",
      },
      xlarge: {
        xs: "2rem",
        sm: "3rem",
      },
      xxlarge: {
        xs: "2.5rem",
        sm: "4rem",
      },
    },
    sizes: {
      xsmall: {
        xs: "1rem",
        sm: "1rem",
      },
      small: {
        xs: "1.25rem",
        sm: "1.25rem",
      },
      medium: {
        xs: "1.5rem",
        sm: "1.5rem",
      },
      mediumplus: {
        xs: "1.625rem",
        sm: "1.75rem",
      },
      large: {
        xs: "1.75rem",
        sm: "2rem",
      },
      largeplus: {
        xs: "1.875rem",
        sm: "2.5rem",
      },
      xlarge: {
        xs: "2rem",
        sm: "3rem",
      },
      xxlarge: {
        xs: "2.5rem",
        sm: "4rem",
      },
    },
    weights: {
      medium: "500",
      bold: "700",
      extrabold: "800",
    },
  },
  paragraph: {
    family: fontFamilies.paragraph.family,
    lineHeights: {
      xsmall: {
        xs: "1rem",
      },
      small: {
        xs: "1.25rem",
      },
      medium: {
        xs: "1.5rem",
      },
      large: {
        xs: "1.5rem",
      },
      xlarge: {
        xs: "1.875rem",
      },
    },
    sizes: {
      xsmall: {
        xs: "0.75rem",
      },
      small: {
        xs: "0.875rem",
      },
      medium: {
        xs: "1rem",
      },
      large: {
        xs: "1.25rem",
      },
      xlarge: {
        xs: "1.25rem",
        sm: "1.5rem",
      },
    },
    weights: {
      light: "300",
      normal: "400",
      medium: "500",
      bold: "700",
    },
  },
}

export interface TextStyleOptions {
  scale?: Partial<Record<Breakpoint, number>>
}

export interface TextStyleFunc {
  (
    type: "heading",
    size: HeadingTextSize,
    weight: HeadingTextWeight,
    options?: TextStyleOptions,
  ): RuleSet
  (
    type: "default",
    size: DefaultTextSize,
    weight: DefaultTextWeight,
    options?: TextStyleOptions,
  ): RuleSet
  (
    type: "paragraph",
    size: ParagraphTextSize,
    weight: ParagraphTextWeight,
    options?: TextStyleOptions,
  ): RuleSet
}

const textReadabilityOutline = (
  primaryColor?: string,
  secondaryColor?: string,
): RuleSet => {
  const getShadowDefs = (xs: boolean) => {
    const distance = xs ? "1px" : "0.6px"

    return [
      ...(primaryColor ? [`${distance} ${distance} 0px ${primaryColor}`] : []),
      ...(secondaryColor
        ? [`-${distance} -${distance} 0px ${secondaryColor}`]
        : []),
    ]
  }

  return css`
    text-shadow: ${getShadowDefs(true).join(", ")};

    ${breakpoints.md} {
      text-shadow: ${getShadowDefs(false).join(", ")};
    }
  `
}

const iconSizing = (): RuleSet => {
  return css`
    ${breakpointNames.map(breakpointName => {
      return css`
        ${breakpoints[breakpointName]} {
          font-size: ${typography.default.sizes.xlarge[breakpointName]};
          width: ${typography.default.sizes.xlarge[breakpointName]};
        }
      `
    })}
  `
}

export interface BrandTheme {
  borderRadius: {
    /* eslint-disable @typescript-eslint/member-ordering */
    xsmall: string
    small: string
    medium: string
    /* eslint-enable @typescript-eslint/member-ordering */
  }
  breakpoints: BreakpointValues
  colors: BrandColorValues
  iconSizing: typeof iconSizing
  shadows: BrandShadowValues
  spacings: BrandSpacingValues
  textReadabilityOutline: typeof textReadabilityOutline
  textStyle: TextStyleFunc
  typography: Typography
  marginStyle: MarginStyleFunc
}

const scaleCss = (scale: number | undefined, cssValue: string): string =>
  scale === undefined ? cssValue : math(`${scale} * ${cssValue}`)

const generateMediaQueryValues = (
  breakpointName: Breakpoint,
  type: TextStyleType,
  size: HeadingTextSize | DefaultTextSize | ParagraphTextSize,
  scale?: number,
): RuleSet => {
  // TODO Should remove the eslint disable.
  // Now just disabled when file was moved from website to web-common
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const sizes = typography[type].sizes as any
  const lineHeights = typography[type].lineHeights as any
  /* eslint-enable @typescript-eslint/no-explicit-any */

  const fontSize = scaleCss(scale, sizes[size][breakpointName])
  const lineHeight = scaleCss(scale, lineHeights[size][breakpointName])

  return fontSize || lineHeight
    ? css`
        ${breakpoints[breakpointName]} {
          font-size: ${fontSize};
          line-height: ${lineHeight};
        }
      `
    : css``
}

const textStyle: TextStyleFunc = (type, size, weight, options) => {
  // TODO Should remove the eslint disable.
  // Now just disabled when file was moved from website to web-common
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const sizes = typography[type].sizes as any
  const lineHeights = typography[type].lineHeights as any
  const weights = typography[type].weights as any
  /* eslint-enable @typescript-eslint/no-explicit-any */

  const breakpointSizes = sizes[size]
  const breakpointLineHeights = lineHeights[size]

  return css`
    font-family: ${typography[type].family};
    font-weight: ${weights[weight]};

    font-size: ${scaleCss(options?.scale?.xs, breakpointSizes.xs)};
    line-height: ${scaleCss(options?.scale?.xs, breakpointLineHeights.xs)};

    ${generateMediaQueryValues("sm", type, size, options?.scale?.sm)}

    ${generateMediaQueryValues("md", type, size, options?.scale?.md)}

    ${generateMediaQueryValues("lg", type, size, options?.scale?.lg)}
  `
}

export const theme: BrandTheme = {
  borderRadius: {
    xsmall: rem(8),
    small: rem(16),
    medium: rem(24),
  },
  breakpoints,
  colors,
  iconSizing,
  shadows: {
    flat: css`
      box-shadow: none;
    `,
    skim: css`
      box-shadow: 0 1px 4px ${opacify(0.2, colors.greyDark)};
    `,
    lifted: css`
      box-shadow: 0 2px 8px ${opacify(0.2, colors.greyDark)};
    `,
    raised: css`
      box-shadow: 0 4px 16px ${opacify(0.2, colors.greyDark)};
    `,
    floating: css`
      box-shadow: 0 6px 24px ${opacify(0.2, colors.greyDark)};
    `,
  },
  spacings: {
    xsmall: `0.25rem`,
    small: `0.5rem`,
    medium: `1rem`,
    large: `1.5rem`,
    xlarge: `2rem`,
    xxlarge: `3rem`,
  },
  textReadabilityOutline,
  textStyle,
  typography,
  marginStyle,
}

export interface StyledTheme {
  flexboxgrid: ITheme
}

export const styledTheme: StyledTheme = {
  flexboxgrid: gridConfig,
}
