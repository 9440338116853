import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const HeartIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Path 69"
          d="M0 16.374a20.686 20.686 0 002.8 10.152 41.216 41.216 0 007.86 9.807 81.241 81.241 0 0011.84 9.142 3.789 3.789 0 00.752.394 1.724 1.724 0 001.5 0 3.789 3.789 0 00.752-.394 80.662 80.662 0 0011.827-9.143 41.63 41.63 0 007.86-9.807A20.617 20.617 0 0048 16.373a15.955 15.955 0 00-1.023-5.815 13.921 13.921 0 00-2.846-4.571 12.57 12.57 0 00-4.3-2.982 13.745 13.745 0 00-5.409-1.047 11.978 11.978 0 00-6.086 1.589 11.832 11.832 0 00-4.337 4.226 11.805 11.805 0 00-4.349-4.226 12.045 12.045 0 00-6.1-1.589 13.681 13.681 0 00-5.384 1.047 12.7 12.7 0 00-4.312 2.982 13.655 13.655 0 00-2.846 4.571A16.129 16.129 0 000 16.374zm3.031 0a12.572 12.572 0 011.354-5.963 10.008 10.008 0 013.733-3.992 10.293 10.293 0 015.409-1.429 9.227 9.227 0 014 .825 10.276 10.276 0 012.999 2.117 14.274 14.274 0 012.094 2.723 3.783 3.783 0 00.715.813 1.081 1.081 0 00.665.222 1.03 1.03 0 00.653-.222 4.048 4.048 0 00.7-.813 17.045 17.045 0 012.144-2.71 10.26 10.26 0 012.969-2.131 9.126 9.126 0 014-.825 10.246 10.246 0 015.384 1.429 10.094 10.094 0 013.745 3.992 12.479 12.479 0 011.368 5.963 16.158 16.158 0 01-1.657 6.959 32.249 32.249 0 01-4.521 6.914 54.917 54.917 0 01-6.567 6.542 81.583 81.583 0 01-7.774 5.828 1.213 1.213 0 01-.444.246 1.072 1.072 0 01-.415-.246 78.743 78.743 0 01-7.786-5.828 56.94 56.94 0 01-6.579-6.542 31.412 31.412 0 01-4.535-6.914 16.257 16.257 0 01-1.654-6.958z"
        />
        <path
          data-name="Line 6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M23.878 42.82V23.195"
        />
        <path
          data-name="Line 7"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M16.589 30.484l7.289-7.289"
        />
        <path
          data-name="Line 8"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M31.167 30.484l-7.289-7.289"
        />
      </g>
    </svg>
  )
}
