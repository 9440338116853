import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ArrowLeft2Icon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.406 24a1.5 1.5 0 011.5-1.5h42.188a1.5 1.5 0 010 3H2.906a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.654 3.252a1.5 1.5 0 010 2.121L5.028 24l18.626 18.627a1.5 1.5 0 01-2.12 2.121L1.845 25.061a1.5 1.5 0 010-2.122L21.533 3.252a1.5 1.5 0 012.121 0z"
        fill="currentColor"
      />
    </svg>
  )
}
