import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const RestTimeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_252)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M21.066 8.866A18.067 18.067 0 1039.06 25.314a1.5 1.5 0 012.988-.268 21.066 21.066 0 11-20.982-19.18c.844 0 1.688.05 2.526.15a1.5 1.5 0 01-.356 2.98c-.72-.087-1.445-.13-2.17-.13" />
        <path d="M13.06 20.932a1.5 1.5 0 012.1-.3l6.804 5.1c.378.283.6.728.6 1.2v11.74a1.5 1.5 0 01-3 0v-10.99l-6.204-4.65a1.5 1.5 0 01-.3-2.1zM24.63 15.228a1.5 1.5 0 011.5-1.5H32a1.5 1.5 0 011.124 2.493l-3.667 4.149H32a1.5 1.5 0 010 3h-5.87a1.5 1.5 0 01-1.124-2.493l3.666-4.149H26.13a1.5 1.5 0 01-1.5-1.5zM36.718 3.002a1.5 1.5 0 110-3H46.5a1.5 1.5 0 011.06 2.56l-7.22 7.222h6.16a1.5 1.5 0 010 3h-9.782a1.5 1.5 0 01-1.06-2.56l7.22-7.222h-6.16z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_252">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
