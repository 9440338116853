export const fontsCssUrl =
  "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Maven+Pro:wght@500;700;800&display=swap"

export type TypographyTypes = "default" | "heading" | "paragraph"

export type FontFamily = {
  family: string
}

export type FontFamilies = Record<TypographyTypes, FontFamily>

/*
 * NOTE: If modifying font families remember to update the
 * imported fontsCssUrl and .storybook/preview-body.html and
 * .storybook/preview-head.html.
 */
export const fontFamilies: FontFamilies = {
  default: {
    family: "'Montserrat', Arial, sans-serif",
  },
  heading: {
    family: "'Maven Pro', Arial, sans-serif",
  },
  paragraph: {
    family: "'Montserrat', Arial, sans-serif",
  },
}
