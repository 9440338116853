import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ArrowDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M5.672 14.325a1.442 1.442 0 012.042 0L24 30.614l16.286-16.289a1.444 1.444 0 012.042 2.042L25.021 33.674a1.442 1.442 0 01-2.042 0L5.672 16.367a1.442 1.442 0 010-2.042z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}
