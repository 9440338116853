import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ArrowBackIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M40 24a1.143 1.143 0 00-1.143-1.143H11.9l7.193-7.19a1.144 1.144 0 10-1.618-1.618l-9.139 9.142a1.143 1.143 0 000 1.618l9.142 9.142a1.146 1.146 0 001.622-1.618l-7.2-7.19h26.957A1.143 1.143 0 0040 24z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}
