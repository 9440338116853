import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MealIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5 5C36.644 5 34 7.716 34 11.5s2.644 6.5 5.5 6.5 5.5-2.716 5.5-6.5S42.356 5 39.5 5zM31 11.5C31 6.448 34.624 2 39.5 2c4.876 0 8.5 4.448 8.5 9.5S44.376 21 39.5 21c-4.876 0-8.5-4.448-8.5-9.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5 18a1.5 1.5 0 011.5 1.5v25a1.5 1.5 0 01-3 0v-25a1.5 1.5 0 011.5-1.5zM8.5 2A1.5 1.5 0 0110 3.5v41a1.5 1.5 0 01-3 0v-41A1.5 1.5 0 018.5 2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 2A1.5 1.5 0 013 3.5v9a5.5 5.5 0 0011 0v-9a1.5 1.5 0 013 0v9a8.5 8.5 0 11-17 0v-9A1.5 1.5 0 011.5 2zM23.045 19.843a1.5 1.5 0 011.91 0 10.675 10.675 0 013.807 6.981c.014.117.014.235 0 .352a10.675 10.675 0 01-3.807 6.981 1.5 1.5 0 01-1.91 0 10.674 10.674 0 01-3.807-6.981 1.502 1.502 0 010-.352 10.674 10.674 0 013.807-6.981zM22.24 27A7.675 7.675 0 0024 30.904 7.674 7.674 0 0025.76 27 7.674 7.674 0 0024 23.096 7.675 7.675 0 0022.24 27z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.725 30.157a9.156 9.156 0 001.895 4.875 9.165 9.165 0 004.877 1.897 9.165 9.165 0 00-1.897-4.877 9.157 9.157 0 00-4.875-1.895zm-1.61-3c3.065-.219 6.1.73 8.494 2.659.084.067.16.143.227.227a12.166 12.166 0 012.66 8.496 1.5 1.5 0 01-1.39 1.39 12.166 12.166 0 01-8.495-2.66 1.499 1.499 0 01-.227-.228 12.156 12.156 0 01-2.658-8.494 1.5 1.5 0 011.389-1.39z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.391 29.816a12.156 12.156 0 018.494-2.658 1.5 1.5 0 011.39 1.389c.219 3.065-.731 6.1-2.659 8.494-.067.084-.144.16-.227.227a12.168 12.168 0 01-8.496 2.66 1.5 1.5 0 01-1.39-1.389 12.156 12.156 0 012.661-8.496c.067-.084.143-.16.227-.227zm2.009 2.236a9.157 9.157 0 00-1.897 4.877 9.169 9.169 0 004.877-1.897 9.156 9.156 0 001.895-4.875 9.158 9.158 0 00-4.875 1.895z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 36.932a1.5 1.5 0 011.5 1.5V43a1.5 1.5 0 01-3 0v-4.568a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}
