import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const CopyIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <g fill="currentColor">
        <path d="M45.055 10.227h-8.203V2.945a1.906 1.906 0 00-1.91-1.914H2.944a1.907 1.907 0 00-1.914 1.914v32.907c0 1.058.852 1.91 1.914 1.91h8.203v7.285c0 1.058.852 1.914 1.91 1.914h31.997a1.91 1.91 0 001.914-1.914V12.14c0-1.051-.852-1.914-1.914-1.914zm-40.2 23.71V4.855H33.02v5.372H13.059c-1.059 0-1.91.855-1.91 1.914v21.797zm38.29 9.208H14.973V14.062h28.16v29.083zm0 0" />
        <path d="M21.871 23.852h14.606a1.91 1.91 0 001.914-1.915 1.91 1.91 0 00-1.914-1.914H21.87c-1.058 0-1.91.856-1.91 1.915 0 1.05.852 1.914 1.91 1.914zm0 0M21.871 37.18h14.606a1.906 1.906 0 001.914-1.91 1.91 1.91 0 00-1.914-1.915H21.87c-1.058 0-1.91.856-1.91 1.915 0 1.058.852 1.91 1.91 1.91zm0 0" />
      </g>
    </svg>
  )
}
