import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ExerciseTime1Icon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_277)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M40.747 25.797a5.054 5.054 0 00-4.384 1.415l-.003.004-1.81 1.8a1.5 1.5 0 01-2.119-.003l-1.799-1.8a5.062 5.062 0 00-8.099 1.314 5.056 5.056 0 00.97 5.859l10 10.428 9.986-10.427.022-.023a5.061 5.061 0 00-.58-7.65 5.054 5.054 0 00-2.184-.917zm-3.285-2.676a8.054 8.054 0 018.183 13.351L35.37 47.204a2.582 2.582 0 01-3.645.082l-.03-.028a1.633 1.633 0 01-.055-.054L21.348 36.473a8.056 8.056 0 01-1.498-9.288 8.06 8.06 0 0112.902-2.095l.743.744.746-.742.002-.002a8.054 8.054 0 013.22-1.969z" />
        <path d="M30.032 29.501a1.5 1.5 0 011.226.727l3.512 5.853 2.641-2.642A1.5 1.5 0 0138.472 33h6.874a1.5 1.5 0 010 3h-6.253l-3.56 3.56a1.5 1.5 0 01-2.347-.288l-3.323-5.538-1.17 1.638a1.5 1.5 0 01-1.22.628h-5.819a1.5 1.5 0 110-3H26.7l2.052-2.872a1.5 1.5 0 011.28-.627z" />
        <path d="M26.027 3.906a17.5 17.5 0 10-7.419 34.003 1.5 1.5 0 01-.325 2.982A20.5 20.5 0 1140.828 17.82a1.5 1.5 0 11-2.974.394A17.5 17.5 0 0026.027 3.906z" />
        <path d="M20.5 7.6A1.5 1.5 0 0122 9.1l-.002 11.4a1.5 1.5 0 01-2.171 1.341l-6.606-3.303a1.5 1.5 0 111.342-2.684l4.435 2.219L19 9.1a1.5 1.5 0 011.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_277">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
