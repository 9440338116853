import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#prefix__a)"
        fill="none"
        stroke="currentColor"
        strokeWidth={3}
      >
        <g data-name="Ellipse 8" transform="rotate(-45 23.25 14.181)">
          <circle cx={16.13} cy={16.13} stroke="none" r={16.13} />
          <circle cx={16.13} cy={16.13} r={14.63} />
        </g>
        <path
          data-name="Line 11"
          strokeLinecap="round"
          d="M30.5 31.5l11.597 11.467"
        />
      </g>
    </svg>
  )
}
