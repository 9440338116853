import React, { FC, useContext, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { GetMeDocument, GetMeQuery } from "../api/gql/admin/generated/graphql"

export interface AdminAuthzContextValue {
  adminUser?: GetMeQuery["me"]
  loading: boolean
}

// Used for old authz and non-admin pages
const defaultValue: AdminAuthzContextValue = {
  // TODO AIS-1046 Improve global role listing
  adminUser: { globalRoles: ["superadmin"] },
  loading: false,
}

const AdminAuthzContext = React.createContext<AdminAuthzContextValue>(
  defaultValue,
)

/**
 * Provide the authz context.
 *
 * If the provider is not present, the default value pretends that the user is a superadmin to retain
 * compatibility with non-admin pages and old authz.
 */
export const AdminAuthzProvider: FC = ({ children }) => {
  const { data, loading, error } = useQuery(GetMeDocument)

  useEffect(() => {
    if (!error) {
      return
    }

    // TODO #AIS-511 Add proper error handling
    // eslint-disable-next-line no-alert
    alert(`Couldn't fetch authorization data: ${error.message}`)
    // eslint-disable-next-line no-console
    console.error(error)
  }, [error])

  return (
    <AdminAuthzContext.Provider
      value={{
        adminUser: data?.me,
        loading,
      }}
    >
      {children}
    </AdminAuthzContext.Provider>
  )
}

export const useAdminAuthz = (): AdminAuthzContextValue =>
  useContext(AdminAuthzContext)
