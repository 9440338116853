import React from "react"

/**
 * Make sure this matches the path of {@link MsalSilentPage}.
 */
export const msalSilenCallbackPath = "/new-admin/entra-id-callbacks/msal-silent"

/**
 * Use with the silent authentication flow.
 *
 * MSAL will open this page in an iframe and collect the code from its URL.
 *
 * **IMPORTANT:** This page must not initialize MSAL, i.e. if the path matches this page
 * ({@link msalSilenCallbackPath}), the initialization of providers etc. must be skipped in
 * the `_app` page component.
 */
const MsalSilentPage: React.FunctionComponent = () => {
  return null
}

export default MsalSilentPage
