import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SendIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="send"
          d="M39.213 8.786a.987.987 0 01.217 1.066L27.943 38.569a1.481 1.481 0 01-2.624.245l-6.274-9.861-9.86-6.273a1.481 1.481 0 01.245-2.626L38.147 8.571a.987.987 0 011.066.217zm-18.2 19.591l5.45 8.564 9.347-23.358zm13.4-16.189l-23.356 9.343 8.566 5.448 14.793-14.791z"
        />
      </g>
    </svg>
  )
}
