import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const GoodEatingIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 37a1.5 1.5 0 011.5-1.5h45a1.5 1.5 0 010 3h-45A1.5 1.5 0 010 37zM15.715 10.707A21.005 21.005 0 0144.978 31.12a1.5 1.5 0 01-2.996-.158 18.008 18.008 0 00-17.974-18.96A18.006 18.006 0 006.02 30.95a1.5 1.5 0 11-2.996.156 21.006 21.006 0 0112.691-20.4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-4.5 1.5a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM17.207 18.095a3.993 3.993 0 015.432 1.448 1.5 1.5 0 11-2.59 1.514.99.99 0 00-1.712 0 1.5 1.5 0 11-2.59-1.514c.352-.602.855-1.1 1.46-1.448zM28.868 20.566a.99.99 0 00-.855.49 1.5 1.5 0 01-2.59-1.513 3.99 3.99 0 016.89 0 1.5 1.5 0 11-2.59 1.514.99.99 0 00-.855-.491zM17.157 25.686a1.5 1.5 0 011.99.733 5.378 5.378 0 009.765 0 1.5 1.5 0 012.724 1.258 8.378 8.378 0 01-15.212 0 1.5 1.5 0 01.733-1.99zM3.962 35.599a1.5 1.5 0 011.937.866L8.015 42h32.03l2.116-5.535a1.5 1.5 0 112.802 1.07l-2.484 6.5a1.5 1.5 0 01-1.401.965H6.982a1.5 1.5 0 01-1.401-.965l-2.484-6.5a1.5 1.5 0 01.865-1.936z"
        fill="currentColor"
      />
    </svg>
  )
}
