import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ResultPageIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.908 3.062a2.04 2.04 0 00-1.407-.562H4.5a2 2 0 00-2 2v39a2 2 0 002 2h36a2 2 0 002-2V10.265a2.06 2.06 0 00-.6-1.431l-5.99-5.77-.002-.002zM34.5.5c1.039 0 2.038.4 2.79 1.116l.004.004 6 5.78.012.012A4.06 4.06 0 0144.5 10.25V43.5a4 4 0 01-4 4h-36a4 4 0 01-4-4v-39a4 4 0 014-4h30z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 19.5a1 1 0 011-1h1.5a1 1 0 110 2H33a1 1 0 01-1-1zM29 10.5a1 1 0 011-1h4.5a1 1 0 110 2H30a1 1 0 01-1-1zM26 28.5a1 1 0 011-1h7.5a1 1 0 110 2H27a1 1 0 01-1-1zM9.5 37.5a1 1 0 011-1h24a1 1 0 110 2h-24a1 1 0 01-1-1zM12.673 8.761a10 10 0 117.654 18.477 10 10 0 01-7.654-18.477zM16.5 10a7.999 7.999 0 100 15.998A7.999 7.999 0 0016.5 10z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 8a1 1 0 011 1v9a1 1 0 01-.447.833l-7.5 4.98a1 1 0 11-1.106-1.666l7.053-4.683V9a1 1 0 011-1z"
        fill="currentColor"
      />
    </svg>
  )
}
