import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Path 72"
          d="M8.848 45.387h30.284q5.063 0 5.063-3.445a10.226 10.226 0 00-1.395-4.863 17.563 17.563 0 00-4.02-4.84 21 21 0 00-6.352-3.691 23.521 23.521 0 00-8.414-1.418 23.592 23.592 0 00-8.437 1.418 21 21 0 00-6.352 3.691 17.563 17.563 0 00-4.02 4.84 10.226 10.226 0 00-1.396 4.863q0 3.445 5.039 3.445zm-.7-2.719a2.089 2.089 0 01-1.137-.223.923.923 0 01-.316-.809 7.657 7.657 0 011.148-3.68 14.154 14.154 0 013.363-3.879 18.49 18.49 0 015.438-3.035 21.111 21.111 0 017.371-1.2 21.037 21.037 0 017.348 1.2 18.49 18.49 0 015.438 3.035 14.154 14.154 0 013.363 3.879 7.657 7.657 0 011.148 3.68.923.923 0 01-.316.809 2.031 2.031 0 01-1.113.223zm15.884-18.726a8.862 8.862 0 004.957-1.465 10.655 10.655 0 003.563-3.9 11.2 11.2 0 001.324-5.437 10.727 10.727 0 00-1.324-5.309 10.4 10.4 0 00-3.562-3.8 9.075 9.075 0 00-4.958-1.419 8.966 8.966 0 00-4.957 1.441 10.665 10.665 0 00-3.574 3.832 10.446 10.446 0 00-1.312 5.273 11.223 11.223 0 001.324 5.426 10.57 10.57 0 003.551 3.9 8.9 8.9 0 004.968 1.458zm0-2.742a6.133 6.133 0 01-3.5-1.066 7.608 7.608 0 01-2.52-2.883 8.785 8.785 0 01-.937-4.09 8.254 8.254 0 01.938-3.926 7.6 7.6 0 012.519-2.823 6.388 6.388 0 017-.023 7.45 7.45 0 012.508 2.8 8.3 8.3 0 01.938 3.949 8.9 8.9 0 01-.926 4.078 7.587 7.587 0 01-2.508 2.906 6.086 6.086 0 01-3.512 1.078z"
        />
      </g>
    </svg>
  )
}
