import { useRouter } from "next/router"
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { NotificationVariant } from "@aistihealth/web-common/src/components/notification/Notification"

export interface NotificationInputBase {
  useI18nKey?: boolean
  // Type `unknown` to allow passing of Error objects directly. If this was a `string` prop,
  // errors of type `any` could easily be passed without converting to string first because they
  // are usually of type `any` (as of the current outdated TS version). Use `.toString()` to
  // convert the value when it's shown.
  additionalInfo?: unknown
  variant: NotificationVariant
  type?: string
  stickTo?: "top"
}

export interface TextNotificationInput extends NotificationInputBase {
  useI18nKey?: false
  message: string
}

export interface TranslationKeyNotificationInput extends NotificationInputBase {
  useI18nKey: true
  messageI18nKey: string
}

export type NotificationInput =
  | TextNotificationInput
  | TranslationKeyNotificationInput

export type Notification = NotificationInput & {
  id: number
}

export interface NotificationContextValue {
  /**
   * Clear notifications.
   *
   * If `type` is set, only the notification of the same type will be cleared (if it exists).
   */
  clearNotifications: (type?: Notification["type"]) => void
  dismissNotification: (id: Notification["id"]) => void
  notifications: Notification[]
  persistNotificationsForOneRouteChange: () => void
  /**
   * Push a notification into the queue.
   *
   * If `type` is set, the possible existing notification of the same type will be cleared.
   */
  pushNotification: (item: NotificationInput) => Notification
}

const createNotInitializedError = () => () => {
  throw new Error(
    `NotificationContext was accessed, but no provider is present.`,
  )
}

const NotificationContext = React.createContext<NotificationContextValue>({
  clearNotifications: createNotInitializedError(),
  dismissNotification: createNotInitializedError(),
  notifications: [],
  persistNotificationsForOneRouteChange: createNotInitializedError(),
  pushNotification: createNotInitializedError(),
})

export const NotificationProvider: FC = ({ children }) => {
  const router = useRouter()

  const [notifications, setNotifications] = useState<Notification[]>([])
  const [persistNotifications, setPersistNotifications] = useState(false)
  const nextId = useRef(0)

  const injectId = useCallback(
    (notificationInput: NotificationInput): Notification => {
      const notification = {
        ...notificationInput,
        id: nextId.current,
      }
      nextId.current = (nextId.current + 1) % Number.MAX_SAFE_INTEGER
      return notification
    },
    [],
  )

  const clearNotifications = useCallback<
    NotificationContextValue["clearNotifications"]
  >(type => {
    if (type !== undefined) {
      setNotifications(prevState =>
        prevState.filter(item => item.type !== type),
      )
    }
    setNotifications([])
  }, [])

  const pushNotification = useCallback<
    NotificationContextValue["pushNotification"]
  >(
    item => {
      const notification = injectId(item)
      setNotifications(prevState => {
        if (notification.type === undefined) {
          return prevState.concat(notification)
        }

        return prevState
          .filter(existingItem => existingItem.type !== notification.type)
          .concat(notification)
      })
      return notification
    },
    [injectId],
  )

  const persistNotificationsForOneRouteChange = useCallback<
    NotificationContextValue["persistNotificationsForOneRouteChange"]
  >(() => {
    setPersistNotifications(true)
  }, [])

  const dismissNotification = (id: Notification["id"]) => {
    setNotifications(prevState => prevState.filter(item => item.id !== id))
  }

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setPersistNotifications(false)

      if (!persistNotifications) {
        setNotifications([])
      }
    }

    router.events.on("routeChangeComplete", handleRouteChangeComplete)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete)
    }
  }, [router.events, persistNotifications])

  return (
    <NotificationContext.Provider
      value={{
        clearNotifications,
        dismissNotification,
        notifications,
        persistNotificationsForOneRouteChange,
        pushNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotifications = (): NotificationContextValue =>
  useContext(NotificationContext)
