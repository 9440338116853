import React, { FC } from "react"
import { generatedIcons } from "../../generated/icons/generated-icons"
import { BrandColor, BrandIcon, theme } from "../../util/styles/theme"

export interface IconProps {
  className?: string
  color?: BrandColor | "currentColor"
  icon: BrandIcon
}

export const Icon: FC<IconProps> = ({ className, color, icon }) => {
  const IconComponent = generatedIcons[icon]
  const colorValue =
    color === "currentColor" ? "currentColor" : color && theme.colors[color]

  return (
    <IconComponent
      className={className}
      color={colorValue}
      fill={colorValue}
      preserveAspectRatio="xMidYMid"
    />
  )
}
