import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SleepingDisorderIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_261)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M22.1 3a17.23 17.23 0 00-11.73 29.87 1.5 1.5 0 01.48 1.1V46.5a1.5 1.5 0 01-3 0V34.61A20.23 20.23 0 0122.103 0c3.783 0 6.908.781 9.538 2.23 2.625 1.445 4.67 3.507 6.363 5.942 2.762 3.972 4.698 9.127 6.703 14.464.41 1.09.822 2.188 1.244 3.285l.001.002A3.002 3.002 0 0143.153 30h-2.302v4.5a7.5 7.5 0 01-7.5 7.5h-1.5v4.5a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5h3a4.5 4.5 0 004.5-4.5v-6a1.5 1.5 0 011.5-1.5h3.797l.003 1.5V27h-.001l1.237.848L43.15 27l1.4-.54-1.4.537c-.45-1.166-.88-2.31-1.3-3.426-2.008-5.333-3.784-10.051-6.311-13.686-1.498-2.155-3.22-3.857-5.347-5.028C28.07 3.688 25.456 3 22.1 3h-.002zm21.05 24v-.003V27z" />
        <path d="M8.77 17.707a1.5 1.5 0 011.5-1.5h4.86a1.5 1.5 0 011.225 2.367l-3.487 4.923h2.566a1.5 1.5 0 010 3H9.968a1.5 1.5 0 01-1.224-2.367l3.487-4.923h-1.96a1.5 1.5 0 01-1.5-1.5zM19.47 8.205a1.5 1.5 0 011.5-1.5h7.205a1.5 1.5 0 011.224 2.367l-5.979 8.44h5.205a1.5 1.5 0 010 3H20.52a1.5 1.5 0 01-1.224-2.367l5.978-8.44H20.97a1.5 1.5 0 01-1.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_261">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
