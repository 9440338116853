import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ThumbsUpIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M25.593 4.214a3.937 3.937 0 00-4.831 3.6 28.407 28.407 0 01-1.084 6.558 13.768 13.768 0 01-2.633 4.15 11.542 11.542 0 01-5.4 3.57 4.426 4.426 0 00-3.433 4.086v10.131a4.013 4.013 0 003.667 3.912 10.852 10.852 0 015.233 1.831l.122.076a12.458 12.458 0 002.456 1.226 11.216 11.216 0 003.712.549h8.863a5.464 5.464 0 004.9-2.694 4.71 4.71 0 00.647-2.312 3.53 3.53 0 00-.195-1.175 8.083 8.083 0 001.236-2.282 4.133 4.133 0 00.01-2.909 5.406 5.406 0 00.4-1.02 8.362 8.362 0 000-4.338 5.428 5.428 0 00-.349-.917 4.811 4.811 0 00.593-4.391 5.248 5.248 0 00-3.039-3.221 16.2 16.2 0 00-6.371-.534q-.562.051-1.122.127a23.714 23.714 0 00-.163-11.415 3.494 3.494 0 00-2.558-2.444zm6.675 37.16h-8.863a8.689 8.689 0 01-2.887-.415 9.758 9.758 0 01-1.965-1l-.1-.061a13.329 13.329 0 00-6.305-2.2 1.488 1.488 0 01-1.4-1.393V26.178a1.918 1.918 0 011.564-1.646 14.085 14.085 0 006.619-4.325 16.2 16.2 0 003.137-5.01 30.607 30.607 0 001.221-7.217 1.405 1.405 0 011.689-1.312l.663.167a.951.951 0 01.729.646 21.118 21.118 0 01-.367 11.965 1.266 1.266 0 001.509 1.632h.008l.035-.008.147-.035a22.558 22.558 0 012.623-.4 13.734 13.734 0 015.343.415 2.746 2.746 0 011.444 1.647 2.352 2.352 0 01-.674 2.588l-.894.894.894.9a1.886 1.886 0 01.39.8 5.8 5.8 0 010 2.945 1.918 1.918 0 01-.39.8l-.894.894.894.9c.119.119.276.448.013 1.236a5.632 5.632 0 01-1.279 2.038l-.894.894.894.9a.635.635 0 01.1.43 2.193 2.193 0 01-.306 1.053 2.946 2.946 0 01-2.7 1.418z" />
      </g>
    </svg>
  )
}
