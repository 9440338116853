import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const PillowIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_260)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M43.218 10.868c1.258-.21 2.634.051 3.65 1.072.032.032.063.065.092.1 1.59 1.916 1.1 4.864-.71 6.831 1.606 7.56 1.606 13.494 0 21.053.887.955 1.488 2.106 1.682 3.278.209 1.258-.05 2.635-1.064 3.654a1.502 1.502 0 01-.1.092c-1.976 1.654-5.05 1.072-7.004-.892l-.006-.006a69.958 69.958 0 01-31.516 0l-.006.006c-.985.99-2.205 1.662-3.454 1.871-1.258.21-2.634-.05-3.65-1.07a1.494 1.494 0 01-.092-.101c-1.59-1.916-1.1-4.864.71-6.831-1.606-7.56-1.606-13.494 0-21.053-.887-.955-1.488-2.106-1.682-3.279-.209-1.258.05-2.634 1.064-3.653.032-.033.065-.063.1-.092 1.97-1.65 5.032-1.075 6.987.875A51.63 51.63 0 0118.44 11.1a1.5 1.5 0 11.167 2.995c-3.472.194-6.95.776-10.433 1.748a1.5 1.5 0 01-1.466-.387l-.598-.6c-1.182-1.188-2.435-1.067-2.9-.748-.155.188-.265.5-.183.994.092.551.419 1.227 1.027 1.837l.399.4a1.5 1.5 0 01.4 1.387c-1.744 7.784-1.744 13.56 0 21.344a1.5 1.5 0 01-.4 1.386l-.399.4c-1.183 1.189-1.068 2.458-.745 2.93.186.156.492.264.978.182.544-.09 1.215-.418 1.823-1.029l.598-.6a1.5 1.5 0 011.415-.4c10.522 2.545 21.232 2.545 31.754 0a1.5 1.5 0 011.416.4l.597.6c1.182 1.188 2.435 1.067 2.9.749.156-.189.265-.5.183-.995-.092-.55-.419-1.226-1.027-1.837l-.398-.4a1.5 1.5 0 01-.401-1.386c1.744-7.784 1.744-13.56 0-21.344a1.5 1.5 0 01.4-1.387l.399-.4c1.184-1.188 1.068-2.457.745-2.93-.186-.155-.492-.263-.978-.182-.544.091-1.215.418-1.823 1.03a1.5 1.5 0 01-2.126-2.117c.985-.99 2.205-1.663 3.454-1.872z" />
        <path d="M25 2A1.5 1.5 0 0126.5.5h8a1.5 1.5 0 011.224 2.367L28.901 12.5H35a1.5 1.5 0 010 3h-9a1.5 1.5 0 01-1.224-2.367L31.599 3.5H26.5A1.5 1.5 0 0125 2zM16.833 20a1.5 1.5 0 011.5-1.5h5.334a1.5 1.5 0 011.224 2.367L20.9 26.5H24a1.5 1.5 0 010 3h-6a1.5 1.5 0 01-1.224-2.367l3.99-5.633h-2.433a1.5 1.5 0 01-1.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_260">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
