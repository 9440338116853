import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const CircleEqualsIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2 1.945c-2.55.216-4.951.827-7.193 1.831C8.283 6.788 3.496 12.901 2.214 20.112a22.128 22.128 0 003.661 16.584 22.846 22.846 0 004.493 4.733c5.562 4.372 12.95 5.801 19.794 3.83a22.541 22.541 0 005.67-2.556c2.06-1.3 4.046-3.098 5.596-5.071 4.97-6.321 6.09-14.9 2.917-22.334a22.2 22.2 0 00-6.713-8.727 22.125 22.125 0 00-10.68-4.507c-1.386-.188-3.362-.237-4.752-.12zm.48 2.98c-.958.091-1.343.139-1.943.241-2.435.414-4.753 1.303-6.913 2.65-2.35 1.466-4.538 3.654-6.008 6.008-1.526 2.443-2.411 4.947-2.81 7.944-.117.89-.117 3.574 0 4.464.4 3.003 1.278 5.489 2.81 7.944 1.466 2.35 3.655 4.54 6.008 6.008 2.445 1.526 4.953 2.413 7.944 2.809.915.12 3.528.12 4.464-.002a19.992 19.992 0 003.117-.636 19.153 19.153 0 0013.006-13.006c.28-.95.485-1.957.636-3.117.124-.96.124-3.504 0-4.464A19.15 19.15 0 0026.256 5.013c-.567-.074-3.085-.136-3.576-.089zm-9.605 13.558c-.296.114-.643.443-.794.754-.16.325-.178.814-.045 1.167.116.31.427.658.732.821l.208.111h21.648l.196-.105c.543-.29.857-.835.827-1.431-.03-.593-.405-1.12-.936-1.32-.257-.095-.344-.096-10.925-.093-10.478.002-10.67.004-10.911.096zm.063 8.038c-.254.094-.514.29-.695.526-.575.748-.292 1.854.577 2.259l.252.117h21.456l.25-.116c.524-.245.878-.793.878-1.364 0-.437-.118-.708-.453-1.043-.501-.501.739-.452-11.424-.449-8.97.003-10.688.014-10.841.07z"
        fill="currentColor"
      />
    </svg>
  )
}
