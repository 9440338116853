import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const EyeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Subtraction 8"
          d="M24.029 39.129a25.371 25.371 0 01-6.653-.853 29.06 29.06 0 01-5.818-2.25 30.228 30.228 0 01-4.808-3.084 29.226 29.226 0 01-3.639-3.384A17.824 17.824 0 01.8 26.412a5.275 5.275 0 01-.8-2.347 5.275 5.275 0 01.8-2.346 17.785 17.785 0 012.311-3.146 28.847 28.847 0 013.648-3.384 30.636 30.636 0 014.817-3.085 29.136 29.136 0 015.818-2.25A25.35 25.35 0 0124.029 9a25.68 25.68 0 016.689.852 29.028 29.028 0 015.836 2.25 29.917 29.917 0 014.8 3.085 28.35 28.35 0 013.613 3.384 17.824 17.824 0 012.285 3.146 5.32 5.32 0 01.791 2.346 5.321 5.321 0 01-.791 2.347 17.864 17.864 0 01-2.285 3.146 28.406 28.406 0 01-3.613 3.384 30.034 30.034 0 01-4.79 3.084 28.72 28.72 0 01-5.827 2.25 25.724 25.724 0 01-6.708.855zm0-27.352a20.7 20.7 0 00-5.572.747 25.89 25.89 0 00-5.009 1.969 30.982 30.982 0 00-4.236 2.645 29.475 29.475 0 00-3.279 2.822 18.3 18.3 0 00-2.118 2.488 3.5 3.5 0 00-.739 1.616 3.747 3.747 0 00.739 1.759 15.967 15.967 0 002.118 2.522 29.4 29.4 0 003.279 2.786 29.72 29.72 0 004.236 2.593 26.1 26.1 0 005.009 1.907 21.4 21.4 0 005.572.721 21.237 21.237 0 005.547-.721 26.194 26.194 0 005-1.907 30.188 30.188 0 004.245-2.593 28.458 28.458 0 003.279-2.786 16.682 16.682 0 002.118-2.522 3.724 3.724 0 00.747-1.759 3.476 3.476 0 00-.747-1.616A19.264 19.264 0 0042.1 19.96a28.529 28.529 0 00-3.279-2.822 31.487 31.487 0 00-4.245-2.645 25.993 25.993 0 00-5-1.969 20.55 20.55 0 00-5.547-.747zm0 22.131a9.655 9.655 0 01-3.858-.773A10.016 10.016 0 0117.033 31a10.072 10.072 0 01-2.119-3.146 9.783 9.783 0 01-.782-3.788 9.759 9.759 0 01.765-3.849 9.835 9.835 0 012.118-3.137 9.894 9.894 0 013.147-2.1 9.894 9.894 0 013.866-.756 9.806 9.806 0 013.832.756 9.886 9.886 0 013.146 2.1 9.84 9.84 0 012.119 3.137 9.759 9.759 0 01.764 3.849 9.56 9.56 0 01-.764 3.788A10.072 10.072 0 0131.008 31a9.973 9.973 0 01-3.146 2.137 9.627 9.627 0 01-3.833.771zM24 19a5.006 5.006 0 00-5 5 5.006 5.006 0 005 5 5.005 5.005 0 005-5 5.006 5.006 0 00-5-5z"
          stroke="currentColor"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}
