import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
  ObservableSubscription,
} from "@apollo/client"
import { AdminAuthContextValue } from "@aistihealth/web-common/src/msal/AdminAuthContext"
import { RefObject } from "react"

export type GqlApiType = "admin" | "public"

const baseUrl = process.env.NEXT_PUBLIC_WS_EXT_APP_BASE_URL
const apiSuffix = process.env.NEXT_PUBLIC_EXT_APP_GQL_API_SUFFIX

const sharedDefaultOptions = {
  fetchPolicy: "no-cache" as const,
}

class AdminAuthnLink extends ApolloLink {
  private adminAuthContextRef: RefObject<AdminAuthContextValue>

  constructor(adminAuthContextRef: RefObject<AdminAuthContextValue>) {
    super()
    this.adminAuthContextRef = adminAuthContextRef
  }

  request(
    operation: Operation,
    forward: NextLink,
  ): Observable<FetchResult> | null {
    // Observable stuff adapted from https://github.com/apollographql/apollo-client/blob/820f6e656377fbc4f7d870976fe3563d32ec1ec1/src/link/context/index.ts
    return new Observable(observer => {
      let handle: ObservableSubscription
      let closed = false

      /**
       * `null` means "abort".
       */
      const getAccessToken = async (): Promise<string | null> => {
        if (!this.adminAuthContextRef?.current) {
          // eslint-disable-next-line no-console
          console.error(
            `AdminAuthContext hasn't been initialized. Aborting GQL request "${operation.operationName}".`,
          )
          return null
        }

        // eslint-disable-next-line no-constant-condition
        while (true) {
          /* eslint-disable no-await-in-loop */
          const result = await this.adminAuthContextRef.current.authenticate()

          switch (result) {
            case "busyPleaseRetry": {
              // MSAL is working on something, retry after a little while
              await new Promise<void>(resolve => {
                setTimeout(() => {
                  resolve()
                }, 100)
              })
              break
            }
            case "interactionRequired": {
              // MSAL needs user interaction, i.e. the login page will be displayed. Abort the request.
              // eslint-disable-next-line no-console
              console.error(
                `Interactive authentication required. Aborting GQL request "${operation.operationName}".`,
              )
              return null
            }
            default: {
              return result.accessToken
            }
          }
          /* eslint-enable no-await-in-loop */
        }
      }

      getAccessToken()
        .then(accessToken => {
          if (accessToken === null) {
            // Abort (don't call `forward`)
            return
          }

          // Success, inject access token
          operation.setContext(({ headers }) => ({
            headers: {
              ...headers,
              authorization: accessToken,
            },
          }))

          if (closed) {
            // No need to subscribe
            return
          }

          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        closed = true
        if (handle) {
          handle.unsubscribe()
        }
      }
    })
  }
}

export type InitApolloParams =
  | {
      apiType: "admin"
      adminAuthContextRef: RefObject<AdminAuthContextValue>
    }
  | {
      apiType: "public"
    }

export const initApollo = (
  args: InitApolloParams,
): ApolloClient<Record<string, unknown>> => {
  const { apiType } = args
  const authLink =
    apiType === "admin"
      ? new AdminAuthnLink(args.adminAuthContextRef)
      : undefined

  const httpLink = createHttpLink({
    uri: `${baseUrl + apiSuffix}/${apiType}`,
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      mutate: sharedDefaultOptions,
      query: sharedDefaultOptions,
      watchQuery: sharedDefaultOptions,
    },
    link: authLink ? authLink.concat(httpLink) : httpLink,
  })
}

export interface ApolloContext {
  context: {
    headers: {
      authorization: string
    }
  }
}

/**
 * @deprecated Don't use. Apollo authenticates automatically now.
 */
export const createApolloContext = (_auth: unknown) => ({})
