import { AccountInfo, AuthenticationResult } from "@azure/msal-browser"
import React, { useContext } from "react"

export type AdminAuthAuthenticationResult =
  | AuthenticationResult
  | "busyPleaseRetry"
  | "interactionRequired"

export interface AdminAuthContextValue {
  account: AccountInfo | null
  /**
   * Authenticate with MSAL.
   *
   * This is called automatically by Apollo, but you might need to call this manually e.g.
   * if you need the file API.
   *
   * Either returns an MSAL {@link AuthenticationResult}, or a string if authentication
   * couldn't be completed:
   * - `"busyPleaseRetry"` if the operation should be retried by the caller, i.e. `busy` is
   * `true`.
   * - `"interactionRequired"` if interactive login is required. The login page will be
   * automatically displayed.
   *
   * Generally, do these when calling and you'll be fine:
   * - If `busy` is `true`, prevent `authenticate` calls by preventing user interaction.
   * - If `busy` is `false`, but the return value is a string, abort whatever you were
   * supposed to do because the login page will be displayed anyway.
   */
  authenticate: () => Promise<AdminAuthAuthenticationResult>
  /**
   * Is MSAL in the middle of something?
   *
   * If `true`, either don't call `authenticate` at all or be prepared to try again later.
   */
  busy: boolean
  getAzureAdAuthority: () => string
}

const createNotInitializedError = (functionName: string) => () => {
  throw new Error(
    `AdminAuthContext: ${functionName} was called, but no provider is present.`,
  )
}

export const AdminAuthContext = React.createContext<AdminAuthContextValue>({
  account: null,
  authenticate: createNotInitializedError("authenticate"),
  busy: true,
  getAzureAdAuthority: createNotInitializedError("getAzureAdAuthority"),
})

export const useAdminAuthContext = (): AdminAuthContextValue =>
  useContext(AdminAuthContext)
