import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ArrowUp2Icon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 1.406a1.5 1.5 0 011.5 1.5v42.188a1.5 1.5 0 01-3 0V2.906a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.94 1.846a1.5 1.5 0 012.12 0l19.688 19.687a1.5 1.5 0 01-2.121 2.121L24 5.028 5.373 23.654a1.5 1.5 0 11-2.121-2.12L22.939 1.845z"
        fill="currentColor"
      />
    </svg>
  )
}
