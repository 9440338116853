import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SleepSheepIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_278)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M.5 46.006a1.5 1.5 0 011.5-1.5h44a1.5 1.5 0 010 3H2a1.5 1.5 0 01-1.5-1.5z" />
        <path d="M29.794 31.795a1.5 1.5 0 011.5 1.5v12.711a1.5 1.5 0 01-3 0v-12.71a1.5 1.5 0 011.5-1.5zM18.08 27.884a1.5 1.5 0 011.5 1.5v16.622a1.5 1.5 0 01-3 0V29.384a1.5 1.5 0 011.5-1.5zM21.405.623c1.441.193 2.599.874 3.485 1.737C26.66 1.205 28.797.845 30.993 1.21l.068.013c2.823.605 5.18 2.604 6.107 5.304.335.021.67.083 1 .186 3.676.785 6.107 4.442 5.404 8.137-.23 1.879-1.277 3.209-2.48 4.128.145.819.087 1.582-.074 2.388M21.405.623c-3.726-.715-7.417 1.76-8.164 5.486v.003l-2.15 10.555-.002.005c-.75 3.746 1.733 7.47 5.479 8.219 1.825.365 3.6-.063 5.067-.944.984 1.412 2.495 2.53 4.304 2.896 1.81.398 3.447-.005 4.768-.783a6.491 6.491 0 002.092.786c3.746.75 7.47-1.733 8.219-5.479M20.87 3.575c-2.12-.424-4.264 1.005-4.688 3.126l-.001.005-2.15 10.555v.002c-.423 2.12 1.005 4.262 3.125 4.686 1.37.274 2.838-.262 3.925-1.35a1.5 1.5 0 012.484.586c.436 1.31 1.609 2.446 2.978 2.72l.031.006c1.214.27 2.316-.115 3.212-.861a1.5 1.5 0 012.021.091c.338.339.834.614 1.58.763 2.122.425 4.265-1.004 4.689-3.125.154-.77.14-1.245-.118-1.887a1.5 1.5 0 01.649-1.86c1.151-.657 1.87-1.484 1.991-2.578.005-.043.011-.086.02-.128.424-2.122-1.005-4.265-3.126-4.689a1.498 1.498 0 01-.18-.048 1.215 1.215 0 00-.81 0 1.5 1.5 0 01-1.96-1.21c-.294-2.054-1.917-3.738-4.076-4.214-1.927-.312-3.555.18-4.721 1.346a1.5 1.5 0 01-2.309-.228c-.622-.933-1.453-1.565-2.458-1.69a1.512 1.512 0 01-.108-.018z" />
        <path d="M8.856 27.722a1.5 1.5 0 01-2.765-1.164l1.384.577c-1.384-.577-1.384-.577-1.383-.578v-.002l.002-.003.003-.01.01-.021a3.678 3.678 0 01.127-.267c.084-.165.206-.388.372-.649.33-.52.838-1.202 1.565-1.872 1.481-1.362 3.828-2.623 7.227-2.397a1.5 1.5 0 11-.2 2.994c-2.469-.165-4.033.725-4.996 1.611a6.395 6.395 0 00-1.062 1.27 5.014 5.014 0 00-.278.498l-.006.013zM31.632 24.391a1.5 1.5 0 00-2.955.517c.044.264.206.784.247.916l.014.04c1.116 3.238 3.113 4.988 4.885 5.904.87.45 1.671.69 2.262.82a7.64 7.64 0 00.944.146l.07.005h.024l.01.001h.005c.001 0 .002 0 .062-1.498l-.06 1.499a1.5 1.5 0 00.132-2.997h.001-.01m.009 0l-.011-.001a4.673 4.673 0 01-.535-.085 6.284 6.284 0 01-1.526-.556c-1.159-.598-2.58-1.777-3.42-4.196a16.925 16.925 0 01-.148-.515M16.18 6.901l-.003.019c-.003.02-.01.055-.016.103a5.514 5.514 0 00-.045.425c-.025.371-.022.845.068 1.315.09.471.256.87.504 1.168.229.275.593.544 1.25.675a1.5 1.5 0 01-.588 2.942c-1.298-.26-2.278-.87-2.966-1.696-.669-.802-.992-1.723-1.146-2.523a8.237 8.237 0 01-.115-2.083 8.53 8.53 0 01.102-.856l.01-.061.005-.02.001-.008v-.003h.001c0-.002 0-.002 1.469.304l-1.469-.306a1.5 1.5 0 012.939.605" />
        <path d="M16.802 4.63a1.5 1.5 0 01-1.32 1.661l-8.801 1.01a3.508 3.508 0 00-.384 6.906l-.335 1.462.335-1.462 6.555 1.503a1.5 1.5 0 11-.67 2.924L5.626 17.13a6.508 6.508 0 01.712-12.81l8.802-1.01a1.5 1.5 0 011.662 1.319zM40.972 13.16a1.5 1.5 0 012.093-.348 30.107 30.107 0 013.904 3.289 1.5 1.5 0 01-2.121 2.121 27.104 27.104 0 00-3.527-2.969 1.5 1.5 0 01-.349-2.092z" />
        <path d="M6.394 10.48a1.5 1.5 0 011.5-1.5h.978a1.5 1.5 0 010 3h-.978a1.5 1.5 0 01-1.5-1.5zM16.71 33.303a1.5 1.5 0 011.898-.948l11.733 3.91a1.5 1.5 0 01-.949 2.847L17.66 35.201a1.5 1.5 0 01-.949-1.898z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_278">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
