import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const CircleCheckIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.608 1.445c-5.92.334-11.564 3.094-15.547 7.603-4.548 5.148-6.477 11.96-5.328 18.816.288 1.725.844 3.56 1.59 5.256 2.4 5.46 6.962 9.814 12.517 11.95 2.375.912 4.521 1.362 7.116 1.493 4.553.229 9.105-.96 13.02-3.4 4.045-2.523 7.337-6.471 9.033-10.835.958-2.468 1.421-4.63 1.554-7.258a22.525 22.525 0 00-2.367-11.222c-1.766-3.533-4.62-6.703-7.98-8.864-4.04-2.6-8.715-3.814-13.608-3.539zm.12 3.001c-3.267.26-6.017 1.12-8.807 2.754-1.964 1.15-4.104 3.078-5.567 5.017a19.584 19.584 0 00-2.535 19.055c1.04 2.591 2.463 4.715 4.485 6.7 1.578 1.547 2.943 2.528 4.864 3.493 2.2 1.105 4.208 1.706 6.696 2.005 1.012.122 3.26.122 4.272 0 2.489-.299 4.497-.9 6.696-2.005 1.921-.965 3.286-1.946 4.864-3.494 1.653-1.62 2.76-3.131 3.769-5.139.95-1.89 1.539-3.677 1.853-5.622.172-1.066.218-1.568.25-2.754.1-3.59-.781-7.05-2.614-10.272-1.203-2.112-3.142-4.299-5.176-5.834-2.771-2.092-6.013-3.385-9.57-3.815-.62-.075-2.917-.134-3.48-.089zm11.983 8.451c-.122.044-.323.185-.488.345-.154.148-3.545 4.406-7.535 9.462s-7.318 9.251-7.396 9.324c-.208.194-.468.184-.65-.024-.076-.086-1.164-1.614-2.418-3.394-1.254-1.781-2.35-3.31-2.437-3.396-.449-.449-1.222-.552-1.76-.234-.506.3-.763.748-.764 1.333 0 .317.02.406.14.63.078.145 1.188 1.75 2.469 3.568 2.487 3.532 2.733 3.84 3.361 4.206.171.1.484.24.695.31.342.114.458.128 1.056.129.611 0 .706-.011 1.05-.132.558-.194 1.114-.54 1.453-.903.4-.427 15.07-19.053 15.154-19.241.154-.338.14-.859-.032-1.21-.163-.334-.527-.673-.834-.778-.279-.095-.788-.092-1.063.005z"
        fill="currentColor"
      />
    </svg>
  )
}
