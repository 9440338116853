import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const YogaStandingIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_280)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M32.843 7.637a7.5 7.5 0 10-15 0 7.5 7.5 0 0015 0zm-7.5-4.5a4.5 4.5 0 110 9 4.5 4.5 0 010-9z" />
        <path d="M41.495 16.637a1.61 1.61 0 010 3.22H31.162a1.5 1.5 0 00-1.5 1.5c0 3.076-.433 4.996-1.087 6.389-.65 1.387-1.578 2.382-2.844 3.55a1.5 1.5 0 00-.483 1.103v10.233c0 1.131-.74 2.09-1.806 2.34-1.213.203-2.417-.812-2.417-2.14V32.6a1.5 1.5 0 00-2.233-1.31c-4.466 2.502-9.373 1.648-12.688.673a1.498 1.498 0 00-.098-.025c-.83-.185-1.4-1.153-.961-2.14l.034-.083a1.617 1.617 0 012.082-.928 1.5 1.5 0 00.232.067c1.043.208 2.357.43 3.906.43l.287.001c1.312.006 3.348.014 5.084-.87a5.865 5.865 0 002.517-2.453c.63-1.147.98-2.587 1.035-4.357a1.5 1.5 0 00-1.5-1.547H8.088a1.71 1.71 0 110-3.42h33.407zm4.61 1.61a4.61 4.61 0 00-4.61-4.61H8.088a4.71 4.71 0 100 9.42h8.971c-.122.62-.3 1.096-.5 1.46-.332.604-.761.975-1.251 1.225-1.065.543-2.419.543-3.897.542h-.112c-1.215 0-2.274-.166-3.212-.351-2.32-.795-4.904.363-5.801 2.687-1.127 2.598.284 5.592 3.016 6.234 3.042.89 7.88 1.858 12.723.081v7.897c0 3.077 2.79 5.668 5.982 5.088l.057-.012c2.53-.562 4.184-2.806 4.184-5.276v-9.584c1.163-1.112 2.248-2.334 3.043-4.028.761-1.621 1.214-3.58 1.337-6.163h8.867a4.61 4.61 0 004.61-4.61z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_280">
          <path
            fill="currentColor"
            transform="matrix(-1 0 0 1 48 0)"
            d="M0 0h48v48H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
