import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ActivityIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M13.492 13.496a1.313 1.313 0 011.315-1.313h3.939a1.313 1.313 0 110 2.626v1.313h10.872l1.013-3.039a1.313 1.313 0 011.245-.9h2.626a1.313 1.313 0 010 2.626h-1.681l-.817 2.455 2.119 3.387a7.878 7.878 0 11-2.231 1.392l-1.334-2.132-5.451 8.723a1.313 1.313 0 01-1.113.617h-5.36a7.878 7.878 0 11-4.766-8.6l2.248-3.6v-2.242h-1.309a1.313 1.313 0 01-1.315-1.313zm3.939 6.415l-1.334 2.138a7.887 7.887 0 012.539 4.577h2.991zm6.565 5.551l4.2-6.715h-8.394zm-8.043 1.163a5.244 5.244 0 00-1.279-2.3l-1.439 2.3zm-3.5-3.7a5.252 5.252 0 103.5 6.323h-5.085a1.313 1.313 0 01-1.113-2.005zm20.868 1.392a5.252 5.252 0 102.227-1.392l2.694 4.314a1.313 1.313 0 01-2.227 1.392l-2.695-4.308z" />
      </g>
    </svg>
  )
}
