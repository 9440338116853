import * as React from "react"

import { ActivityIcon } from "./ActivityIcon"
import { AlcoholIcon } from "./AlcoholIcon"
import { AlertIcon } from "./AlertIcon"
import { AnxietyIcon } from "./AnxietyIcon"
import { ArrowBackIcon } from "./ArrowBackIcon"
import { ArrowDownIcon } from "./ArrowDownIcon"
import { ArrowDown2Icon } from "./ArrowDown2Icon"
import { ArrowLeft2Icon } from "./ArrowLeft2Icon"
import { ArrowRight2Icon } from "./ArrowRight2Icon"
import { ArrowUp2Icon } from "./ArrowUp2Icon"
import { CalendarIcon } from "./CalendarIcon"
import { ChartsIcon } from "./ChartsIcon"
import { CheckIcon } from "./CheckIcon"
import { CircleCheckIcon } from "./CircleCheckIcon"
import { CircleEqualsIcon } from "./CircleEqualsIcon"
import { CircleExclamationIcon } from "./CircleExclamationIcon"
import { CircleMinusIcon } from "./CircleMinusIcon"
import { CirclePlusIcon } from "./CirclePlusIcon"
import { CopyIcon } from "./CopyIcon"
import { CrossIcon } from "./CrossIcon"
import { DeleteIcon } from "./DeleteIcon"
import { EatingDisorderIcon } from "./EatingDisorderIcon"
import { EmployeesIcon } from "./EmployeesIcon"
import { ExerciseTime1Icon } from "./ExerciseTime1Icon"
import { ExerciseTime2Icon } from "./ExerciseTime2Icon"
import { EyeIcon } from "./EyeIcon"
import { FamilyHoldIcon } from "./FamilyHoldIcon"
import { FeedbackIcon } from "./FeedbackIcon"
import { FinancialSituationIcon } from "./FinancialSituationIcon"
import { FloppyDiskIcon } from "./FloppyDiskIcon"
import { FocusIcon } from "./FocusIcon"
import { GoodEatingIcon } from "./GoodEatingIcon"
import { HamburgerMenuIcon } from "./HamburgerMenuIcon"
import { HeadSickIcon } from "./HeadSickIcon"
import { HealthPromotionIcon } from "./HealthPromotionIcon"
import { HeartIcon } from "./HeartIcon"
import { HeartRateIcon } from "./HeartRateIcon"
import { HomeIcon } from "./HomeIcon"
import { HourGlassIcon } from "./HourGlassIcon"
import { IdentifyingRiskIcon } from "./IdentifyingRiskIcon"
import { InfoIcon } from "./InfoIcon"
import { KilocaloriesIcon } from "./KilocaloriesIcon"
import { LockShieldIcon } from "./LockShieldIcon"
import { LonelinessIcon } from "./LonelinessIcon"
import { LoveIcon } from "./LoveIcon"
import { LoveReloadIcon } from "./LoveReloadIcon"
import { LoveRibbonIcon } from "./LoveRibbonIcon"
import { MealIcon } from "./MealIcon"
import { MealRythmIcon } from "./MealRythmIcon"
import { MealTimeIcon } from "./MealTimeIcon"
import { MeaningfulLifeIcon } from "./MeaningfulLifeIcon"
import { MeditateIcon } from "./MeditateIcon"
import { MentalHealthIcon } from "./MentalHealthIcon"
import { MessageIcon } from "./MessageIcon"
import { MindfulnessIcon } from "./MindfulnessIcon"
import { NetworkHeartIcon } from "./NetworkHeartIcon"
import { Number1Icon } from "./Number1Icon"
import { Number2Icon } from "./Number2Icon"
import { Number3Icon } from "./Number3Icon"
import { NutritionIcon } from "./NutritionIcon"
import { PdfAttachmentIcon } from "./PdfAttachmentIcon"
import { PeaceIcon } from "./PeaceIcon"
import { PhoneIcon } from "./PhoneIcon"
import { PhoneInHandIcon } from "./PhoneInHandIcon"
import { PillowIcon } from "./PillowIcon"
import { PlusIcon } from "./PlusIcon"
import { PulseIcon } from "./PulseIcon"
import { RemoteWorkIcon } from "./RemoteWorkIcon"
import { ReportsIcon } from "./ReportsIcon"
import { ResearchIcon } from "./ResearchIcon"
import { RestTimeIcon } from "./RestTimeIcon"
import { RestlessLegsIcon } from "./RestlessLegsIcon"
import { ResultPageIcon } from "./ResultPageIcon"
import { RunningIcon } from "./RunningIcon"
import { RunningFastIcon } from "./RunningFastIcon"
import { RunningMusicIcon } from "./RunningMusicIcon"
import { SadnessIcon } from "./SadnessIcon"
import { SearchIcon } from "./SearchIcon"
import { SelfCareIcon } from "./SelfCareIcon"
import { SendIcon } from "./SendIcon"
import { SettingsIcon } from "./SettingsIcon"
import { SignOutIcon } from "./SignOutIcon"
import { SittingIcon } from "./SittingIcon"
import { SleepRecoveryIcon } from "./SleepRecoveryIcon"
import { SleepSheepIcon } from "./SleepSheepIcon"
import { SleepingDisorderIcon } from "./SleepingDisorderIcon"
import { SlidersIcon } from "./SlidersIcon"
import { SlowChangeIcon } from "./SlowChangeIcon"
import { SocialRelationshipsIcon } from "./SocialRelationshipsIcon"
import { SortDownIcon } from "./SortDownIcon"
import { SortDownUpIcon } from "./SortDownUpIcon"
import { SortUpIcon } from "./SortUpIcon"
import { StarIcon } from "./StarIcon"
import { StarNineIcon } from "./StarNineIcon"
import { StressIcon } from "./StressIcon"
import { SupportIcon } from "./SupportIcon"
import { TelescopeIcon } from "./TelescopeIcon"
import { ThumbsUpIcon } from "./ThumbsUpIcon"
import { UserIcon } from "./UserIcon"
import { UsersIcon } from "./UsersIcon"
import { WorkIcon } from "./WorkIcon"
import { YogaStandingIcon } from "./YogaStandingIcon"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */

export type GeneratedIcon =
  | "activity"
  | "alcohol"
  | "alert"
  | "anxiety"
  | "arrowBack"
  | "arrowDown"
  | "arrowDown2"
  | "arrowLeft2"
  | "arrowRight2"
  | "arrowUp2"
  | "calendar"
  | "charts"
  | "check"
  | "circleCheck"
  | "circleEquals"
  | "circleExclamation"
  | "circleMinus"
  | "circlePlus"
  | "copy"
  | "cross"
  | "delete"
  | "eatingDisorder"
  | "employees"
  | "exerciseTime1"
  | "exerciseTime2"
  | "eye"
  | "familyHold"
  | "feedback"
  | "financialSituation"
  | "floppyDisk"
  | "focus"
  | "goodEating"
  | "hamburgerMenu"
  | "headSick"
  | "healthPromotion"
  | "heart"
  | "heartRate"
  | "home"
  | "hourGlass"
  | "identifyingRisk"
  | "info"
  | "kilocalories"
  | "lockShield"
  | "loneliness"
  | "love"
  | "loveReload"
  | "loveRibbon"
  | "meal"
  | "mealRythm"
  | "mealTime"
  | "meaningfulLife"
  | "meditate"
  | "mentalHealth"
  | "message"
  | "mindfulness"
  | "networkHeart"
  | "number1"
  | "number2"
  | "number3"
  | "nutrition"
  | "pdfAttachment"
  | "peace"
  | "phone"
  | "phoneInHand"
  | "pillow"
  | "plus"
  | "pulse"
  | "remoteWork"
  | "reports"
  | "research"
  | "restTime"
  | "restlessLegs"
  | "resultPage"
  | "running"
  | "runningFast"
  | "runningMusic"
  | "sadness"
  | "search"
  | "selfCare"
  | "send"
  | "settings"
  | "signOut"
  | "sitting"
  | "sleepRecovery"
  | "sleepSheep"
  | "sleepingDisorder"
  | "sliders"
  | "slowChange"
  | "socialRelationships"
  | "sortDown"
  | "sortDownUp"
  | "sortUp"
  | "star"
  | "starNine"
  | "stress"
  | "support"
  | "telescope"
  | "thumbsUp"
  | "user"
  | "users"
  | "work"
  | "yogaStanding"

export type GeneratedIconComponents = Record<
  GeneratedIcon,
  React.FC<React.SVGProps<SVGSVGElement>>
>

export const generatedIcons: GeneratedIconComponents = {
  activity: ActivityIcon,
  alcohol: AlcoholIcon,
  alert: AlertIcon,
  anxiety: AnxietyIcon,
  arrowBack: ArrowBackIcon,
  arrowDown: ArrowDownIcon,
  arrowDown2: ArrowDown2Icon,
  arrowLeft2: ArrowLeft2Icon,
  arrowRight2: ArrowRight2Icon,
  arrowUp2: ArrowUp2Icon,
  calendar: CalendarIcon,
  charts: ChartsIcon,
  check: CheckIcon,
  circleCheck: CircleCheckIcon,
  circleEquals: CircleEqualsIcon,
  circleExclamation: CircleExclamationIcon,
  circleMinus: CircleMinusIcon,
  circlePlus: CirclePlusIcon,
  copy: CopyIcon,
  cross: CrossIcon,
  delete: DeleteIcon,
  eatingDisorder: EatingDisorderIcon,
  employees: EmployeesIcon,
  exerciseTime1: ExerciseTime1Icon,
  exerciseTime2: ExerciseTime2Icon,
  eye: EyeIcon,
  familyHold: FamilyHoldIcon,
  feedback: FeedbackIcon,
  financialSituation: FinancialSituationIcon,
  floppyDisk: FloppyDiskIcon,
  focus: FocusIcon,
  goodEating: GoodEatingIcon,
  hamburgerMenu: HamburgerMenuIcon,
  headSick: HeadSickIcon,
  healthPromotion: HealthPromotionIcon,
  heart: HeartIcon,
  heartRate: HeartRateIcon,
  home: HomeIcon,
  hourGlass: HourGlassIcon,
  identifyingRisk: IdentifyingRiskIcon,
  info: InfoIcon,
  kilocalories: KilocaloriesIcon,
  lockShield: LockShieldIcon,
  loneliness: LonelinessIcon,
  love: LoveIcon,
  loveReload: LoveReloadIcon,
  loveRibbon: LoveRibbonIcon,
  meal: MealIcon,
  mealRythm: MealRythmIcon,
  mealTime: MealTimeIcon,
  meaningfulLife: MeaningfulLifeIcon,
  meditate: MeditateIcon,
  mentalHealth: MentalHealthIcon,
  message: MessageIcon,
  mindfulness: MindfulnessIcon,
  networkHeart: NetworkHeartIcon,
  number1: Number1Icon,
  number2: Number2Icon,
  number3: Number3Icon,
  nutrition: NutritionIcon,
  pdfAttachment: PdfAttachmentIcon,
  peace: PeaceIcon,
  phone: PhoneIcon,
  phoneInHand: PhoneInHandIcon,
  pillow: PillowIcon,
  plus: PlusIcon,
  pulse: PulseIcon,
  remoteWork: RemoteWorkIcon,
  reports: ReportsIcon,
  research: ResearchIcon,
  restTime: RestTimeIcon,
  restlessLegs: RestlessLegsIcon,
  resultPage: ResultPageIcon,
  running: RunningIcon,
  runningFast: RunningFastIcon,
  runningMusic: RunningMusicIcon,
  sadness: SadnessIcon,
  search: SearchIcon,
  selfCare: SelfCareIcon,
  send: SendIcon,
  settings: SettingsIcon,
  signOut: SignOutIcon,
  sitting: SittingIcon,
  sleepRecovery: SleepRecoveryIcon,
  sleepSheep: SleepSheepIcon,
  sleepingDisorder: SleepingDisorderIcon,
  sliders: SlidersIcon,
  slowChange: SlowChangeIcon,
  socialRelationships: SocialRelationshipsIcon,
  sortDown: SortDownIcon,
  sortDownUp: SortDownUpIcon,
  sortUp: SortUpIcon,
  star: StarIcon,
  starNine: StarNineIcon,
  stress: StressIcon,
  support: SupportIcon,
  telescope: TelescopeIcon,
  thumbsUp: ThumbsUpIcon,
  user: UserIcon,
  users: UsersIcon,
  work: WorkIcon,
  yogaStanding: YogaStandingIcon,
}
