import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const KilocaloriesIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_276)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M5.5 3A2.5 2.5 0 003 5.5v37A2.5 2.5 0 005.5 45h37a2.5 2.5 0 002.5-2.5v-37A2.5 2.5 0 0042.5 3h-37zM0 5.5A5.5 5.5 0 015.5 0h37A5.5 5.5 0 0148 5.5v37a5.5 5.5 0 01-5.5 5.5h-37A5.5 5.5 0 010 42.5v-37z" />
        <path d="M8 16.5A1.5 1.5 0 019.5 18v2.379l3.44-3.44a1.5 1.5 0 012.12 2.122L10.122 24l4.94 4.94a1.5 1.5 0 01-2.122 2.12L9.5 27.622V30a1.5 1.5 0 01-3 0V18A1.5 1.5 0 018 16.5z" />
        <path d="M15.5 26a5.5 5.5 0 015.5-5.5h1a1.5 1.5 0 010 3h-1a2.5 2.5 0 100 5h1a1.5 1.5 0 010 3h-1a5.5 5.5 0 01-5.5-5.5zM29 20.5a1.5 1.5 0 011.404.973l3 8a1.5 1.5 0 11-2.809 1.054L30.21 29.5h-2.42l-.386 1.027a1.5 1.5 0 11-2.809-1.054l3-8A1.5 1.5 0 0129 20.5zm-.086 6h.171L29 26.272l-.086.228zM36 20.5a1.5 1.5 0 011.5 1.5v6.5H40a1.5 1.5 0 010 3h-4a1.5 1.5 0 01-1.5-1.5v-8a1.5 1.5 0 011.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_276">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
