import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const LockShieldIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1916_40)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M13.318 19.318A4.5 4.5 0 0116.5 18h15a4.5 4.5 0 014.5 4.5v9a4.5 4.5 0 01-4.5 4.5h-15a4.5 4.5 0 01-4.5-4.5v-9a4.5 4.5 0 011.318-3.182zM16.5 21a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-9a1.5 1.5 0 00-1.5-1.5h-15z" />
        <path d="M24 27.75a.75.75 0 100-1.5v-1.5a2.25 2.25 0 100 4.5v-1.5z" />
        <path d="M24 24.75a2.25 2.25 0 110 4.5v-1.5a.75.75 0 110-1.5v-1.5zM24 9a6 6 0 00-6 6v4.5a1.5 1.5 0 01-3 0V15a9 9 0 0118 0v4.5a1.5 1.5 0 01-3 0V15a6 6 0 00-6-6z" />
        <path d="M0 1.5A1.5 1.5 0 011.5 0h45A1.5 1.5 0 0148 1.5V21c0 7.363-2.413 13.223-6.648 17.698-4.206 4.443-10.113 7.423-16.965 9.251a1.5 1.5 0 01-.774 0c-6.845-1.826-12.752-4.803-16.96-9.246C2.418 34.23 0 28.368 0 21V1.5zM3 3v18c0 6.652 2.157 11.76 5.832 15.64 3.631 3.835 8.836 6.565 15.168 8.306 6.338-1.743 11.543-4.475 15.173-8.31C42.846 32.753 45 27.646 45 21V3H3z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1916_40">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
