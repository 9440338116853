const path = require("path")
const fs = require("fs")

const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || "development"
const isBrowser = typeof window !== 'undefined'

const getNamespaces = (localePaths, locales, localeExt) => {
  const getLocaleNamespaces = (p) => {
    if (!fs.existsSync(p)) {
      return []
    }
    const ret = []

    fs.readdirSync(p).map((file) => {
      const joinedP = path.join(p, file)
      if (fs.statSync(joinedP).isDirectory()) {
        const subRet = getLocaleNamespaces(joinedP).map(
          n => `${file}/${n}`
        )
        ret.push(...subRet)
        return
      }
      ret.push(file.replace(`.${localeExt}`, ''))
    })
    return ret
  }


  const namespaces = []
  for(const localePath of localePaths) {
    const namespacesByLocale = locales.map(locale =>
      getLocaleNamespaces(
        path.resolve(`${localePath}/${locale}`)
      )
    )
    for (const localNamespaces of namespacesByLocale) {
      namespaces.push(...localNamespaces)
    }
  }

  return Array.from(new Set(namespaces))
}


const globalLocalePath = "../common/localization/locales"
const websiteLocalePath = "./public/locales"
const localeExtension = "json"
const locales = ["fi", "en", "sv"]
const allNamespaces = isBrowser? [] : getNamespaces( [globalLocalePath, websiteLocalePath], locales, localeExtension)

const localePath = (locale, namespace, missing) => {
  if(namespace.startsWith("global/")) {
    return path.resolve(globalLocalePath, locale, namespace, missing ? ".missing" : "")  + "." + localeExtension
  }

  return path.resolve(websiteLocalePath, locale, namespace , missing ? ".missing" : "")  + "." + localeExtension
}

if(!isBrowser) {
  // This is needed to have global locale files to be available on serverless functions
  path.resolve(globalLocalePath)
}

module.exports = {
  i18n: {
    locales,
    defaultLocale: "en",
  },
  defaultNS: "common",
  localeExtension,
  ns: allNamespaces,
  localePath: isBrowser ? undefined : localePath,
  reloadOnPrerender: environment === "development",
  serializeConfig: isBrowser,
}
