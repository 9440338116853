import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SlidersIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.02 24a1.5 1.5 0 011.5-1.5h7.64a1.5 1.5 0 110 3H2.52a1.5 1.5 0 01-1.5-1.5zM18.512 24a1.5 1.5 0 011.5-1.5h25.464a1.5 1.5 0 010 3H20.012a1.5 1.5 0 01-1.5-1.5zM1.022 40.234a1.5 1.5 0 011.5-1.5h25.466a1.5 1.5 0 010 3H2.522a1.5 1.5 0 01-1.5-1.5zM36.34 40.234a1.5 1.5 0 011.5-1.5h7.636a1.5 1.5 0 110 3H37.84a1.5 1.5 0 01-1.5-1.5zM1.022 8.046a1.5 1.5 0 011.5-1.5h25.466a1.5 1.5 0 010 3H2.522a1.5 1.5 0 01-1.5-1.5zM36.34 8.046a1.5 1.5 0 011.5-1.5h7.636a1.5 1.5 0 110 3H37.84a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.627 18.067a6.425 6.425 0 114.918 11.873 6.425 6.425 0 01-4.918-11.873zm2.46 2.511a3.425 3.425 0 10-.001 6.85 3.425 3.425 0 000-6.85zM28.37 35.694a6.426 6.426 0 119.088 9.088 6.426 6.426 0 01-9.088-9.088zm4.544 1.118a3.426 3.426 0 100 6.852 3.426 3.426 0 000-6.852zM28.37 3.226a6.426 6.426 0 119.088 9.088 6.426 6.426 0 01-9.088-9.088zm4.544 1.118a3.426 3.426 0 100 6.852 3.426 3.426 0 000-6.852z"
        fill="currentColor"
      />
    </svg>
  )
}
