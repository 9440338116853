import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const FinancialSituationIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path data-name="Path 75" d="M24 26a2 2 0 10-2-2 2 2 0 002 2z" />
        <path
          data-name="Path 76"
          d="M3 13.5a2.625 2.625 0 012.625-2.625h36.75A2.625 2.625 0 0145 13.5v21a2.625 2.625 0 01-2.625 2.625H5.625A2.625 2.625 0 013 34.5zm7.875 0a5.25 5.25 0 01-5.25 5.25v10.5a5.25 5.25 0 015.25 5.25h26.25a5.25 5.25 0 015.25-5.25v-10.5a5.25 5.25 0 01-5.25-5.25z"
        />
      </g>
    </svg>
  )
}
