import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const TelescopeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_269)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M19.34 14.33a1.5 1.5 0 012.014.667l2.366 4.71a1.5 1.5 0 11-2.68 1.346l-1.693-3.37L8.339 23.21a2.448 2.448 0 00-1.089 3.286v.001a2.446 2.446 0 003.286 1.089l8.34-4.19a1.5 1.5 0 111.347 2.68l-8.34 4.19s.001 0 0 0a5.449 5.449 0 11-4.89-9.736l12.348-6.2z" />
        <path d="M34.331 4.588a1.5 1.5 0 012.013.667l5.318 10.584a1.5 1.5 0 01-.667 2.013l-10.224 5.136a1.5 1.5 0 11-1.346-2.68l8.883-4.463-3.971-7.904-11.01 5.528a2.447 2.447 0 00-1.087 3.285 1.5 1.5 0 11-2.68 1.348 5.445 5.445 0 012.42-7.314l12.351-6.2zM7.25 26.496a1.5 1.5 0 01-.666 2.014l-4.41 2.218a1.5 1.5 0 01-1.348-2.68l4.41-2.218a1.5 1.5 0 012.014.666z" />
        <path d="M38.317 3.724a2.446 2.446 0 00-1.087 3.288l3.548 7.061a2.448 2.448 0 003.287 1.088l.423-.212-5.747-11.437-.424.212zm-3.024-1.373a5.45 5.45 0 011.677-1.307l1.764-.887a1.5 1.5 0 012.014.667l7.094 14.118a1.5 1.5 0 01-.667 2.014l-1.763.886a5.448 5.448 0 01-7.315-2.422L34.55 8.36a5.448 5.448 0 01.743-6.009zM26.911 21.224a4.5 4.5 0 10-2.82 8.547 4.5 4.5 0 002.82-8.547zm-1.965-3.206a7.501 7.501 0 016.235 12.378 7.5 7.5 0 11-6.235-12.378z" />
        <path d="M25.5 30a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-3 0v-15a1.5 1.5 0 011.5-1.5zM21.9 28.35a1.5 1.5 0 01.599 2.034l-9.182 16.834a1.5 1.5 0 11-2.634-1.436l9.182-16.834a1.5 1.5 0 012.035-.599zM29.1 28.35a1.5 1.5 0 012.035.598l9.182 16.834a1.5 1.5 0 01-2.634 1.436l-9.182-16.834a1.5 1.5 0 01.599-2.035z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_269">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
