import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MindfulnessIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_282)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M21.97 3a17.23 17.23 0 00-11.73 29.87 1.5 1.5 0 01.481 1.1V46.5a1.5 1.5 0 01-3 0V34.61A20.23 20.23 0 0121.972 0c3.783 0 6.909.781 9.538 2.23 2.626 1.445 4.67 3.507 6.363 5.942 2.762 3.972 4.698 9.127 6.703 14.464.41 1.09.822 2.188 1.245 3.285v.002A3 3 0 0143.025 30H40.72v4.5a7.5 7.5 0 01-7.5 7.5h-1.5v4.5a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5h3a4.5 4.5 0 004.5-4.5v-6a1.5 1.5 0 011.5-1.5h3.798l.002 1.5V27l1.236.848L43.021 27l1.4-.54-1.4.537c-.45-1.166-.88-2.31-1.3-3.426-2.007-5.333-3.783-10.051-6.31-13.686-1.499-2.155-3.22-3.857-5.348-5.028C27.94 3.688 25.327 3 21.971 3h-.002zM43.02 27v-.003V27z" />
        <path d="M22.464 12.339l-.006.006.006-.006zm-.311.305a9.854 9.854 0 00-2.804 6.574 10.124 10.124 0 002.803 6.582 10.122 10.122 0 002.804-6.582 9.863 9.863 0 00-.857-3.714m-1.946-2.86a9.863 9.863 0 011.946 2.86zm-1.775-2.46a2.555 2.555 0 013.55 0 12.863 12.863 0 014.027 9c.002.032.002.064 0 .096a13.123 13.123 0 01-1.376 5.387m-6.201-14.483a12.856 12.856 0 00-4.03 9v.096a13.12 13.12 0 004.86 9.72 1.5 1.5 0 001.888 0 13.122 13.122 0 003.483-4.333" />
        <path d="M32.892 17.099c-2.037.067-4.016.71-5.706 1.86a1.5 1.5 0 01-1.687-2.481 13.775 13.775 0 017.823-2.385 2.573 2.573 0 012.566 2.54 12.846 12.846 0 01-3.516 9.186 1.486 1.486 0 01-.068.066 13.125 13.125 0 01-10.31 3.436 1.5 1.5 0 01.315-2.983 10.124 10.124 0 004.264-.463m6.319-8.776a9.845 9.845 0 01-2.665 6.62 10.125 10.125 0 01-3.654 2.155" />
        <path d="M10.923 14.093c2.815-.03 5.57.81 7.89 2.405a1.5 1.5 0 01-1.7 2.472 10.672 10.672 0 00-5.759-1.873 9.842 9.842 0 002.66 6.626 10.241 10.241 0 007.98 2.62 1.5 1.5 0 01.316 2.983 13.24 13.24 0 01-10.368-3.433 1.492 1.492 0 01-.072-.071 12.842 12.842 0 01-2.673-4.253m1.736-4.476h.005zm-.01-3a2.575 2.575 0 00-2.565 2.537 12.843 12.843 0 00.839 4.94" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_282">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
