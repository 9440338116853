import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SignOutIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g fillRule="evenodd" clipPath="url(#prefix__a)">
        <path
          data-name="Path 80"
          d="M29.37 36.374A1.372 1.372 0 0128 37.749H6.106a1.372 1.372 0 01-1.369-1.375V11.626a1.372 1.372 0 011.369-1.375H28a1.372 1.372 0 011.37 1.375v5.5a1.369 1.369 0 102.737 0v-5.5A4.115 4.115 0 0028 7.501H6.106A4.115 4.115 0 002 11.626v24.748a4.115 4.115 0 004.106 4.127H28a4.115 4.115 0 004.106-4.125v-5.5a1.369 1.369 0 10-2.737 0z"
        />
        <path
          data-name="Path 81"
          d="M45.592 24.966a1.348 1.348 0 000-1.933l-8.334-8.188a1.408 1.408 0 00-1.967 0 1.35 1.35 0 000 1.933l5.964 5.858H16.828a1.365 1.365 0 100 2.73h24.427l-5.964 5.858a1.35 1.35 0 000 1.933 1.408 1.408 0 001.967 0z"
        />
      </g>
    </svg>
  )
}
