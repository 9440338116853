import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const AlertIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M24 42.322a4.58 4.58 0 004.58-4.58h-9.16a4.58 4.58 0 004.58 4.58zm0-32.251l-1.825.369a9.165 9.165 0 00-7.336 8.98 49.7 49.7 0 01-1.051 8.57 29.626 29.626 0 01-1.519 5.172h23.462a29.766 29.766 0 01-1.518-5.171 49.7 49.7 0 01-1.051-8.57 9.165 9.165 0 00-7.336-8.978l-1.825-.373zm14.245 23.09a5.827 5.827 0 001.786 2.29H7.969a5.827 5.827 0 001.786-2.29c2.062-4.122 2.794-11.725 2.794-13.741a11.455 11.455 0 019.173-11.224 2.29 2.29 0 114.557 0 11.456 11.456 0 019.172 11.224c0 2.016.733 9.619 2.794 13.742z" />
      </g>
    </svg>
  )
}
