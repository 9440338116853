import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const PhoneInHandIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.002 28.378a1.641 1.641 0 00-1.261.301l-.002.002-3.336 2.517a1.5 1.5 0 01-1.806-2.395l3.344-2.524a4.64 4.64 0 016.65 1.153 4.64 4.64 0 01-.002 5.139l-3.28 4.939-.012.02a10.64 10.64 0 01-4.837 3.861l-3.96 1.617V46.5a1.5 1.5 0 01-3 0V42a1.5 1.5 0 01.933-1.389l4.913-2.005a7.64 7.64 0 003.471-2.766l3.274-4.931a1.64 1.64 0 000-1.818l-.002-.003a1.64 1.64 0 00-1.087-.71zM19.38 16.91a1.5 1.5 0 01-.79 1.97l-7.21 3.084a7.56 7.56 0 00-4 3.925l-2.274 5.485-.007.015a7.46 7.46 0 00-.599 2.948V46.5a1.5 1.5 0 01-3 0V34.342a10.46 10.46 0 01.837-4.123l2.277-5.493.012-.028a10.56 10.56 0 015.59-5.5l7.194-3.077a1.5 1.5 0 011.97.79z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3a4.5 4.5 0 00-4.5 4.5v21.38a1.5 1.5 0 01-3 0V7.5A7.5 7.5 0 0124 0h15a7.5 7.5 0 017.5 7.5v33A7.5 7.5 0 0139 48H24a7.5 7.5 0 01-7.5-7.5v-.98a1.5 1.5 0 013 0v.98A4.5 4.5 0 0024 45h15a4.5 4.5 0 004.5-4.5v-33A4.5 4.5 0 0039 3H24z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.34 35.5a1.5 1.5 0 011.5-1.5H45a1.5 1.5 0 010 3H21.84a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}
