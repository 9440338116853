import React, { FC } from "react"
import styled, { css, keyframes } from "styled-components"
import { BrandColor, theme } from "../../util/styles/theme"

export interface LoadingIndicatorProps {
  centered?: boolean
  color: BrandColor
}

const rotateAnimationKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

// Styling based on https://loading.io/css/
const Container = styled.div<LoadingIndicatorProps>`
  position: relative;
  width: 5rem;
  height: 5rem;

  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      width: 100%;
      justify-content: center;
    `}

  div {
    animation: ${rotateAnimationKeyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1)
      infinite;

    border: 0.5rem solid;
    border-color: transparent;
    border-top-color: ${({ color }) => theme.colors[color]};
    border-radius: 50%;

    box-sizing: border-box;
    display: block;
    height: 4rem;
    margin: 0.5rem;
    position: absolute;
    width: 4rem;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`

export const LoadingIndicator: FC<LoadingIndicatorProps> = props => {
  return (
    <Container {...props}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  )
}
