import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ArrowDown2Icon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 1.406a1.5 1.5 0 011.5 1.5v42.188a1.5 1.5 0 01-3 0V2.906a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.252 24.346a1.5 1.5 0 012.121 0L24 42.972l18.627-18.626a1.5 1.5 0 012.121 2.12L25.061 46.155a1.5 1.5 0 01-2.122 0L3.252 26.467a1.5 1.5 0 010-2.121z"
        fill="currentColor"
      />
    </svg>
  )
}
