import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const LoveIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_1838_263)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.413 2.825A12.82 12.82 0 003.87 6.47 12.852 12.852 0 00.06 16.925a12.872 12.872 0 003.247 7.344c.429.471 19.652 20.505 19.76 20.592.157.127.36.232.544.28.194.052.55.056.745.01.196-.047.457-.183.618-.324.143-.125 19.52-20.334 19.803-20.654a12.953 12.953 0 002.793-5.203 13.01 13.01 0 00.404-2.5c.008-.145.02-.26.03-.254.009.006.016-.256.016-.581 0-.326-.008-.58-.016-.567-.01.015-.021-.074-.028-.21a12.832 12.832 0 00-2.007-6.13 12.848 12.848 0 00-8.897-5.77 13.526 13.526 0 00-3.317-.067 12.85 12.85 0 00-6.346 2.521c-.588.443-.814.651-2.155 1.988L24 8.648l-1.234-1.23c-1.343-1.338-1.587-1.564-2.174-2.006a12.83 12.83 0 00-8.18-2.587zm-.096 3.005c-.257.016-.607.053-.9.096-1.935.284-3.639 1.082-5.139 2.409a9.775 9.775 0 00-2.597 3.742 9.83 9.83 0 00.955 9.014c.366.553.698.957 1.327 1.613l9.223 9.62C20.026 37.37 23.992 41.5 24 41.5c.017 0 18.332-19.103 18.538-19.336a9.855 9.855 0 002.35-7.957c-.34-2.272-1.476-4.378-3.166-5.873-1.48-1.31-3.177-2.112-5.074-2.4a9.944 9.944 0 00-2.731-.04 9.921 9.921 0 00-4.627 1.86c-.515.384-.764.618-2.583 2.43-1.671 1.664-1.826 1.813-1.966 1.891a1.914 1.914 0 01-.307.133c-.135.042-.192.048-.434.048s-.299-.006-.434-.048a1.918 1.918 0 01-.307-.133c-.14-.078-.294-.226-1.966-1.891-1.82-1.812-2.068-2.046-2.583-2.43a9.926 9.926 0 00-5.212-1.918 14.565 14.565 0 00-1.181-.007zM.009 15.658c0 .327.002.464.005.303.004-.16.004-.428 0-.595-.003-.167-.005-.036-.005.292z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_263">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
