import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const HeartRateIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.209 3.62a12.9 12.9 0 0115.429 6.411l.003.007a12.657 12.657 0 01-2.399 14.703L25.078 44.543a1.5 1.5 0 01-2.156 0L3.758 24.747a12.658 12.658 0 01-2.4-14.703l.004-.006a12.9 12.9 0 0120.539-3.326L24 8.627l2.099-1.915a12.9 12.9 0 015.11-3.092zm5.51 2.507a9.9 9.9 0 00-8.576 2.781l-3.132 2.858a1.5 1.5 0 01-2.022 0l-3.132-2.858a9.9 9.9 0 00-15.821 2.491 9.658 9.658 0 001.866 11.25L24 41.343l18.098-18.7.025-.025a9.658 9.658 0 001.842-11.225 9.9 9.9 0 00-7.246-5.266z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.153 18.656l1.307 2.604a2.252 2.252 0 002.012 1.24H37.5a1.5 1.5 0 000-3h-6.567l-1.846-3.679a2.252 2.252 0 00-3.308-.83 2.25 2.25 0 00-.79.998l-.002.004-4.139 10.347-2.798-5.597-.002-.003a2.252 2.252 0 00-2.012-1.24H10.5a1.5 1.5 0 000 3h5.074l3.34 6.679a2.25 2.25 0 004.097-.168l.002-.004 4.14-10.35z"
        fill="currentColor"
      />
    </svg>
  )
}
