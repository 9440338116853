import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const HamburgerMenuIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M6.2 36.946a1.618 1.618 0 011.618-1.618h32.365a1.618 1.618 0 110 3.236H7.818A1.618 1.618 0 016.2 36.946zM14.2 24a1.618 1.618 0 011.618-1.618h24.365a1.618 1.618 0 110 3.236H15.818A1.618 1.618 0 0114.2 24zm-8-12.946a1.618 1.618 0 011.618-1.618h32.365a1.618 1.618 0 010 3.236H7.818A1.618 1.618 0 016.2 11.054z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}
