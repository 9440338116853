import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const FeedbackIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Line 1"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M15.351 30h17.298"
        />
        <path
          data-name="Line 9"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M15.351 38H25.5"
        />
        <g
          data-name="Rectangle 2"
          transform="translate(7)"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
        >
          <rect width={34} height={48} rx={6} stroke="none" />
          <rect x={1.5} y={1.5} width={31} height={45} rx={4.5} />
        </g>
        <path d="M20.93 22.58a.829.829 0 01-.547-.205c-.571-.5-1.122-.969-1.608-1.383a29.9 29.9 0 01-3.515-3.298 5.263 5.263 0 01-1.4-3.445 4.419 4.419 0 011.122-3.018A3.8 3.8 0 0117.81 10a3.557 3.557 0 012.222.767 4.545 4.545 0 01.9.938 4.546 4.546 0 01.9-.938A3.557 3.557 0 0124.05 10a3.8 3.8 0 012.828 1.231A4.419 4.419 0 0128 14.249a5.262 5.262 0 01-1.4 3.445 29.9 29.9 0 01-3.51 3.295c-.487.415-1.038.885-1.611 1.385a.83.83 0 01-.549.206z" />
      </g>
    </svg>
  )
}
