import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const PhoneIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <g
          data-name="Rectangle 2"
          transform="translate(10)"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
        >
          <rect width={29} height={48} rx={6} stroke="none" />
          <rect x={1.5} y={1.5} width={26} height={45} rx={4.5} />
        </g>
        <rect
          data-name="Rectangle 3"
          width={17}
          height={6}
          rx={3}
          transform="translate(16)"
        />
        <path
          data-name="Line 3"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M20.5 41h8"
        />
      </g>
    </svg>
  )
}
