import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MealTimeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_257)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M21.054 7.834a1.5 1.5 0 011.5 1.5l-.002 11.732a1.5 1.5 0 01-1.5 1.5h-9.774a1.5 1.5 0 110-3h8.274l.002-10.232a1.5 1.5 0 011.5-1.5z" />
        <path d="M28.61 4.672a18.056 18.056 0 10-10.423 34.223 1.5 1.5 0 11-.474 2.962A21.057 21.057 0 1142.1 21.066a1.5 1.5 0 11-3 0A18.055 18.055 0 0028.61 4.672z" />
        <path d="M21.048 24.734a1.5 1.5 0 011.5 1.5V33a4.5 4.5 0 109 0v-6.766a1.5 1.5 0 013 0V33a7.5 7.5 0 01-15 0v-6.766a1.5 1.5 0 011.5-1.5z" />
        <path d="M27.052 24.734a1.5 1.5 0 011.5 1.5v20.254a1.5 1.5 0 01-3 0V26.234a1.5 1.5 0 011.5-1.5zM39.696 24.883l-.652 1.351c.652-1.35.653-1.35.654-1.35l.002.001.004.002.011.005.03.015a5.926 5.926 0 01.386.215c.244.145.578.36.972.652.788.585 1.82 1.487 2.845 2.781C46.014 31.163 48 35.294 48 41.454a1.5 1.5 0 01-1.5 1.5h-5.956v3.534a1.5 1.5 0 11-3 0V26.234a1.5 1.5 0 012.152-1.35zm.848 15.071h4.41c-.284-4.607-1.854-7.638-3.358-9.536a13.01 13.01 0 00-1.052-1.176v10.712z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_257">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
