import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const HomeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g fillRule="evenodd" clipPath="url(#prefix__a)">
        <path
          data-name="Path 87"
          d="M8.015 38.377V21.06h2.664v17.317a1.332 1.332 0 001.336 1.332h23.974a1.332 1.332 0 001.332-1.332V21.06h2.664v17.317a4 4 0 01-4 4h-23.97a4 4 0 01-4-4zM37.321 9.071v9.325l-5.328-5.329v-4a1.332 1.332 0 011.332-1.328h2.664a1.332 1.332 0 011.332 1.332z"
        />
        <path
          data-name="Path 88"
          d="M22.118 6.407a2.664 2.664 0 013.767 0l17.708 17.707A1.334 1.334 0 0141.707 26L24.001 8.291 6.294 26a1.334 1.334 0 11-1.886-1.886z"
        />
      </g>
    </svg>
  )
}
