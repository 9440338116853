import { useTranslation } from "next-i18next"
import React, { FC } from "react"
import styled from "styled-components"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"
import { Button } from "@aistihealth/web-common/src/components/button/Button"
import { LoginPageBaseProps } from "@aistihealth/web-common/src/msal/EnsureAdminAuthentication"

import logoImage from "../../../public/aistii-logo-primary.svg"

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`

const LoginBox = styled.div`
  align-items: center;
  background: ${theme.colors.white};
  border-radius: 24px;
  box-shadow: 0 0 8px #c9c9c9;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  justify-content: center;
  padding: 60px;
  width: 600px;
`

const LoginButton = styled(Button)`
  margin-bottom: 0;
  padding: 20px;
`

const Logo = styled.img`
  width: 220px;
`

const Heading = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-size: 65px;
  font-weight: 500;
`

export const AdminLoginPage: FC<LoginPageBaseProps> = ({
  onLoginButtonClick,
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <LoginBox>
        <Logo src={logoImage.src} />
        <Heading>{t("admin.auth.loginPage.heading")}</Heading>
        <LoginButton
          type="button"
          variant="primary"
          onClick={onLoginButtonClick}
        >
          {t("admin.auth.loginPage.loginButton")}
        </LoginButton>
      </LoginBox>
    </Container>
  )
}
