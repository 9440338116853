import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const PdfAttachmentIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Path 89"
          d="M14.931 5.861a4.535 4.535 0 00-4.535 4.535v27.208a4.535 4.535 0 004.535 4.535H33.07a4.535 4.535 0 004.535-4.535V10.396a4.535 4.535 0 00-4.535-4.535zm0 2.267H33.07a2.267 2.267 0 012.267 2.267v27.209a2.267 2.267 0 01-2.267 2.267H14.931a2.267 2.267 0 01-2.267-2.267V10.396a2.267 2.267 0 012.267-2.268z"
        />
        <path
          data-name="Path 90"
          d="M16.298 33.27a1.837 1.837 0 01-.993-.952 2.432 2.432 0 01.181-2.5 6.036 6.036 0 012.034-1.784 17.413 17.413 0 013.36-1.464 44.669 44.669 0 002.412-5.051 16.482 16.482 0 01-.975-2.936 6.3 6.3 0 01-.1-2.576 2.449 2.449 0 011.474-1.866 2.365 2.365 0 011.357-.171 1.587 1.587 0 011.082.828 2.819 2.819 0 01.288 1.22 7.271 7.271 0 01-.107 1.392 22.327 22.327 0 01-1.179 4.06 24.837 24.837 0 002.222 3.823 13.044 13.044 0 013.025.113 3.617 3.617 0 012.177 1.054 1.919 1.919 0 01.453 1.174 2.638 2.638 0 01-.313 1.277 2.358 2.358 0 01-.8.943 1.941 1.941 0 01-1.156.313 3.842 3.842 0 01-2.115-.945 12.96 12.96 0 01-2.07-2.152 26.4 26.4 0 00-4.528.921 25.646 25.646 0 01-2.315 3.424 6.1 6.1 0 01-2.1 1.784 1.8 1.8 0 01-1.315.066zm3.127-4.31a11.376 11.376 0 00-1.041.54 4.433 4.433 0 00-1.467 1.24.742.742 0 00-.091.819.515.515 0 00.059.1.613.613 0 00.079-.027 4.611 4.611 0 001.44-1.3q.542-.663 1.02-1.374zm3.718-3.016q1.136-.265 2.29-.438-.62-.948-1.156-1.945-.533 1.206-1.134 2.381zm5.546 1.02a8.9 8.9 0 00.986.93 2.257 2.257 0 001.129.58.243.243 0 00.159-.034.7.7 0 00.213-.283.989.989 0 00.134-.453.215.215 0 00-.059-.143 2.074 2.074 0 00-1.174-.474 8.8 8.8 0 00-1.388-.12zm-4.512-7.953a15.191 15.191 0 00.453-1.877 5.34 5.34 0 00.086-1.054 1.39 1.39 0 00-.073-.449 1.172 1.172 0 00-.329.091c-.2.079-.358.24-.444.642a4.732 4.732 0 00.1 1.864c.054.252.122.515.2.785z"
        />
      </g>
    </svg>
  )
}
