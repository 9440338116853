import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const EmployeesIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M43.731 40.912a2.761 2.761 0 002.82-2.819c0-2.819-2.819-11.275-14.093-11.275s-14.095 8.457-14.095 11.275a2.761 2.761 0 002.819 2.819zm-22.487-2.819l-.062-.011a8.65 8.65 0 012.142-4.848c1.556-1.824 4.29-3.6 9.132-3.6s7.574 1.776 9.132 3.6a8.679 8.679 0 012.142 4.848l-.022.006-.039.006zm11.212-16.912a5.637 5.637 0 10-5.637-5.637 5.637 5.637 0 005.637 5.637zm8.456-5.637a8.456 8.456 0 11-8.456-8.456 8.456 8.456 0 018.456 8.456zM21.001 27.608a16.574 16.574 0 00-3.467-.7q-.992-.094-1.99-.093c-11.274.004-14.093 8.46-14.093 11.278a2.491 2.491 0 002.819 2.819h11.881a6.308 6.308 0 01-.609-2.819 12.935 12.935 0 013.072-8.185 14.915 14.915 0 012.385-2.3zm-5.682 2.029a15.483 15.483 0 00-2.593 8.456H4.27a8.649 8.649 0 012.142-4.859c1.536-1.793 4.205-3.54 8.907-3.594zm-9.64-12.684a8.456 8.456 0 118.456 8.456 8.456 8.456 0 01-8.456-8.456zm8.456-5.637a5.637 5.637 0 105.637 5.637 5.637 5.637 0 00-5.637-5.637z" />
      </g>
    </svg>
  )
}
