import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const StarIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g data-name="Polygon 1" fill="none" clipPath="url(#prefix__a)">
        <path d="M22.1 3.942a2 2 0 013.809 0l4.081 12.744a2 2 0 001.926 1.389l13.569-.144a2 2 0 011.161 3.643l-10.98 7.612a2 2 0 00-.751 2.3L39.235 44a2 2 0 01-3.065 2.271l-11-7.979a2 2 0 00-2.349 0l-11 7.979A2 2 0 018.765 44l4.321-12.519a2 2 0 00-.751-2.3l-10.98-7.606a2 2 0 011.161-3.644l13.569.144a2 2 0 001.926-1.389z" />
        <path
          d="M24 7.828l-3.133 9.773a4.984 4.984 0 01-4.814 3.474l-10.312-.11 8.303 5.757a5.017 5.017 0 011.877 5.74l-3.285 9.52 8.427-6.115A4.97 4.97 0 0124 34.913a4.97 4.97 0 012.937.954l8.427 6.115-3.285-9.52a5.018 5.018 0 011.877-5.74l8.303-5.756-10.31.109h-.055a4.984 4.984 0 01-4.761-3.474L24 7.828m0-5.276c.804 0 1.608.463 1.905 1.39l4.085 12.744a2 2 0 001.925 1.389l13.57-.144h.024c1.953 0 2.75 2.525 1.136 3.644l-10.98 7.612a2 2 0 00-.75 2.296l4.32 12.52c.487 1.411-.635 2.661-1.897 2.661-.39 0-.794-.12-1.168-.39l-10.995-7.98a1.997 1.997 0 00-2.35 0l-10.995 7.98c-.374.27-.778.39-1.168.39-1.262 0-2.384-1.25-1.897-2.662l4.32-12.519a2 2 0 00-.75-2.296l-10.98-7.612C-.258 20.456.538 17.93 2.49 17.93h.024l13.57.144a1.997 1.997 0 001.925-1.39l4.085-12.743c.297-.927 1.101-1.39 1.905-1.39z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
