import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const StarNineIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g data-name="9-star">
        <g data-name="Polygon 1" fill="none" clipPath="url(#prefix__a)">
          <path d="M22.242 3.241a2 2 0 013.516 0l2.995 5.521a2 2 0 002.5.9l6.1-2.433a2 2 0 012.682 2.341l-1.392 5.63a2 2 0 001.392 2.4l5.944 1.7a2 2 0 01.6 3.558l-4.835 3.409a2 2 0 00-.491 2.775l3.288 4.736a2 2 0 01-1.741 3.138l-6.43-.316a2 2 0 00-2.083 1.74l-.774 5.951a2 2 0 01-3.3 1.247l-4.894-4.285a2 2 0 00-2.635 0l-4.894 4.285a2 2 0 01-3.3-1.247l-.774-5.951a2 2 0 00-2.086-1.74l-6.43.316a2 2 0 01-1.743-3.138l3.288-4.736a2 2 0 00-.491-2.775l-4.837-3.409a2 2 0 01.6-3.558l5.944-1.7a2 2 0 001.392-2.4L7.965 9.57a2 2 0 012.682-2.338l6.1 2.433a2 2 0 002.5-.9z" />
          <path
            d="M24 6.292l-2.116 3.9a4.997 4.997 0 01-6.247 2.26l-4.293-1.712.925 3.74a4.976 4.976 0 01-.527 3.706 4.976 4.976 0 01-2.953 2.301l-3.935 1.124 3.127 2.204a4.969 4.969 0 012.042 3.216 4.969 4.969 0 01-.816 3.722L7.08 33.819l4.402-.22a5.012 5.012 0 015.207 4.349l.533 4.094 3.485-3.052a4.998 4.998 0 016.588 0l3.485 3.052.533-4.094a5.012 5.012 0 015.207-4.35l4.402.22-2.128-3.065a4.969 4.969 0 01-.816-3.722 4.97 4.97 0 012.042-3.216l3.127-2.204-3.935-1.124a4.976 4.976 0 01-2.953-2.301 4.976 4.976 0 01-.527-3.707l.925-3.739-4.293 1.712a4.997 4.997 0 01-6.247-2.26L24 6.292m0-4.097c.69 0 1.38.348 1.758 1.046l2.995 5.52a2 2 0 002.498.905l6.102-2.434c.25-.1.503-.146.747-.146 1.23 0 2.262 1.165 1.935 2.484l-1.392 5.63a2 2 0 001.393 2.402l5.944 1.699c1.616.461 1.976 2.589.603 3.557l-4.835 3.41a2 2 0 00-.49 2.774l3.287 4.736c.926 1.335-.04 3.141-1.636 3.141-.035 0-.07 0-.107-.003l-6.432-.32a2 2 0 00-2.083 1.74l-.774 5.95a1.999 1.999 0 01-3.301 1.247l-4.895-4.286a1.996 1.996 0 00-2.635 0l-4.894 4.286a1.998 1.998 0 01-3.3-1.247l-.775-5.951a2 2 0 00-2.083-1.74l-6.432.321a2.144 2.144 0 01-.107.003c-1.595 0-2.562-1.806-1.636-3.14l3.288-4.737a2 2 0 00-.49-2.775l-4.836-3.409c-1.373-.968-1.013-3.096.603-3.557l5.944-1.699A2 2 0 009.357 15.2L7.965 9.57C7.639 8.251 8.67 7.086 9.9 7.086c.244 0 .496.046.747.146l6.102 2.434a2 2 0 002.499-.904l2.994-5.521A1.98 1.98 0 0124 2.195z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}
