import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SadnessIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_287)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M22.1 3a17.23 17.23 0 00-11.73 29.87 1.5 1.5 0 01.48 1.1V46.5a1.5 1.5 0 01-3 0V34.61A20.23 20.23 0 0122.103 0c3.783 0 6.908.781 9.538 2.23 2.625 1.445 4.67 3.507 6.363 5.942 2.762 3.972 4.698 9.127 6.703 14.464.41 1.09.822 2.188 1.244 3.285l.001.002A3.002 3.002 0 0143.153 30h-2.302v4.5a7.5 7.5 0 01-7.5 7.5h-1.5v4.5a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5h3a4.5 4.5 0 004.5-4.5v-6a1.5 1.5 0 011.5-1.5h3.797l.003 1.5V27h-.001l1.237.848L43.15 27l1.4-.54-1.4.537c-.45-1.166-.88-2.31-1.3-3.426-2.008-5.333-3.784-10.051-6.311-13.686-1.498-2.155-3.22-3.857-5.347-5.028C28.07 3.688 25.456 3 22.1 3h-.002zm21.05 24v-.003V27z" />
        <path d="M9.33 20.019c0-7.07 5.73-12.802 12.8-12.802 7.071 0 12.803 5.732 12.803 12.802 0 7.07-5.732 12.802-12.802 12.802-7.07 0-12.802-5.732-12.802-12.802zm12.8-9.802c-5.412 0-9.8 4.389-9.8 9.802 0 5.413 4.388 9.802 9.8 9.802 5.414 0 9.803-4.389 9.803-9.802 0-5.413-4.389-9.802-9.802-9.802z" />
        <path d="M19.613 17.08a1.5 1.5 0 00-1.298-2.252 2.252 2.252 0 000 4.505 1.5 1.5 0 001.298-2.253z" />
        <path d="M16.815 16.328a1.5 1.5 0 011.5-1.5 2.252 2.252 0 010 4.505 1.5 1.5 0 01-1.298-2.253 1.492 1.492 0 01-.202-.752zM27.244 17.08a1.5 1.5 0 00-1.298-2.252 2.252 2.252 0 000 4.505 1.5 1.5 0 001.298-2.253z" />
        <path d="M24.447 16.328a1.5 1.5 0 011.5-1.5 2.252 2.252 0 010 4.505 1.5 1.5 0 01-1.298-2.253 1.493 1.493 0 01-.202-.752zM22.154 24.555c-1.088-.006-1.995.318-2.467.76a1.5 1.5 0 01-2.05-2.191c1.216-1.138 2.975-1.577 4.532-1.57 1.553.008 3.29.463 4.465 1.578a1.5 1.5 0 01-2.067 2.175c-.433-.412-1.32-.747-2.413-.752z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_287">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
