import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SlowChangeIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.424 1.603c-.505.114-.944.51-1.11 1.003-.046.137-.05.175-.05.466 0 .29.004.33.05.466.115.344-.144.07 4.103 4.32l3.89 3.892h-6.826c-4.349 0-6.94.008-7.142.02a8.996 8.996 0 00-2.438.472 9.213 9.213 0 00-2.554 1.33 10.003 10.003 0 00-1.913 1.894 9.322 9.322 0 00-1.753 4.147c-.113.714-.105.029-.11 9.485a1888.38 1888.38 0 01-.014 8.88c-.048 1.039-.483 2.212-1.146 3.09a5.803 5.803 0 01-2.1 1.747c-.611.3-1.19.474-1.901.572-.21.029-.803.032-6.442.042l-6.211.011-.154.053a1.583 1.583 0 00-.965.964c-.047.143-.051.178-.051.471 0 .291.004.329.05.466.17.502.612.897 1.122 1.003.208.043 12.194.043 12.68 0a8.732 8.732 0 003.196-.885 8.758 8.758 0 001.735-1.118c.266-.215.926-.874 1.157-1.156a8.878 8.878 0 001.966-4.416c.088-.584.086-.357.086-9.504.001-9.282-.004-8.834.096-9.388a6.383 6.383 0 011.303-2.842c.18-.224.647-.695.867-.876a6.319 6.319 0 013.367-1.418c.246-.026 1.119-.03 7.187-.03h6.908l-3.898 3.903c-4.23 4.234-3.982 3.973-4.093 4.306-.048.141-.052.178-.052.47 0 .298.002.326.053.47.162.467.533.823 1.011.973.122.038.185.045.413.045.39 0 .629-.077.912-.295.199-.152 10.464-10.437 10.549-10.568.158-.245.229-.5.227-.82a1.382 1.382 0 00-.228-.801c-.078-.121-10.338-10.406-10.53-10.556a1.623 1.623 0 00-.489-.26 2.01 2.01 0 00-.758-.028z"
        fill="currentColor"
      />
    </svg>
  )
}
