import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const WorkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M20.063 6.938a3.938 3.938 0 00-3.937 3.938v1.312H6.938A3.938 3.938 0 003 16.126v21a3.938 3.938 0 003.937 3.938h34.126A3.937 3.937 0 0045 37.126v-21a3.937 3.937 0 00-3.937-3.938h-9.188v-1.312a3.937 3.937 0 00-3.937-3.938zm0 2.625h7.875a1.313 1.313 0 011.312 1.313v1.312h-10.5v-1.312a1.313 1.313 0 011.313-1.313zm4.951 18.149l17.362-4.628v14.042a1.313 1.313 0 01-1.313 1.312H6.938a1.313 1.313 0 01-1.312-1.312V23.082l17.362 4.631a3.938 3.938 0 002.026 0zM6.938 14.813h34.125a1.313 1.313 0 011.313 1.313v4.242l-18.037 4.809a1.313 1.313 0 01-.677 0L5.625 20.368v-4.242a1.312 1.312 0 011.313-1.313z" />
      </g>
    </svg>
  )
}
