import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ResearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Line 1"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M15.351 9.765h17.298"
        />
        <path
          data-name="Line 9"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M15.351 17.765h17.298"
        />
        <g
          data-name="Ellipse 7"
          transform="translate(20.582 24.488)"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
        >
          <circle cx={9.5} cy={9.5} stroke="none" r={9.5} />
          <circle cx={9.5} cy={9.5} r={8} />
        </g>
        <path
          data-name="Line 10"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M36.5 40.5l5.732 5.5"
        />
        <g data-name="Subtraction 7">
          <g data-name="Group 53">
            <path
              data-name="Path 70"
              d="M41 23.263V6.001a6.007 6.007 0 00-6-6H13a6.007 6.007 0 00-6 6v36a6.006 6.006 0 006 6h9.3l-4.035-3h-5.268a3 3 0 01-3-3v-36a3 3 0 013-3h22a3 3 0 013 3v15.032z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
