export type Partial<T> = { [P in keyof T]?: T[P] }

export interface Dictionary<T> {
  [K: string]: T
}

export type Nullable<T> = { [K in keyof T]: T[K] | null }

export type RequiredAndNonNullable<T> = Required<
  { [K in keyof T]: Exclude<T[K], undefined | null> }
>

// https://stackoverflow.com/a/61132308
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

// https://stackoverflow.com/a/52490977
export type HomogenousTuple<T, N extends number> = N extends N
  ? number extends N
    ? T[]
    : _HomogenousTupleOf<T, N, []>
  : never
type _HomogenousTupleOf<
  T,
  N extends number,
  R extends unknown[]
> = R["length"] extends N ? R : _HomogenousTupleOf<T, N, [T, ...R]>

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const temp: any = ""
const temp2 = typeof temp
export type PrimitiveType = typeof temp2

export const isHomogenousPrimitiveArray = (
  arr: unknown,
  expectedType: PrimitiveType,
): boolean => {
  if (!Array.isArray(arr)) {
    return false
  }

  return arr.every(value => typeof value === expectedType)
}

export function assert<T>(value: T | null | undefined, error: string): T {
  if (value) {
    return value
  }
  throw new Error(error)
}

export function assertNotNullOrUndefined<T>(
  value: T | null | undefined,
  error: string,
): T {
  if (value !== null && value !== undefined) {
    return value
  }
  throw new Error(error)
}

/**
 * Make sure this function is never called
 *
 * Useful in ensuring that all cases are handled when working with unions.
 */
export function assertNever(never: never): never {
  return never
}
