import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SettingsIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Path 82"
          d="M24 15.532A8.468 8.468 0 1032.468 24 8.468 8.468 0 0024 15.532zM18.141 24A5.859 5.859 0 1124 29.859 5.859 5.859 0 0118.141 24z"
        />
        <path
          data-name="Path 83"
          d="M28.715 6.525a4.916 4.916 0 00-9.43 0l-.247.837a2.292 2.292 0 01-3.295 1.365l-.767-.42a4.917 4.917 0 00-6.666 6.671l.417.767a2.292 2.292 0 01-1.365 3.295l-.837.247a4.916 4.916 0 000 9.43l.837.247a2.292 2.292 0 011.365 3.295l-.42.767a4.916 4.916 0 006.671 6.668l.767-.417a2.292 2.292 0 013.295 1.365l.247.837a4.916 4.916 0 009.43 0l.247-.837a2.292 2.292 0 013.295-1.365l.767.42a4.916 4.916 0 006.668-6.671l-.417-.767a2.292 2.292 0 011.365-3.295l.837-.247a4.916 4.916 0 000-9.43l-.837-.247a2.292 2.292 0 01-1.365-3.295l.42-.767a4.917 4.917 0 00-6.674-6.668l-.767.417a2.292 2.292 0 01-3.295-1.365l-.247-.837zm-6.915.743a2.29 2.29 0 014.395 0l.247.837a4.917 4.917 0 007.07 2.927l.764-.42a2.293 2.293 0 013.108 3.111l-.417.767a4.917 4.917 0 002.93 7.067l.835.247a2.29 2.29 0 010 4.395l-.837.247a4.917 4.917 0 00-2.927 7.07l.42.764a2.293 2.293 0 01-3.111 3.108l-.764-.417a4.917 4.917 0 00-7.07 2.93l-.247.835a2.29 2.29 0 01-4.395 0l-.247-.837a4.917 4.917 0 00-7.067-2.927l-.767.42a2.293 2.293 0 01-3.108-3.111l.417-.764a4.917 4.917 0 00-2.927-7.072l-.834-.245a2.29 2.29 0 010-4.395l.837-.247a4.917 4.917 0 002.927-7.068l-.42-.767a2.293 2.293 0 013.111-3.108l.767.417a4.917 4.917 0 007.067-2.927l.247-.837z"
        />
      </g>
    </svg>
  )
}
