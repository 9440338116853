import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const FamilyHoldIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_267)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M1.518 27a1.5 1.5 0 011.5 1.5v18a1.5 1.5 0 01-3 0v-18a1.5 1.5 0 011.5-1.5z" />
        <path d="M.018 31.5a1.5 1.5 0 011.5-1.5h10.5a7.5 7.5 0 017.349 6h6.151a7.5 7.5 0 017.5 7.5 1.5 1.5 0 01-1.5 1.5h-30a1.5 1.5 0 010-3h28.243a4.498 4.498 0 00-4.243-3h-7.5a1.5 1.5 0 01-1.5-1.5 4.5 4.5 0 00-4.5-4.5h-10.5a1.5 1.5 0 01-1.5-1.5z" />
        <path d="M10.518 37.5a1.5 1.5 0 011.5-1.5h6a1.5 1.5 0 010 3h-6a1.5 1.5 0 01-1.5-1.5zM33.715 2.197A7.5 7.5 0 1144.32 12.803 7.5 7.5 0 0133.715 2.197zM39.018 3a4.5 4.5 0 100 9 4.5 4.5 0 000-9z" />
        <path d="M38.21 15.036A9 9 0 0148.018 24a1.5 1.5 0 01-3 0 6 6 0 00-9.6-4.8 1.5 1.5 0 01-1.8-2.4 9 9 0 014.592-1.764zM6.715 2.197A7.5 7.5 0 1117.32 12.803 7.5 7.5 0 016.715 2.197zM12.018 3a4.5 4.5 0 100 9 4.5 4.5 0 000-9z" />
        <path d="M12.557 18.026a6.002 6.002 0 00-6.539 5.975 1.5 1.5 0 01-3-.002 9.002 9.002 0 0114.4-7.2 1.5 1.5 0 11-1.8 2.401 6.002 6.002 0 00-3.06-1.174zM21.275 10.757a6 6 0 118.486 8.486 6 6 0 01-8.486-8.486zM25.518 12a3 3 0 100 6 3 3 0 000-6z" />
        <path d="M20.588 22.47a8.998 8.998 0 014.93-1.47 1.5 1.5 0 110 3 5.999 5.999 0 00-5.5 3.6 1.5 1.5 0 11-2.749-1.2 8.997 8.997 0 013.32-3.93z" />
        <path d="M24.018 22.5a1.5 1.5 0 011.5-1.5 9 9 0 019 9 1.5 1.5 0 01-3 0 6 6 0 00-6-6 1.5 1.5 0 01-1.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_267">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
