import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MentalHealthIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        d="M22 4c8.137 0 14.852 6.07 15.867 13.93l4.5 7.078c.297.465.238 1.16-.449 1.457L38 28.14V34c0 2.21-1.79 4-4 4h-3.996L30 44H12v-7.387c0-2.36-.871-4.593-2.488-6.61A15.93 15.93 0 016 20c0-8.836 7.164-16 16-16zm0 4c-6.629 0-12 5.371-12 12 0 2.77.938 5.387 2.633 7.5C14.82 30.227 16 33.336 16 36.613V40h10l.004-6H34v-8.496l3.102-1.328-3.086-4.852-.118-.883C33.133 12.504 28.047 8 22 8zm-1.059 7.527L22 16.586l1.059-1.059a3.496 3.496 0 014.953 0 3.494 3.494 0 010 4.946L22 26.484l-6.012-6.011a3.494 3.494 0 010-4.946 3.496 3.496 0 014.953 0zm0 0"
        fill="currentColor"
      />
    </svg>
  )
}
