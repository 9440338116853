import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SelfCareIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_256)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M22.1 3a17.23 17.23 0 00-11.73 29.87 1.5 1.5 0 01.48 1.1V46.5a1.5 1.5 0 01-3 0V34.61A20.23 20.23 0 0122.103 0c3.783 0 6.908.781 9.538 2.23 2.625 1.445 4.67 3.507 6.363 5.942 2.762 3.972 4.698 9.127 6.703 14.464.41 1.09.822 2.188 1.244 3.285l.001.002A3.002 3.002 0 0143.153 30h-2.302v4.5a7.5 7.5 0 01-7.5 7.5h-1.5v4.5a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5h3a4.5 4.5 0 004.5-4.5v-6a1.5 1.5 0 011.5-1.5h3.797l.003 1.5V27h-.001l1.237.848L43.15 27l1.4-.54-1.4.537c-.45-1.166-.88-2.31-1.3-3.426-2.008-5.333-3.784-10.051-6.311-13.686-1.498-2.155-3.22-3.857-5.347-5.028C28.07 3.688 25.456 3 22.1 3h-.002zm21.05 24v-.003V27z" />
        <path d="M13.66 21.677l8.348 7.561 8.348-7.561c1.797-1.825 1.447-4.572-.195-6.209-.788-.786-1.807-1.225-2.92-1.146-1.114.08-2.55.694-4.108 2.462a1.5 1.5 0 01-2.25 0c-1.559-1.767-2.997-2.385-4.115-2.466-1.117-.082-2.139.356-2.928 1.14-1.64 1.628-1.997 4.374-.18 6.219zm8.348-8.016c-1.617-1.45-3.328-2.213-5.023-2.336-2.094-.151-3.938.692-5.259 2.004-2.573 2.555-3.418 7.238-.163 10.494.018.017.035.034.054.05L21 32.375a1.5 1.5 0 002.014 0l9.385-8.5a1.52 1.52 0 00.053-.051c3.242-3.242 2.39-7.924-.174-10.48-1.317-1.313-3.158-2.162-5.25-2.013-1.694.12-3.405.882-5.02 2.33z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_256">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
