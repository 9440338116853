import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SortUpIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M24 40a1.143 1.143 0 001.143-1.143V11.9l7.19 7.193a1.144 1.144 0 101.618-1.618l-9.142-9.139a1.143 1.143 0 00-1.618 0l-9.142 9.142a1.146 1.146 0 001.618 1.622l7.19-7.2v26.957A1.143 1.143 0 0024 40z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}
