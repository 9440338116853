import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const RemoteWorkIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_250)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M22.926 20.327c-1.003-.287-2.018-.15-2.917.299a1.5 1.5 0 11-1.342-2.683c1.48-.74 3.236-1.007 5.007-.522 4.12.767 7.164 4.605 7.164 8.863v5.8c0 3.029-2.472 5.5-5.5 5.5h-3.073l-3.785 7.571-1.342-.67 1.341.67c-1.403 2.807-4.812 3.67-7.355 1.99-2.321-1.465-2.826-4.336-1.728-6.531l2.6-5.2a1.5 1.5 0 112.684 1.341l-2.6 5.2c-.5.998-.21 2.118.653 2.657l.037.024c1.055.704 2.433.364 3.026-.822l4.2-8.4a1.5 1.5 0 011.342-.83h4c1.371 0 2.5-1.128 2.5-2.5v-5.8c0-2.915-2.12-5.456-4.76-5.922a1.515 1.515 0 01-.152-.035z" />
        <path d="M7.009 34.743a1.5 1.5 0 01.67 2.012l-2.6 5.2c-.499.998-.208 2.119.654 2.657l.037.024c1.055.704 2.433.364 3.026-.822.023-.046.048-.09.075-.133l.346-.546a1.5 1.5 0 012.534 1.605l-.309.488c-1.425 2.748-4.797 3.582-7.318 1.917-2.321-1.465-2.826-4.335-1.728-6.531l2.6-5.2a1.5 1.5 0 012.013-.671zM14.838 10.676a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zm6.5-3.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM25 46.5a1.5 1.5 0 011.5-1.5h16a1.5 1.5 0 010 3h-16a1.5 1.5 0 01-1.5-1.5zM31 18.5a1.5 1.5 0 011.5-1.5h4c4.228 0 7.5 3.272 7.5 7.5v6a1.5 1.5 0 01-3 0v-6c0-2.572-1.928-4.5-4.5-4.5h-4a1.5 1.5 0 01-1.5-1.5z" />
        <path d="M40.5 32a2.512 2.512 0 00-2.5 2.5V45h7V34.5c0-1.372-1.128-2.5-2.5-2.5h-2zM35 34.5c0-3.028 2.472-5.5 5.5-5.5h2c3.028 0 5.5 2.472 5.5 5.5v12a1.5 1.5 0 01-1.5 1.5h-10a1.5 1.5 0 01-1.5-1.5v-12zM3.012 21.384l1.944 7h9.43l-1.867-7H3.012zm-2.413-1.89c.56-.728 1.419-1.11 2.34-1.11h9.6c1.21 0 2.407.781 2.822 2.026.01.029.019.058.027.088l2.4 9a1.5 1.5 0 01-1.45 1.886h-11.4c-1.211 0-2.408-.781-2.823-2.026a1.528 1.528 0 01-.022-.072l-2-7.2a1.517 1.517 0 01-.026-.107c-.172-.86-.02-1.767.532-2.484z" />
        <path d="M14.838 29.884a1.5 1.5 0 011.5-1.5h6a1.5 1.5 0 110 3h-6a1.5 1.5 0 01-1.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_250">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
