import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const CircleMinusIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.063 24a1.5 1.5 0 011.5-1.5h16.874a1.5 1.5 0 010 3H15.564a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.354 3.126a22.594 22.594 0 1117.292 41.748A22.594 22.594 0 0115.354 3.126zM24 4.406a19.594 19.594 0 100 39.187 19.594 19.594 0 000-39.187z"
        fill="currentColor"
      />
    </svg>
  )
}
