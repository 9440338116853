export type AdminLanguage = "fi" | "en" | "sv"
export const defaultAdminLanguage: AdminLanguage = "fi"
export const supportedAdminLanguages: AdminLanguage[] = ["fi", "en", "sv"]

export const isAdminLanguage = (
  maybeLang: string,
): maybeLang is AdminLanguage =>
  (supportedAdminLanguages as string[]).includes(maybeLang)

export const resolveAdminLanguageWDefault = (
  maybeLang: string,
): AdminLanguage => {
  if (isAdminLanguage(maybeLang)) {
    return maybeLang
  }

  return defaultAdminLanguage
}
