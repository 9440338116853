import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SleepRecoveryIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Path 77"
          d="M6.44 37v-2.088l9.312-12.456.6.768h-9.72v-2.232H18.32v2.016l-9 12.336-.648-.576h9.648V37z"
        />
        <path
          data-name="Path 78"
          d="M20.2 29v-1.74l7.76-10.38.5.64h-8.1v-1.86h9.74v1.68l-7.5 10.28-.54-.48h8.04V29z"
        />
        <path
          data-name="Path 79"
          d="M32.96 22v-1.392l6.208-8.308.4.512h-6.48v-1.488h7.792v1.344l-6 8.232-.432-.384h6.432V22z"
        />
      </g>
    </svg>
  )
}
