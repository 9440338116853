import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MeditateIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.715 2.197A7.5 7.5 0 1129.32 12.803 7.5 7.5 0 0118.715 2.197zM24.018 3a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM30.406 21.41c-1.296-.974-3.043-1.956-6.36-1.91h-.04c-3.317-.045-5.063.937-6.36 1.912-.314.235-.602.471-.902.717l-.098.081c-.324.265-.677.552-1.042.805-.73.507-1.622.963-2.762.984a5.474 5.474 0 01-3.83-1.09 1.5 1.5 0 01-.305-.312 1.5 1.5 0 00-2.49 1.67 7.48 7.48 0 006.411 2.736c.048-.004.096-.006.144-.005a7.478 7.478 0 004.373-1.333 1.5 1.5 0 012.355 1.233v9.016a1.5 1.5 0 01-2.146 1.353l-1.911-.912h-.002a1.504 1.504 0 00-1.289 2.718m0 0l12.2 5.782a1.5 1.5 0 001.286-2.711l-4.88-2.313a1.5 1.5 0 111.284-2.71l4.88 2.311a4.5 4.5 0 01-3.855 8.133l-12.2-5.782a4.506 4.506 0 01.42-8.312 4.505 4.505 0 013.213.073v-4.19a10.477 10.477 0 01-3.7.645 10.48 10.48 0 01-8.973-3.918 1.499 1.499 0 01-.075-.102 4.5 4.5 0 017.216-5.36 2.474 2.474 0 001.626.39 1.52 1.52 0 01.164-.009c.328 0 .646-.11 1.136-.45.254-.176.52-.39.85-.66l.1-.082c.295-.242.632-.519 1-.795 1.728-1.298 4.102-2.564 8.182-2.513 4.08-.052 6.455 1.214 8.183 2.513.367.276.705.553 1 .795l.099.081c.33.27.597.485.85.662.49.34.808.449 1.136.449.054 0 .109.003.163.009a2.482 2.482 0 001.634-.394 4.5 4.5 0 017.217 5.35c-.026.04-.053.079-.083.115A10.477 10.477 0 0135.243 30a10.602 10.602 0 01-3.735-.65v3.73a4.5 4.5 0 012.242 8.697 1.5 1.5 0 01-.937-2.85 1.5 1.5 0 00-.917-2.856l-1.373.502a1.5 1.5 0 01-2.015-1.409V26.9a1.5 1.5 0 012.35-1.236A7.602 7.602 0 0035.272 27c.049 0 .097.001.145.005a7.475 7.475 0 006.411-2.731 1.5 1.5 0 00-2.491-1.664 1.501 1.501 0 01-.297.299 5.482 5.482 0 01-3.83 1.09c-1.14-.021-2.032-.477-2.763-.984-.365-.254-.717-.54-1.04-.806-.034-.026-.066-.053-.099-.08-.3-.246-.588-.483-.902-.718"
        fill="currentColor"
      />
    </svg>
  )
}
