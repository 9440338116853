// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || "development"
const enable = process.env.NEXT_PUBLIC_AISTI_ENABLE_SENTRY === "true"

if (enable) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_CLIENT_DSN,
    environment,
    // The current self-hosted Sentry version (9.0.0) doesn't support this
    autoSessionTracking: false,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
