import { ApolloProvider } from "@apollo/client"
import { sendDataLayerEvent } from "@aistihealth/web-common/src/gtm/gtm-utils"
import * as luxon from "luxon"
import React, { FC, useEffect, useMemo, useRef } from "react"
import { AppProps } from "next/app"
import {
  createGlobalStyle,
  StyleSheetManager,
  ThemeProvider,
} from "styled-components"
import { normalize } from "polished"
import { setLocale } from "yup"
import { appWithTranslation, useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import * as msalBrowser from "@azure/msal-browser"
import { EnsureAdminAuthentication } from "@aistihealth/web-common/src/msal/EnsureAdminAuthentication"
import {
  AdminLanguage,
  resolveAdminLanguageWDefault,
} from "@aistihealth/web-common/src/typings/adminLanguage"
import { AdminAuthProvider } from "@aistihealth/web-common/src/msal/clientMsal"
import {
  styledTheme,
  theme,
} from "@aistihealth/web-common/src/util/styles/theme"
import { TranslatedValidationError } from "@aistihealth/web-common/src/typings/yup"
import { AdminAuthContextValue } from "@aistihealth/web-common/src/msal/AdminAuthContext"
import { shouldForwardProp } from "@aistihealth/web-common/src/util/styles/utils"
import { AdminLoginPage } from "../components/admin-login-page/AdminLoginPage"
import { NotificationProvider } from "../hooks/useNotifications"
import { AdminAuthzProvider } from "../authz/AdminAuthzContext"
import { initApollo } from "../api/gql/client"
import nextI18NextConfig from "../../next-i18next.config.js"

// Needed for IE11
import "core-js/features/string/match-all"
import { msalSilenCallbackPath } from "./new-admin/entra-id-callbacks/msal-silent.page"

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  html {
    font-family: ${theme.typography.default.family};
    font-weight: ${theme.typography.default.weights.normal};
    font-size: 100%;
  }

  * {
    box-sizing: border-box;
  }
`

// Localization of yup error messages
setLocale({
  mixed: {
    required: "forms.validation.fieldRequired",
  },
  string: {
    email: "forms.validation.emailInvalid",
    max: ({ max }): TranslatedValidationError => ({
      interpolationVariables: { max },
      key: "forms.validation.fieldTooLong",
    }),
    min: ({ min }) => ({
      interpolationVariables: { min },
      key: "forms.validation.fieldTooShort",
    }),
  },
})

const azureAdAuthority = process.env.NEXT_PUBLIC_ADMIN_AZURE_AD_AUTHORITY
const azureAdClientId = process.env.NEXT_PUBLIC_ADMIN_AZURE_AD_CLIENT_ID
const azureAdRedirectFlowRedirectUri =
  process.env.NEXT_PUBLIC_AZURE_AD_REDIRECT_FLOW_REDIRECT_URI
const azureAdSilentFlowRedirectUri =
  process.env.NEXT_PUBLIC_AZURE_AD_SILENT_FLOW_REDIRECT_URI

const msalConfig: msalBrowser.Configuration = {
  auth: {
    authority: azureAdAuthority,
    clientId: azureAdClientId,
    redirectUri: azureAdSilentFlowRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
  },
}

const languageToLuxonLocale = {
  en: "en-GB",
  fi: "fi",
  sv: "sv",
}

interface AdminPageWrapperProps {
  isAdminPage: boolean
  language: AdminLanguage
}

const AdminPageWrapper: FC<AdminPageWrapperProps> = ({
  children,
  isAdminPage,
  language,
}) => {
  const adminAuthContextRef = useRef<AdminAuthContextValue>()

  const apollo = useMemo(() => {
    if (!isAdminPage) {
      return undefined
    }

    return initApollo({
      apiType: "admin",
      adminAuthContextRef,
    })
  }, [isAdminPage])

  if (!isAdminPage) {
    return <>{children}</>
  }

  return (
    <EnsureAdminAuthentication
      azureAdAuthority={azureAdAuthority}
      azureAdClientId={azureAdClientId}
      azureAdRedirectFlowRedirectUri={azureAdRedirectFlowRedirectUri}
      language={language}
      loginPageComponent={AdminLoginPage}
      adminAuthContextRef={adminAuthContextRef}
    >
      <ApolloProvider client={apollo}>
        <AdminAuthzProvider>{children}</AdminAuthzProvider>
      </ApolloProvider>
    </EnsureAdminAuthentication>
  )
}

const App: React.FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const { i18n } = useTranslation()

  luxon.Settings.defaultLocale = languageToLuxonLocale[i18n.language]

  // Match /new-admin, /new-admin/, /new-admin/foo, but not /new-admin-foo
  const isAdminPage = /^\/new-admin(\/.+|\/)?$/.test(router.pathname)

  useEffect(() => {
    sendDataLayerEvent({
      event: "pageView",
      language: router.locale,
      page: router.asPath,
    })
  }, [router.locale, router.asPath])

  // MSAL must not be initialized on the MSAL silent flow callback page
  if (router.pathname === msalSilenCallbackPath) {
    return null
  }

  return (
    <>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <GlobalStyle />
        <ThemeProvider theme={styledTheme}>
          <AdminAuthProvider msalConfig={msalConfig}>
            <NotificationProvider>
              <AdminPageWrapper
                isAdminPage={isAdminPage}
                language={resolveAdminLanguageWDefault(i18n.language)}
              >
                <Component {...pageProps} />
              </AdminPageWrapper>
            </NotificationProvider>
          </AdminAuthProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </>
  )
}

export default appWithTranslation(App, nextI18NextConfig)
