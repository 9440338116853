import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const HourGlassIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <g data-name="Polygon 2">
          <path d="M33 46.5H14c-.908 0-1.72-.47-2.17-1.26a2.473 2.473 0 01.016-2.51l9.5-16.12a2.477 2.477 0 012.154-1.231c.895 0 1.7.46 2.154 1.23l9.5 16.122c.461.782.467 1.72.017 2.51A2.473 2.473 0 0133 46.5z" />
          <path
            d="M23.5 26.879a.97.97 0 00-.862.492l-9.5 16.121a.968.968 0 00-.006 1.004A.968.968 0 0014 45h19c.505 0 .76-.316.868-.504a.968.968 0 00-.006-1.004l-9.5-16.12a.97.97 0 00-.862-.493m0-3c1.336 0 2.673.656 3.446 1.969l9.5 16.121C38.017 44.636 36.096 48 33 48H14c-3.095 0-5.017-3.364-3.446-6.03l9.5-16.122a3.964 3.964 0 013.446-1.97z"
            fill="currentColor"
          />
        </g>
        <g data-name="Polygon 3" fill="none">
          <path d="M27.031 24.366a4 4 0 01-7.062 0l-9.84-18.487A4 4 0 0113.66 0h19.68a4 4 0 013.531 5.879z" />
          <path
            d="M23.5 23.487a.964.964 0 00.883-.53l9.84-18.487a.97.97 0 00-.026-.985A.97.97 0 0033.34 3H13.66a.97.97 0 00-.857.485.97.97 0 00-.025.985l9.84 18.486c.245.462.658.53.882.53m0 3a3.958 3.958 0 01-3.531-2.12l-9.84-18.487C8.711 3.215 10.642 0 13.66 0h19.68c3.018 0 4.949 3.215 3.53 5.88l-9.839 18.486a3.958 3.958 0 01-3.531 2.12z"
            fill="currentColor"
          />
        </g>
        <path
          data-name="Rectangle 5"
          d="M14 0h19a4 4 0 014 4v1H10V4a4 4 0 014-4z"
        />
      </g>
    </svg>
  )
}
