import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const AlcoholIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.879.879A3 3 0 0118 0h3a3 3 0 013 3v9.879l4.683 4.682a1.5 1.5 0 01-2.122 2.122L21.44 14.56A1.5 1.5 0 0121 13.5V3h-3v10.5a1.5 1.5 0 01-.44 1.06l-5.121 5.122a1.5 1.5 0 00-.439 1.06V43.5a1.5 1.5 0 001.5 1.5H21a1.5 1.5 0 010 3h-7.5A4.5 4.5 0 019 43.5V20.742a4.5 4.5 0 011.317-3.18L15 12.879V3a3 3 0 01.879-2.121z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9a1.5 1.5 0 011.5-1.5h6a1.5 1.5 0 010 3h-6A1.5 1.5 0 0115 9zM24 24a1.5 1.5 0 011.5-1.5h12A1.5 1.5 0 0139 24v7.364a7.5 7.5 0 01-15 0V24zm3 1.5v5.864a4.5 4.5 0 109 0V25.5h-9z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5 36a1.5 1.5 0 011.5 1.5v9a1.5 1.5 0 01-3 0v-9a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 46.5A1.5 1.5 0 0127 45h9a1.5 1.5 0 010 3h-9a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}
