import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SocialRelationshipsIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Path 73"
          d="M10.03 33.003a2.625 2.625 0 01.753 2.1 28.8 28.8 0 01-1.045 5.25 31 31 0 006.914-2.344 2.625 2.625 0 011.864-.194 21.157 21.157 0 005.484.713c10.489 0 18.375-7.368 18.375-15.75S34.49 7.028 24 7.028 5.625 14.403 5.625 22.778a14.427 14.427 0 004.405 10.225zM8.736 43.25q-.933.185-1.872.339a.682.682 0 01-.717-.95q.35-.825.64-1.672l.008-.026a27.419 27.419 0 001.376-6.088A17.051 17.051 0 013 22.778C3 12.63 12.4 4.403 24 4.403s21 8.227 21 18.375-9.4 18.375-21 18.375a23.782 23.782 0 01-6.161-.8 35.881 35.881 0 01-9.103 2.897z"
        />
        <path
          data-name="Path 74"
          d="M13.5 16.216a1.313 1.313 0 011.313-1.313h18.375a1.313 1.313 0 010 2.625H14.813a1.313 1.313 0 01-1.313-1.312zm0 6.563a1.313 1.313 0 011.313-1.312h18.375a1.313 1.313 0 010 2.625H14.813a1.313 1.313 0 01-1.313-1.314zm0 6.563a1.313 1.313 0 011.313-1.312h10.5a1.313 1.313 0 010 2.625h-10.5a1.313 1.313 0 01-1.313-1.314z"
        />
      </g>
    </svg>
  )
}
