import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const StressIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_258)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M22.1 3a17.23 17.23 0 00-11.73 29.87 1.5 1.5 0 01.48 1.1V46.5a1.5 1.5 0 01-3 0V34.61A20.23 20.23 0 0122.103 0c3.783 0 6.908.781 9.538 2.23 2.625 1.445 4.67 3.507 6.363 5.942 2.762 3.972 4.698 9.127 6.703 14.464.41 1.09.822 2.188 1.244 3.285l.001.002A3.002 3.002 0 0143.153 30h-2.302v4.5a7.5 7.5 0 01-7.5 7.5h-1.5v4.5a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5h3a4.5 4.5 0 004.5-4.5v-6a1.5 1.5 0 011.5-1.5h3.797l.003 1.5V27h-.001l1.237.848L43.15 27l1.4-.54-1.4.537c-.45-1.166-.88-2.31-1.3-3.426-2.008-5.333-3.784-10.051-6.311-13.686-1.498-2.155-3.22-3.857-5.347-5.028C28.07 3.688 25.456 3 22.1 3h-.002zm21.05 24v-.003V27z" />
        <path d="M21.292 6.363a1.5 1.5 0 01.972 1.885l-1.068 3.338h2.084a1.5 1.5 0 011.408 2.017l-1.74 4.741a1.5 1.5 0 11-2.816-1.034l1-2.724h-1.99a1.5 1.5 0 01-1.43-1.957l1.695-5.295a1.5 1.5 0 011.885-.971zM35.13 14.234a1.5 1.5 0 01-.568 2.044l-3.05 1.726 1.53 1.415a1.5 1.5 0 01-.336 2.436l-4.497 2.299a1.5 1.5 0 01-1.365-2.671l2.583-1.32-1.46-1.352a1.5 1.5 0 01.28-2.407l4.838-2.737a1.5 1.5 0 012.044.567zM8.625 14.828a1.5 1.5 0 011.95.835l1.303 3.253 1.607-1.327a1.5 1.5 0 012.37.658l1.678 4.763a1.5 1.5 0 11-2.83.997l-.964-2.737-1.534 1.268a1.5 1.5 0 01-2.348-.599L7.79 16.78a1.5 1.5 0 01.835-1.95z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_258">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
