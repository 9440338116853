import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ArrowRight2Icon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.406 24a1.5 1.5 0 011.5-1.5h42.188a1.5 1.5 0 010 3H2.906a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.346 3.252a1.5 1.5 0 012.12 0l19.688 19.687a1.5 1.5 0 010 2.122L26.467 44.748a1.5 1.5 0 01-2.121-2.121L42.972 24 24.346 5.373a1.5 1.5 0 010-2.121z"
        fill="currentColor"
      />
    </svg>
  )
}
