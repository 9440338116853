import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const Number1Icon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Subtraction 14"
          d="M24 48a23.849 23.849 0 01-9.342-1.886 23.921 23.921 0 01-7.629-5.144 23.92 23.92 0 01-5.143-7.629A23.848 23.848 0 010 24a23.85 23.85 0 011.886-9.342 23.919 23.919 0 015.143-7.629 23.92 23.92 0 017.629-5.143A23.85 23.85 0 0124 0a23.848 23.848 0 019.342 1.886 23.919 23.919 0 017.629 5.143 23.92 23.92 0 015.143 7.629A23.85 23.85 0 0148 24a23.848 23.848 0 01-1.886 9.342 23.92 23.92 0 01-5.143 7.629 23.921 23.921 0 01-7.629 5.144A23.847 23.847 0 0124 48zm-.088-30.824V35h3.52V13.656h-3.456l-4.289 2.592v3.52l4.223-2.591z"
        />
      </g>
    </svg>
  )
}
