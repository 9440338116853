import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const IdentifyingRiskIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#prefix__a)"
        fill="none"
        stroke="currentColor"
        strokeWidth={3}
      >
        <g data-name="Ellipse 11" transform="translate(2 2)">
          <circle cx={22} cy={22} r={22} stroke="none" />
          <circle cx={22} cy={22} r={20.5} />
        </g>
        <path
          data-name="Path 67"
          d="M5 22.258h7.247L16.394 35.5l6.651-24 6.651 19.954 6.651-9.2h6.651"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}
