import { ITheme } from "react-styled-flexboxgrid"

export const gridConfig: ITheme = {
  // Defaults
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 64, // em
    lg: 75, // em
  },
  container: {
    sm: 46, // rem
    md: 63, // rem
    lg: 73, // rem
  },
  gridSize: 12, // columns
  gutterWidth: 1, // rem
  mediaQuery: "",
  outerMargin: 1, // rem
}
