import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const MealRythmIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_274)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M39.713.058a1.5 1.5 0 011.603 1.39l.5 7a1.5 1.5 0 01-1.182 1.573l-7.006 1.5A1.5 1.5 0 0133 8.587l5.73-1.227-.406-5.7a1.5 1.5 0 011.39-1.602zM23.958 18a9 9 0 00-9 9v1h18v-1a9 9 0 00-9-9zm-8.485.515A12 12 0 0135.958 27v2.5a1.5 1.5 0 01-1.5 1.5h-21a1.5 1.5 0 01-1.5-1.5V27a12 12 0 013.515-8.485z" />
        <path d="M8.458 29.502a1.5 1.5 0 011.5-1.5h28a1.5 1.5 0 110 3h-28a1.5 1.5 0 01-1.5-1.5z" />
        <path d="M30.797 4.183a21.002 21.002 0 10-8.873 40.77 1.5 1.5 0 11-.296 2.986A24.003 24.003 0 1141.406 7.53a1.5 1.5 0 11-2.176 2.066 21.002 21.002 0 00-8.433-5.414zM41.362 38.44a1.5 1.5 0 01.06 2.12 23.943 23.943 0 01-13.156 7.116 1.5 1.5 0 11-.532-2.952A20.944 20.944 0 0039.242 38.5a1.5 1.5 0 012.12-.06zM46.498 22.554A1.5 1.5 0 0148 24.052c.005 3.83-.91 7.604-2.667 11.006a1.5 1.5 0 01-2.666-1.376A20.918 20.918 0 0045 24.056a1.5 1.5 0 011.498-1.502zM43.311 12.403a1.5 1.5 0 012.022.644 23.912 23.912 0 011.805 4.607 1.5 1.5 0 01-2.892.8 20.916 20.916 0 00-1.578-4.03 1.5 1.5 0 01.643-2.02z" />
        <path d="M23.958 11.37a1.5 1.5 0 011.5 1.5v3.632a1.5 1.5 0 01-3 0V12.87a1.5 1.5 0 011.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_274">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
