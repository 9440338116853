import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const LoveReloadIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_254)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M43.936 11.36C41.013 5.341 32.89 4.107 28.222 8.776l-1.06-1.06 1.06 1.06-3.187 3.187a1.5 1.5 0 01-2.121 0l-3.177-3.177C14.853 4.089 6.927 5.36 4.013 11.359a1.483 1.483 0 01-.02.04c-1.863 3.557-1.036 8.23 1.923 11.19l.021.021L23.974 41.4l18.038-18.79a1.59 1.59 0 01.052-.051c3.083-2.912 3.788-7.368 1.88-11.185l-.008-.015zm2.694-1.32C42.778 2.123 32.184.572 26.1 6.656l-2.126 2.126-2.125-2.126-.02-.02C15.565.597 5.19 2.109 1.325 10.029c-2.503 4.8-1.34 10.863 2.458 14.67l19.11 19.906a1.5 1.5 0 002.164 0l19.095-19.89c4.061-3.859 4.941-9.74 2.478-14.673z" />
        <path d="M31.374 14.348a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-1.5 1.5h-4.6a1.5 1.5 0 010-3h3.1v-4.5a1.5 1.5 0 011.5-1.5z" />
        <path d="M14.874 23.448c0-5.029 4.072-9.1 9.1-9.1 4.21 0 7.72 3.393 8.656 7.136a1.5 1.5 0 11-2.91.728c-.665-2.657-3.154-4.864-5.745-4.864a6.097 6.097 0 00-6.1 6.1c0 3.371 2.728 6.1 6.1 6.1 1.867 0 3.483-.93 4.67-2.288a1.5 1.5 0 012.259 1.976c-1.613 1.842-3.997 3.312-6.93 3.312a9.097 9.097 0 01-9.1-9.1z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_254">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
