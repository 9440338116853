import { css, RuleSet, styled, StyledInstance } from "styled-components"
import { ComponentProps, JSXElementConstructor } from "react"
import {
  AnyComponent,
  Attrs,
  BaseObject,
  FastOmit,
  KnownTarget,
  Runtime,
  ShouldForwardProp,
  StyledTarget,
} from "styled-components/dist/types"
import isPropValid from "@emotion/is-prop-valid"

export const hideOnPrint = (): RuleSet =>
  css`
    @media print {
      display: none;
    }
  `

/// A hack that mimics styled-components 5.x.x `.attrs` behavior. Makes the provided required
/// props optional.
/// https://github.com/styled-components/styled-components/issues/4076#issuecomment-2232579171

type MakeOverlappingPropsOptional<
  GBase extends BaseObject,
  GOther extends Partial<GBase>
> = FastOmit<GBase, keyof GOther> & Partial<GOther>

/**
 * `styled` + `.attrs`, but with TypeScript inference.
 *
 * **Usage:**
 * ```ts
 * styledWithAttrs(FooComponent)({ your: 1, default: 2, props: 3 })
 * ```
 */
export function styledWithAttrs<
  GRuntime extends Runtime,
  GTarget extends Extract<StyledTarget<GRuntime>, AnyComponent | KnownTarget>
>(target: GTarget) {
  type TBase = ComponentProps<GTarget>
  const styledTarget = styled(target)

  function withAttrs<GOther extends Partial<TBase>>(attrs: Attrs<GOther>) {
    type TOverlapped = MakeOverlappingPropsOptional<TBase, GOther>
    return (styledTarget as StyledInstance<
      GRuntime,
      JSXElementConstructor<TOverlapped>,
      TOverlapped
    >).attrs(attrs as TOverlapped)
  }

  return withAttrs
}

// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
export const shouldForwardProp: ShouldForwardProp<"web"> = (
  propName,
  target,
) => {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}
