export interface GoogleTagManagerConfig {
  containerId?: string
  gtmAuth?: string
  gtmPreview?: string
}

const getGoogleTagManagerExtraParams = (
  googleTagManagerConfig: GoogleTagManagerConfig,
) => {
  return googleTagManagerConfig.gtmAuth && googleTagManagerConfig.gtmPreview
    ? `&gtm_auth=${googleTagManagerConfig.gtmAuth}&gtm_preview=${googleTagManagerConfig.gtmPreview}`
    : ""
}

/**
 * Return the google tag manager script or undefined if no container id defined
 */
export const getGoogleTagManagerHeadScript = (
  googleTagManagerConfig: GoogleTagManagerConfig,
): string | undefined => {
  if (!googleTagManagerConfig.containerId) {
    return undefined
  }

  return `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${getGoogleTagManagerExtraParams(
    googleTagManagerConfig,
  )}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${
    googleTagManagerConfig.containerId
  }');
`
}

/**
 * Return the google tag manager no script url or undefined if no container id defined
 */
export const getGoogleTagManagerNoScript = (
  googleTagManagerConfig: GoogleTagManagerConfig,
): string | undefined => {
  if (!googleTagManagerConfig.containerId) {
    return undefined
  }

  return `https://www.googletagmanager.com/ns.html?id=${
    googleTagManagerConfig.containerId
  }&${getGoogleTagManagerExtraParams(
    googleTagManagerConfig,
  )}}&gtm_cookies_win=x`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataLayer = Record<string, any>[]

declare global {
  interface Window {
    dataLayer: DataLayer
  }
}

export interface DataLayerBaseEvent {
  event:
    | "pageView"
    | "contactRequest"
    | "changeLanguage"
    | "startTrial"
    | "requestQuote"
}

export interface PageViewEvent extends DataLayerBaseEvent {
  event: "pageView"
  language: string
  page: string
}

export interface ChangeLanguageEvent extends DataLayerBaseEvent {
  event: "changeLanguage"
  language: string
}

export interface ContactRequestEvent extends DataLayerBaseEvent {
  contactReason: string
  event: "contactRequest"
}
export interface StartTrialEvent extends DataLayerBaseEvent {
  event: "startTrial"
}

export interface RequestQuoteEvent extends DataLayerBaseEvent {
  event: "requestQuote"
}

export type DataLayerEvent =
  | PageViewEvent
  | ContactRequestEvent
  | ChangeLanguageEvent
  | StartTrialEvent
  | RequestQuoteEvent

/**
 * TODO: #AIS-490
 * To be deprecated: Use sendDataLayerEvent or sendPageViewEvent
 */
export function sendEvent(eventCategory: string, eventName: string): void {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "customEvent",
    eventAction: eventName,
    eventCategory,
  })
}

export function sendDataLayerEvent(event: DataLayerEvent): void {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(event)
}
