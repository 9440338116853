import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const SortDownUpIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M31.001 38a1 1 0 001-1V13.415l6.292 6.294a1.001 1.001 0 001.416-1.416l-8-8a1 1 0 00-1.416 0l-8 8a1.001 1.001 0 101.416 1.416l6.292-6.294V37a1 1 0 001 1zm-14-28a1 1 0 011 1v23.585l6.292-6.294a1.001 1.001 0 011.416 1.416l-8 8a1 1 0 01-1.416 0l-8-8a1.001 1.001 0 111.416-1.416l6.292 6.294V11a1 1 0 011-1z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}
