import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M35.694 15.948a1.043 1.043 0 010 1.477L21.087 32.032a1.043 1.043 0 01-1.477 0l-7.3-7.3a1.045 1.045 0 111.477-1.477l6.565 6.567 13.864-13.874a1.043 1.043 0 011.477 0z" />
        <path d="M32.873 14.408a2.369 2.369 0 013.38 3.317L23.649 33.492a2.369 2.369 0 01-3.412.063l-8.358-8.363a2.369 2.369 0 113.348-3.348l6.613 6.612 10.973-13.978a.844.844 0 01.063-.069z" />
      </g>
    </svg>
  )
}
