import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const CrossIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M9.63 9.63a2.142 2.142 0 013.034 0l11.337 11.341L35.338 9.63a2.145 2.145 0 113.034 3.034L27.03 24.001l11.342 11.337a2.145 2.145 0 11-3.034 3.034L24.001 27.03 12.663 38.372a2.145 2.145 0 11-3.034-3.034l11.342-11.337L9.63 12.663a2.142 2.142 0 010-3.034z" />
      </g>
    </svg>
  )
}
