import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const RestlessLegsIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.006 27.63a1.5 1.5 0 01-.407 2.083l-12.962 8.72A2.979 2.979 0 008.14 43.76l23.401-6.848-1.15-3.934a1.5 1.5 0 112.88-.842l1.15 3.934a3 3 0 01-2.036 3.72L8.985 46.64h-.001a5.978 5.978 0 01-5.021-10.696l12.962-8.72a1.5 1.5 0 012.081.408z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.283 5.48a1.5 1.5 0 01-.997 1.873c-2.181.666-4.117 1.34-6.078 2.024l-.049.017c-1.816.633-3.655 1.275-5.698 1.91l7.662 16.533a1.5 1.5 0 01-2.722 1.262l-7.846-16.93a2.624 2.624 0 011.329-3.507c.099-.041.2-.076.304-.105 2.146-.658 4.06-1.325 6.003-2.003l.03-.01c1.956-.682 3.944-1.375 6.189-2.06a1.5 1.5 0 011.873.996zM7.363 34.173a1.5 1.5 0 011.97-.788 16.078 16.078 0 018.457 8.48 1.5 1.5 0 11-2.76 1.175 13.078 13.078 0 00-6.88-6.897 1.5 1.5 0 01-.787-1.97zM12.627 31.011a1.5 1.5 0 011.808-1.11 23.3 23.3 0 0113.195 8.647 1.5 1.5 0 01-2.396 1.804 20.3 20.3 0 00-11.497-7.533 1.5 1.5 0 01-1.11-1.808zM41.188 10.958l-.074.036a1.5 1.5 0 11-1.316-2.696l.082-.04.014-.007a4.744 4.744 0 016.162 1.942m-4.868.765a1.744 1.744 0 011.581 3.108l-2.69 1.475a1.5 1.5 0 001.442 2.63l2.69-1.473a4.744 4.744 0 001.845-6.505"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.092 4.28L27.41 10.59a8.434 8.434 0 002.225 15.88 8.428 8.428 0 005.482-.883l6.956-3.975.023-.013a1.746 1.746 0 00-.314-3.195c-.42-.133-.875-.1-1.273.088a1.5 1.5 0 11-1.289-2.708 4.744 4.744 0 014.33 8.438L36.593 28.2l-.029.016a11.428 11.428 0 01-14.031-2.495m17.56-21.44a1.746 1.746 0 012.256.718v.002a1.747 1.747 0 01-.67 2.392l-.003.002-1.24.676a1.5 1.5 0 101.438 2.634l1.245-.68.006-.004a4.748 4.748 0 00-4.33-8.446l-.024.012-12.682 6.31a11.434 11.434 0 00-3.556 17.824"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.861 8.9a1.5 1.5 0 01-.617 2.03l-4.068 2.17a1.5 1.5 0 01-1.412-2.647l4.068-2.17a1.5 1.5 0 012.03.617zM41.242 16.416a1.5 1.5 0 01-.618 2.03l-3.212 1.712A1.5 1.5 0 1136 17.51l3.212-1.712a1.5 1.5 0 012.029.618z"
        fill="currentColor"
      />
    </svg>
  )
}
