import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__b">
          <path
            data-name="Rectangle 13"
            transform="translate(.274)"
            fill="currentColor"
            d="M0 0h32v32H0z"
          />
        </clipPath>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <g
          data-name="calendar 1"
          transform="translate(7.726 8)"
          clipPath="url(#prefix__b)"
        >
          <path
            data-name="Path 105"
            d="M7.251 0a1 1 0 011 1v1h15.944V1a1 1 0 011.993 0v1h1.993a3.987 3.987 0 013.987 3.98v21.927a3.987 3.987 0 01-3.987 3.987H4.261a3.987 3.987 0 01-3.987-3.987V5.98a3.987 3.987 0 013.987-3.987h1.993V1a1 1 0 011-1zM2.268 7.974v19.933A1.993 1.993 0 004.261 29.9h23.921a1.993 1.993 0 001.993-1.993V7.974z"
          />
        </g>
      </g>
    </svg>
  )
}
