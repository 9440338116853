import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ExerciseTime2Icon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_255)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M30.677 17.003a4.547 4.547 0 00-3.946 1.275l-1.67 1.67a1.5 1.5 0 01-2.117.005l-1.679-1.665a4.551 4.551 0 00-7.289 1.181 4.554 4.554 0 00.854 5.247l.02.022 9.15 9.54 9.145-9.54.022-.022a4.553 4.553 0 00-2.49-7.713zm-3.049-2.693a7.547 7.547 0 019.074 3.81 7.554 7.554 0 01-1.401 8.704l-9.496 9.906a2.5 2.5 0 01-3.558.053l-.03-.03-.013-.014-.009-.009-9.5-9.906a7.554 7.554 0 01-1.404-8.693l.001-.002a7.55 7.55 0 0112.093-1.964l.61.606.614-.613a7.552 7.552 0 013.02-1.848z" />
        <path d="M21.337 19.736a1.5 1.5 0 011.207.823l2.76 5.524 2.221-2.221a1.5 1.5 0 011.061-.44h6.346a1.5 1.5 0 010 3h-5.724l-3.253 3.254a1.5 1.5 0 01-2.403-.39l-2.607-5.215-1.313 1.751a1.5 1.5 0 01-1.2.6h-5.37a1.5 1.5 0 010-3h4.62l2.32-3.092a1.5 1.5 0 011.335-.594zM24 40.264a1.5 1.5 0 011.5 1.5V46.5a1.5 1.5 0 11-3 0v-4.736a1.5 1.5 0 011.5-1.5zM24 0a1.5 1.5 0 011.5 1.5v4.736a1.5 1.5 0 01-3 0V1.5A1.5 1.5 0 0124 0zM40.264 24a1.5 1.5 0 011.5-1.5H46.5a1.5 1.5 0 010 3h-4.736a1.5 1.5 0 01-1.5-1.5zM0 24a1.5 1.5 0 011.5-1.5h4.736a1.5 1.5 0 010 3H1.5A1.5 1.5 0 010 24zM40.969 7.031a1.5 1.5 0 010 2.122L37.62 12.5a1.5 1.5 0 11-2.122-2.122l3.348-3.348a1.5 1.5 0 012.122 0zM12.5 35.502a1.5 1.5 0 010 2.121l-3.352 3.35a1.5 1.5 0 01-2.12-2.122l3.352-3.35a1.5 1.5 0 012.12 0zM7.027 7.032a1.5 1.5 0 012.12-.001l3.353 3.348a1.5 1.5 0 01-2.12 2.122L7.028 9.153a1.5 1.5 0 01-.001-2.121zM35.5 35.501a1.5 1.5 0 012.121 0l3.348 3.35a1.5 1.5 0 11-2.122 2.121l-3.348-3.35a1.5 1.5 0 010-2.121z" />
        <path d="M24 3C12.402 3 3 12.402 3 24s9.402 21 21 21 21-9.402 21-21S35.598 3 24 3zM0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_255">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
