import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const DeleteIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M21 10a1 1 0 00-1 1v2h8v-2a1 1 0 00-1-1zm9 3v-2a3 3 0 00-3-3h-6a3 3 0 00-3 3v2h-7a1 1 0 100 2h1.076l1.706 21.32A4 4 0 0017.77 40h12.46a4 4 0 003.988-3.681L35.924 15H37a1 1 0 100-2h-7zm3.916 2H14.084l1.692 21.16A2 2 0 0017.77 38h12.46a2 2 0 001.994-1.84L33.916 15zm-14.974 2a1 1 0 011.058.94l1 17a1.002 1.002 0 11-2 .12l-1-17a1 1 0 01.94-1.06zm10.116 0A1 1 0 0130 18.06l-1 17a1.002 1.002 0 11-2-.12l1-17a1 1 0 011.058-.94zM24 17a1 1 0 011 1v17a1 1 0 01-2 0V18a1 1 0 011-1z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}
