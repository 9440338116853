import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const Number2Icon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Subtraction 13"
          d="M24 48a23.849 23.849 0 01-9.342-1.886 23.921 23.921 0 01-7.629-5.144 23.921 23.921 0 01-5.143-7.629A23.848 23.848 0 010 24a23.849 23.849 0 011.886-9.342 23.92 23.92 0 015.143-7.629 23.921 23.921 0 017.629-5.143A23.851 23.851 0 0124 0a23.848 23.848 0 019.342 1.886 23.921 23.921 0 017.629 5.143 23.92 23.92 0 015.143 7.629A23.85 23.85 0 0148 24a23.848 23.848 0 01-1.886 9.342 23.922 23.922 0 01-5.143 7.629 23.922 23.922 0 01-7.629 5.144A23.847 23.847 0 0124 48zm-.872-31.657a6.086 6.086 0 012.624.481 2.978 2.978 0 011.408 1.28 3.771 3.771 0 01.416 1.759 5.952 5.952 0 01-.7 2.672 16.553 16.553 0 01-1.841 2.816 28.61 28.61 0 01-2.5 2.736c-.9.868-1.815 1.67-2.72 2.384s-1.733 1.324-2.448 1.808V35h14.112v-3.328h-9.286c.417-.334.9-.726 1.43-1.167a42.226 42.226 0 002.64-2.4 28.8 28.8 0 002.416-2.7 14.4 14.4 0 001.776-2.88 7.064 7.064 0 00.672-2.944 6.675 6.675 0 00-.752-3.152 5.471 5.471 0 00-2.352-2.272 8.831 8.831 0 00-4.128-.849 11.071 11.071 0 00-3.616.544 14.081 14.081 0 00-2.72 1.248v3.327A15.45 15.45 0 0119.992 17a7.239 7.239 0 013.136-.656z"
        />
      </g>
    </svg>
  )
}
