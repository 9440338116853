import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const FocusIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_279)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M10.868 24a24.77 24.77 0 003.233 3.361C16.594 29.498 20.007 31.5 24 31.5c3.993 0 7.406-2.002 9.899-4.139A24.77 24.77 0 0037.132 24a24.77 24.77 0 00-3.233-3.361C31.406 18.502 27.993 16.5 24 16.5c-3.993 0-7.406 2.002-9.899 4.139A24.77 24.77 0 0010.868 24zM39 24c1.248-.832 1.248-.832 1.247-.833v-.001l-.002-.003-.006-.008-.018-.027a16.334 16.334 0 00-.29-.406 23.666 23.666 0 00-.857-1.088 27.768 27.768 0 00-3.223-3.273C33.094 15.998 29.007 13.5 24 13.5c-5.007 0-9.094 2.498-11.851 4.861a27.77 27.77 0 00-3.223 3.273 23.66 23.66 0 00-1.085 1.405 8.954 8.954 0 00-.062.09l-.018.026-.006.008-.002.003v.001L9 24l-1.248-.832a1.5 1.5 0 000 1.664L9 24c-1.248.832-1.248.832-1.247.833v.001l.002.003.006.008.018.027.062.09a23.66 23.66 0 001.085 1.405 27.77 27.77 0 003.223 3.272C14.906 32.002 18.993 34.5 24 34.5c5.007 0 9.094-2.498 11.851-4.861a27.768 27.768 0 003.223-3.273 23.666 23.666 0 001.085-1.405l.062-.09.018-.026.006-.008.002-.003v-.001L39 24zm0 0l1.248.832a1.5 1.5 0 000-1.664L39 24z" />
        <path d="M24 21.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM18.75 24a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zM4.5 3A1.5 1.5 0 003 4.5V9a1.5 1.5 0 11-3 0V4.5A4.5 4.5 0 014.5 0H9a1.5 1.5 0 110 3H4.5zM37.5 1.5A1.5 1.5 0 0139 0h4.5A4.5 4.5 0 0148 4.5V9a1.5 1.5 0 01-3 0V4.5A1.5 1.5 0 0043.5 3H39a1.5 1.5 0 01-1.5-1.5zM1.5 37.5A1.5 1.5 0 013 39v4.5A1.5 1.5 0 004.5 45H9a1.5 1.5 0 010 3H4.5A4.5 4.5 0 010 43.5V39a1.5 1.5 0 011.5-1.5zM46.5 37.5A1.5 1.5 0 0148 39v4.5a4.5 4.5 0 01-4.5 4.5H39a1.5 1.5 0 010-3h4.5a1.5 1.5 0 001.5-1.5V39a1.5 1.5 0 011.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_279">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
