import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const EatingDisorderIcon: React.FC<React.SVGProps<
  SVGSVGElement
>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.25 3A17.23 17.23 0 009.52 32.87a1.5 1.5 0 01.481 1.1V46.5a1.5 1.5 0 01-1.5 1.5c-.828 0-1.221-.672-1.221-1.5l-.279-11.89A20.23 20.23 0 0121.252 0c3.783 0 6.909.781 9.538 2.23 2.626 1.445 4.67 3.507 6.363 5.942 2.762 3.972 4.698 9.127 6.703 14.464.41 1.09.822 2.188 1.245 3.285v.002A3 3 0 0142.305 30H40v4.5a7.5 7.5 0 01-7.5 7.5h-1.5v4.5a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5h3a4.5 4.5 0 004.5-4.5v-6a1.5 1.5 0 011.5-1.5H42.3l.002 1.5V27l1.236.848L42.301 27l1.4-.54-1.4.537c-.45-1.166-.88-2.31-1.3-3.426-2.008-5.333-3.783-10.051-6.31-13.686-1.499-2.155-3.22-3.857-5.348-5.028C27.22 3.688 24.607 3 21.251 3h-.002zM42.3 27v-.003V27z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.845 8.645a1.5 1.5 0 011.5 1.5v5.012c0 1 1.053 2.222 2.904 2.222 1.85 0 2.904-1.222 2.904-2.222v-5.012a1.5 1.5 0 113 0v5.012c0 3.11-2.89 5.222-5.904 5.222s-5.904-2.111-5.904-5.222v-5.012a1.5 1.5 0 011.5-1.5zM18.099 8.93c.39.282.62.734.62 1.215V31.4a1.5 1.5 0 01-3 0v-4.87h-5.654a1.5 1.5 0 01-1.5-1.5c0-2.027.162-5.402 1.21-8.588 1.05-3.186 3.08-6.443 6.976-7.723a1.5 1.5 0 011.348.21zm-2.38 14.601V12.603c-1.468 1.118-2.447 2.813-3.093 4.777-.69 2.098-.946 4.35-1.027 6.151h4.12z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.248 8.645a1.5 1.5 0 011.5 1.5v20.452a1.5 1.5 0 11-3 0V10.145a1.5 1.5 0 011.5-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}
