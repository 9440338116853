import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const Number3Icon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          data-name="Subtraction 12"
          d="M24 48a23.849 23.849 0 01-9.342-1.886 23.921 23.921 0 01-7.629-5.144 23.921 23.921 0 01-5.143-7.629A23.848 23.848 0 010 24a23.85 23.85 0 011.886-9.342 23.92 23.92 0 015.143-7.629 23.92 23.92 0 017.629-5.143A23.85 23.85 0 0124 0a23.848 23.848 0 019.342 1.886 23.921 23.921 0 017.629 5.143 23.92 23.92 0 015.143 7.629A23.85 23.85 0 0148 24a23.848 23.848 0 01-1.886 9.342 23.922 23.922 0 01-5.143 7.629 23.922 23.922 0 01-7.629 5.144A23.847 23.847 0 0124 48zm-6.536-17.448v3.232a16.12 16.12 0 002.7 1.04 11.665 11.665 0 003.248.432 11.5 11.5 0 003.408-.464 7.339 7.339 0 002.512-1.312 5.641 5.641 0 001.552-2 6.02 6.02 0 00.528-2.528 5.291 5.291 0 00-.5-2.352 5.917 5.917 0 00-1.2-1.68 7.45 7.45 0 00-1.328-1.04 3.707 3.707 0 00-.913-.432 2.424 2.424 0 00.656-.3 5.159 5.159 0 00.993-.8 4.539 4.539 0 00.9-1.328 4.41 4.41 0 00.368-1.856 5.684 5.684 0 00-.464-2.3 4.987 4.987 0 00-1.408-1.84 6.793 6.793 0 00-2.3-1.216 10.353 10.353 0 00-3.12-.432 10.744 10.744 0 00-2.784.352 13.3 13.3 0 00-2.368.864V17.6a12.861 12.861 0 012.336-.88 9.684 9.684 0 012.464-.3 7.672 7.672 0 012.336.32 3.128 3.128 0 011.52.976 2.607 2.607 0 01.527 1.68 2.6 2.6 0 01-1.008 2.16 4.9 4.9 0 01-3.024.784h-2.751v2.912H23a6.593 6.593 0 013.919.944 3.117 3.117 0 011.264 2.64 2.888 2.888 0 01-1.312 2.544 6.738 6.738 0 01-3.744.879 8.687 8.687 0 01-3.088-.527 21.4 21.4 0 01-2.576-1.168z"
        />
      </g>
    </svg>
  )
}
