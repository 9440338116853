import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const AnxietyIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.25 3a17.23 17.23 0 00-11.73 29.87 1.5 1.5 0 01.481 1.1V46.5a1.5 1.5 0 11-3 0V34.61A20.23 20.23 0 0122.252 0c3.783 0 6.909.781 9.538 2.23 2.626 1.445 4.67 3.507 6.363 5.942 2.762 3.972 4.698 9.127 6.703 14.464.41 1.09.822 2.188 1.245 3.285v.002A3.002 3.002 0 0143.305 30H41v4.5a7.5 7.5 0 01-7.5 7.5h-1.5v4.5a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5h3a4.5 4.5 0 004.5-4.5v-6a1.5 1.5 0 011.5-1.5H43.3l.002 1.5V27l1.236.848L43.301 27l1.4-.54-1.4.537c-.45-1.166-.88-2.31-1.3-3.426-2.008-5.333-3.783-10.051-6.31-13.686-1.499-2.155-3.22-3.857-5.348-5.028C28.22 3.688 25.607 3 22.251 3h-.002zM43.3 27v-.003V27z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.798 10.36a9.1 9.1 0 100 18.2 9.1 9.1 0 000-18.2zm-12.1 9.1c0-6.683 5.417-12.1 12.1-12.1s12.1 5.417 12.1 12.1-5.417 12.1-12.1 12.1-12.1-5.417-12.1-12.1z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.799 12.608a1.5 1.5 0 011.5 1.5v4.542a1.5 1.5 0 11-3 0v-4.542a1.5 1.5 0 011.5-1.5zM23.096 24.058a1.5 1.5 0 00-1.298-2.252 2.252 2.252 0 000 4.505 1.5 1.5 0 001.298-2.253z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.298 23.306a1.5 1.5 0 011.5-1.5 2.252 2.252 0 110 4.505 1.5 1.5 0 01-1.298-2.253 1.494 1.494 0 01-.202-.752z"
        fill="currentColor"
      />
    </svg>
  )
}
