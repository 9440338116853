import React, { AriaAttributes, FC } from "react"
import styled, { css, RuleSet } from "styled-components"
import { lighten, rem, rgba, transparentize } from "polished"
import { assertNever } from "../../typings/typeUtils"
import { GeneratedIcon } from "../../generated/icons/generated-icons"
import { BrandColor, MarginProp, Margins, theme } from "../../util/styles/theme"
import { Icon } from "../icon/Icon"
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator"

const buttonHeightPx: Record<ButtonSize, number> = {
  xsmall: 24,
  small: 32,
  medium: 48,
}

export interface ButtonSizing {
  borderRadius: string
  height: string
  padding: string
  textStyle: RuleSet
}

export const buttonSizing: Record<ButtonSize, ButtonSizing> = {
  xsmall: {
    borderRadius: rem(buttonHeightPx.xsmall / 2),
    height: rem(buttonHeightPx.xsmall),
    padding: rem(buttonHeightPx.xsmall / 4),
    textStyle: theme.textStyle("default", "xsmall", "semibold"),
  },
  small: {
    borderRadius: rem(buttonHeightPx.small / 2),
    height: rem(buttonHeightPx.small),
    padding: rem(buttonHeightPx.small / 2),
    textStyle: theme.textStyle("default", "medium", "semibold"),
  },
  medium: {
    borderRadius: rem(buttonHeightPx.medium / 2),
    height: rem(buttonHeightPx.medium),
    padding: rem(buttonHeightPx.medium / 2),
    textStyle: theme.textStyle("default", "medium", "semibold"),
  },
}

const textColorByVariant: Record<ButtonVariant, BrandColor> = {
  accent: "white",
  outline: "white",
  outlineGrey: "black",
  primary: "white",
  secondary: "primary",
  tertiary: "blueLight",
  transparent: "primary",
  danger: "white",
}

export const buttonVariants = [
  "primary",
  "secondary",
  "tertiary",
  "transparent",
  "outline",
  "outlineGrey",
  "accent",
  "danger",
] as const

export type ButtonVariant = typeof buttonVariants[number]

export const buttonSizes = ["xsmall", "small", "medium"] as const

export type ButtonSize = typeof buttonSizes[number]

type BaseAttributes = Margins & AriaAttributes

export interface BaseButtonProps<T> extends BaseAttributes {
  active?: boolean
  className?: string
  flexGrow?: boolean
  icon?: GeneratedIcon
  id?: string
  onClick?: (e: React.MouseEvent<T, MouseEvent>) => void
  size?: ButtonSize
  type: "button" | "link" | "submit"
  variant: ButtonVariant
  loading?: boolean
}

export interface ClickableButtonProps
  extends BaseButtonProps<HTMLButtonElement> {
  ariaPressed?: boolean
  disabled?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  type: "button"
}

export type LinkButtonProps = BaseButtonProps<HTMLAnchorElement> & {
  type: "link"
} & Pick<HTMLAnchorElement, "href"> &
  Partial<Pick<HTMLAnchorElement, "target">>

export interface SubmitButtonProps extends BaseButtonProps<HTMLButtonElement> {
  disabled?: boolean
  type: "submit"
}

export type ButtonProps =
  | ClickableButtonProps
  | LinkButtonProps
  | SubmitButtonProps

type ButtonElementProps = Pick<
  ButtonProps,
  "size" | "variant" | "active" | "flexGrow" | MarginProp
>

const ButtonElement = styled.button<ButtonElementProps>`
  align-items: center;
  border: transparent solid 1px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  flex-grow: ${({ flexGrow }) => flexGrow && 1};
  ${({ size = "medium" }) => {
    switch (size) {
      case "xsmall":
      case "small":
      case "medium":
        return css`
          height: ${buttonSizing[size].height};
          border-radius: ${buttonSizing[size].borderRadius};
          padding: 0 ${buttonSizing[size].padding};
          ${buttonSizing[size].textStyle};
        `
      default:
        return assertNever(size)
    }
  }}
  ${props => theme.marginStyle(theme, props)}
  text-align: center;
  text-transform: uppercase;

  &:link,
  &:visited,
  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  transition: opacity 0.3s;
  &:disabled {
    opacity: 0.5;
  }

  ${({ active, variant }) => {
    switch (variant) {
      case "primary":
        return css`
          background: ${active ? theme.colors.blueLight : theme.colors.primary};
          border-color: ${theme.colors.primary};

          &:not([disabled]):hover,
          &:focus {
            background: ${theme.colors.blueLight};
            border-color: ${theme.colors.blueLight};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      case "secondary":
        return css`
          background: ${active ? theme.colors.grey : theme.colors.white};
          border-color: ${theme.colors.white};

          &:not([disabled]):hover,
          &:focus {
            background: ${theme.colors.grey};
            border-color: ${theme.colors.grey};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      case "transparent":
        return css`
          background: ${active
            ? transparentize(0.93, theme.colors.black)
            : theme.colors.transparent};

          &:not([disabled]):hover,
          &:focus {
            background: ${transparentize(0.93, theme.colors.black)};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      case "outline":
        return css`
          background: ${active
            ? rgba(theme.colors.white, 0.15)
            : theme.colors.transparent};
          border-color: ${theme.colors.white};

          &:not([disabled]):hover,
          &:focus {
            background: ${rgba(theme.colors.white, 0.15)};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      case "tertiary":
        return css`
          background: ${active
            ? theme.colors.greyCold
            : theme.colors.transparent};
          border-color: ${theme.colors.blueLight};

          &:not([disabled]):hover,
          &:focus {
            background: ${theme.colors.greyCold};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      case "accent":
        return css`
          background: ${active
            ? lighten(0.05, theme.colors.orange)
            : theme.colors.orange};
          border-color: ${theme.colors.orange};

          &:not([disabled]):hover,
          &:focus {
            background: ${lighten(0.05, theme.colors.orange)};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      case "outlineGrey":
        return css`
          background: ${active ? theme.colors.grey : theme.colors.white};
          border-color: ${theme.colors.grey};

          &:not([disabled]):hover,
          &:focus {
            background: ${theme.colors.grey};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      case "danger":
        return css`
          background: ${theme.colors.red};
          border-color: ${theme.colors.red};

          &:not([disabled]):hover,
          &:focus {
            background: ${lighten(0.05, theme.colors.red)};
          }
          color: ${theme.colors[textColorByVariant[variant]]};
        `
      default:
        return assertNever(variant)
    }
  }}
`

const ChildrenContainer = styled.span`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  pointer-events: none;
`

const StyledIcon = styled(Icon)`
  &:not(:only-child) {
    margin-right: ${theme.spacings.small};
  }
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0) scale(0.5);
  opacity: 0.5;
`

const ButtonToBeWrapped: FC<ButtonProps> = props => {
  const { children, icon: iconName, loading, ...sharedProps } = props

  const textColor = textColorByVariant[sharedProps.variant]

  const icon = iconName && <StyledIcon icon={iconName} color={textColor} />
  const childrenElement = children && (
    <ChildrenContainer>{children}</ChildrenContainer>
  )
  const loadingIndicator = loading && (
    <StyledLoadingIndicator color={textColor} />
  )

  switch (sharedProps.type) {
    case "button":
      return (
        <ButtonElement
          {...sharedProps}
          type="button"
          disabled={sharedProps.disabled}
          aria-pressed={sharedProps.ariaPressed}
        >
          {icon}
          {childrenElement}
          {loadingIndicator}
        </ButtonElement>
      )
    case "link": {
      return (
        <ButtonElement {...sharedProps} as="a">
          {icon}
          {childrenElement}
          {loadingIndicator}
        </ButtonElement>
      )
    }
    case "submit":
      return (
        <ButtonElement
          {...sharedProps}
          type="submit"
          disabled={sharedProps.disabled}
        >
          {icon}
          {childrenElement}
          {loadingIndicator}
        </ButtonElement>
      )
    default:
      return assertNever(sharedProps)
  }
}
ButtonToBeWrapped.defaultProps = {
  size: "medium",
  type: "button",
}

export const Button = styled(ButtonToBeWrapped)<ButtonProps>``
