import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const PulseIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_246)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M7.03 7.03a24 24 0 1133.94 33.94A24 24 0 017.03 7.03zM24 3a21 21 0 100 42 21 21 0 000-42z" />
        <path d="M19.409 14.767l6.58 18.64a3 3 0 005.433.493L35.37 27h5.13a1.5 1.5 0 100-3h-6a1.5 1.5 0 00-1.302.755l-4.38 7.653-6.584-18.65-.001-.004a2.998 2.998 0 00-5.604-.116l-.002.005L12.485 24H7.5a1.5 1.5 0 000 3h6a1.5 1.5 0 001.393-.943l4.516-11.29z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_246">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
