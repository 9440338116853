import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const ReportsIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#prefix__a)"
        fill="none"
        stroke="currentColor"
        strokeWidth={3}
      >
        <path data-name="Line 1" strokeLinecap="round" d="M15.351 30h17.298" />
        <path data-name="Line 9" strokeLinecap="round" d="M15.351 38h17.298" />
        <g data-name="Rectangle 2" transform="translate(7)">
          <rect width={34} height={48} rx={6} stroke="none" />
          <rect x={1.5} y={1.5} width={31} height={45} rx={4.5} />
        </g>
        <path
          data-name="Path 67"
          d="M10.147 16.786h5.436l3.111-6.418L23.683 22l4.989-9.671 4.989 4.457h4.989"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}
