import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import * as msalReact from "@azure/msal-react"
import { useAccountSane, login, authenticateSilent } from "./clientMsal"
import {
  AdminAuthAuthenticationResult,
  AdminAuthContext,
  AdminAuthContextValue,
} from "./AdminAuthContext"
import { AdminLanguage } from "../typings/adminLanguage"
import { LoadingIndicator } from "../components/loading-indicator/LoadingIndicator"

export interface LoginPageBaseProps {
  language: AdminLanguage
  onLoginButtonClick: () => void
}

export interface EnsureAdminAuthenticationProps {
  azureAdAuthority: string
  azureAdClientId: string
  azureAdRedirectFlowRedirectUri: string
  language: AdminLanguage
  loginPageComponent: React.ComponentType<LoginPageBaseProps>
  adminAuthContextRef: RefObject<AdminAuthContextValue>
}

export const EnsureAdminAuthentication: FC<EnsureAdminAuthenticationProps> = ({
  azureAdAuthority,
  azureAdClientId,
  azureAdRedirectFlowRedirectUri,
  children,
  language,
  loginPageComponent,
  adminAuthContextRef,
}) => {
  const { instance, accounts, inProgress } = msalReact.useMsal()
  const isAuthenticated = msalReact.useIsAuthenticated()
  const account = useAccountSane(accounts[0])
  const [interactiveLoginRequired, setInteractiveLoginRequired] = useState(
    false,
  )
  const mountedRef = useRef(true)

  const scopes = useMemo(() => [`api://${azureAdClientId}/Api.Access`], [
    azureAdClientId,
  ])
  const LoginPage = loginPageComponent

  const busy = inProgress !== "none"

  const authenticate = useCallback(async (): Promise<
    AdminAuthAuthenticationResult
  > => {
    const setInteractiveLoginRequiredIfMounted = (value: boolean): void => {
      if (mountedRef.current) {
        setInteractiveLoginRequired(value)
      }
    }

    if (busy) {
      // Busy, caller should retry
      return "busyPleaseRetry"
    }

    if (!account) {
      setInteractiveLoginRequiredIfMounted(true)
      return "interactionRequired"
    }

    try {
      const result = await authenticateSilent(instance, account, scopes)

      if (result === "interactionRequired") {
        setInteractiveLoginRequiredIfMounted(true)
        return "interactionRequired"
      }

      setInteractiveLoginRequiredIfMounted(false)

      return result
    } catch (error) {
      setInteractiveLoginRequiredIfMounted(true)
      throw error
    }
  }, [account, busy, instance, scopes])

  useEffect(() => {
    authenticate()
  }, [authenticate])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleLoginClick = useCallback(() => {
    login(instance, scopes, azureAdRedirectFlowRedirectUri).catch(error => {
      throw error
    })
  }, [azureAdRedirectFlowRedirectUri, instance, scopes])

  const adminAuthContextValue = useMemo(
    (): AdminAuthContextValue => ({
      account,
      busy,
      authenticate,
      getAzureAdAuthority: () => azureAdAuthority,
    }),
    [account, authenticate, azureAdAuthority, busy],
  )

  useImperativeHandle(adminAuthContextRef, () => adminAuthContextValue, [
    adminAuthContextValue,
  ])

  // The isAuthenticated flag can stay true even when interaction is required
  if (!interactiveLoginRequired && isAuthenticated) {
    return (
      <AdminAuthContext.Provider value={adminAuthContextValue}>
        {children}
      </AdminAuthContext.Provider>
    )
  }

  if (!busy && interactiveLoginRequired) {
    return (
      <LoginPage language={language} onLoginButtonClick={handleLoginClick} />
    )
  }

  return <LoadingIndicator centered color="roseGold" />
}
