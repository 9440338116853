import * as React from "react"

/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY MANUALLY.
 * File is generated from .svg in src/assets/icons/. Check web-common README.md.
 */
export const UsersIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_1838_259)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M4.757 7.757a6 6 0 118.486 8.486 6 6 0 01-8.486-8.486zM9 9a3 3 0 100 6 3 3 0 000-6z" />
        <path d="M2.636 20.636A9 9 0 019 18a1.5 1.5 0 010 3 6 6 0 00-6 6v3h1.5a1.5 1.5 0 011.488 1.314L7.324 42H12a1.5 1.5 0 010 3H6a1.5 1.5 0 01-1.488-1.314L3.176 33H1.5A1.5 1.5 0 010 31.5V27a9 9 0 012.636-6.364zM34.757 7.757a6 6 0 118.486 8.485 6 6 0 01-8.486-8.485zM39 9a3 3 0 100 6 3 3 0 000-6z" />
        <path d="M37.5 19.5A1.5 1.5 0 0139 18a9 9 0 019 9v4.5a1.5 1.5 0 01-1.5 1.5h-1.676l-1.336 10.686A1.5 1.5 0 0142 45h-6a1.5 1.5 0 010-3h4.676l1.336-10.686A1.5 1.5 0 0143.5 30H45v-3a6 6 0 00-6-6 1.5 1.5 0 01-1.5-1.5zM18.697 2.197a7.5 7.5 0 1110.606 10.606A7.5 7.5 0 0118.697 2.197zM24 3a4.5 4.5 0 100 9 4.5 4.5 0 000-9z" />
        <path d="M15.515 18.515A12 12 0 0136 27v4.5a1.5 1.5 0 01-1.5 1.5h-3.142l-1.365 13.65A1.5 1.5 0 0128.5 48h-9a1.5 1.5 0 01-1.493-1.35L16.642 33H13.5a1.5 1.5 0 01-1.5-1.5V27a12 12 0 013.515-8.485zM24 18a9 9 0 00-9 9v3h3a1.5 1.5 0 011.493 1.35L20.858 45h6.284l1.365-13.65A1.5 1.5 0 0130 30h3v-3a9 9 0 00-9-9z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1838_259">
          <path fill="currentColor" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
